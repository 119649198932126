<template>

    <div>
        <ValidationObserver v-slot="{ handleSubmit }" ref='addObserver'>
            <form @submit.prevent="handleSubmit(addItem)">
                <div class="card mb-4">
                    <div class="form-row">
                        <div class="form-group col-lg-4">
                            <validation-provider :rules="{ required: true, min_value: 1 }" v-slot="{ errors, classes }" name="Quantity">
                                <label for="packingListQuantity">Quantity</label>
                                <input id="packingListQuantity" type="number" min="1" step="1" class="form-control" v-bind:class="classes" placeholder="1" v-model="newQuantity" />
                                <div class="text-danger">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                        <div class="form-group col-lg-4">
                            <validation-provider :rules="{ required: true }" v-slot="{ errors, classes }" name="Unit of Measurement">
                                <label for="packingListUnit">Unit of Measurement</label>
                                <select id="packingListUnit" class="form-control" v-bind:class="classes" placeholder="Please Select" v-model="newUnits">
                                    <option value="Piece">Piece</option>
                                    <option value="Pair">Pair</option>
                                </select>
                                <div class="text-danger">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                        <div class="form-group col-lg-4">
                            <validation-provider :rules="{ required: true, min_value: 0.01 }" v-slot="{ errors, classes }" name="Unit Value">
                                <label for="packingListValue">Unit Value</label>
                                <div class="input-group">
                                    <div class="input-group-prepend" v-bind:class="classes">
                                        <span class="input-group-text">{{ currencySymbol }}</span>
                                    </div>
                                    <input id="packingListValue" type="number" inputmode="decimal" class="form-control" v-bind:class="classes" v-model.number="newValue" min="0.01" step="any" placeholder="0.00" />
                                </div>
                                <div class="text-danger">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group" v-bind:class="[hasFixedCountry ? 'col-lg-12' : 'col-lg-6']">
                            <validation-provider :rules="{ required: true }" v-slot="{ errors, classes }" name="Description">
                                <label for="packingListDescription">Description</label>
                                <input id="packingListDescription" type="text" class="form-control" v-bind:class="classes" placeholder="" v-model="newDescription" />
                                <div class="text-danger">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                        <div class="form-group col-lg-6" v-if="!hasFixedCountry">
                            <validation-provider :rules="{ required: true }" v-slot="{ errors }" name="Country of Origin">
                                <label for="packingListCountry">Country of Origin</label>
                                <country-select id="packingListCountry" placeholder="e.g. United Kingdom"
                                                :countries="countryList"
                                                :default-selected-iso="newCountry"
                                                :isLoading="isLoadingCountries"
                                                v-model="newCountry">
                                </country-select>
                                <small class="form-text">(Original Purchase)</small>
                                <div class="text-danger">{{ errors[0] }}</div>
                            </validation-provider>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col col-lg-4"><button type="submit" class="btn btn-success w-100">Add Item</button></div>
                    </div>
                </div>
            </form>
        </ValidationObserver>

        <ValidationObserver v-slot="{ handleSubmit }" ref="formObserver">
            <form @submit.prevent="handleSubmit(saveForm)">
                <table class="table table-striped table-responsive packinglist-table" v-show="items.length > 0">
                    <thead>
                        <tr>
                            <th class="packinglist-col-action"></th>
                            <th class="packinglist-col-qty">Quantity</th>
                            <th class="packinglist-col-desc">Description</th>
                            <th class="packinglist-col-value">Unit Value</th>
                            <th class="packinglist-col-total">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td class="packinglist-col-action"><a href="#" v-on:click.stop.prevent="removeItem(index)"><i class="far fa-times-circle"></i></a></td>
                            <td class="packinglist-col-qty">
                                <validation-provider :rules="{ required: true, min_value: 1 }" v-slot="{ classes }" name="Quantity">
                                    <div class="input-group">
                                        <input class="form-control form-control-sm" v-bind:class="classes" type="number" min="1" step="1" v-model="item.no_of_units" />
                                        <div class="input-group-append" v-bind:class="classes">
                                            <span class="input-group-text packinglist-unit" v-text="item.unit_of_measure"></span>
                                        </div>
                                    </div>
                                </validation-provider>
                            </td>
                            <td class="packinglist-col-desc" v-text="item.description_of_goods"></td>
                            <td class="packinglist-col-value">
                                <validation-provider :rules="{ required: true, min_value: 0.01 }" v-slot="{ classes }" name="Unit Value">
                                    <div class="input-group">
                                        <div class="input-group-prepend" v-bind:class="classes">
                                            <span class="input-group-text">{{ currencySymbol }}</span>
                                        </div>
                                        <input type="number" inputmode="decimal" class="form-control form-control-sm" v-bind:class="classes" v-model.number="item.unit_value" min="0.01" step="any" placeholder="0.00" />
                                    </div>
                                </validation-provider>
                            </td>
                            <td class="packinglist-col-total" v-text="getItemTotal(item)"></td>
                        </tr>
                        <tr class="table-active packinglist-table-footer">
                            <td class="packinglist-col-action"></td>
                            <td class="packinglist-col-qty"></td>
                            <td class="packinglist-col-desc"></td>
                            <td class="packinglist-col-value"></td>
                            <td class="packinglist-col-total font-weight-bold py-2">{{ currencySymbol }}{{ totalValue }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="my-4" v-if="(differenceToTargetValue > 0.01 || differenceToTargetValue < -0.01) && targetvalue > 0 && items.length > 0" style="display:block;">
                    <div v-if="differenceToTargetValue > 0" style="display:block;">
                        The value of your items is <label class="font-weight-bold">{{currencySymbol}}{{Math.abs(differenceToTargetValue)}}</label> less than the value you declared at checkout.
                    </div>
                    <div v-if="differenceToTargetValue <= 0" style="display:block;">
                        The value of your items is <label class="font-weight-bold">{{currencySymbol}}{{Math.abs(differenceToTargetValue)}}</label> greater than the value you declared at checkout.
                    </div>
                    <div>Please adjust the values or use our auto adjust button.</div>
                    <button class="btn btn-outline-success mt-2" v-on:click.stop.prevent="adjustValuesToMatchTarget">Auto Adjust Values</button>
                </div>

                <div class="my-2">
                    <div class="font-weight-bold" v-bind:class="classes">Export Confirmation</div>
                    <validation-provider :rules="{ required: true }" v-slot="{ errors }" name="Export Confirmation">
                        <input type="hidden" v-model="exportReason">
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                    <div class="form-check radiobutton" v-for="reason in exportReasons" :key="reason.value">
                        <label class="form-check-label" :for="'export-reason-' + reason.value">
                            <input class="form-check-input" type="radio" :id="'export-reason-' + reason.value" v-model="exportReason" :value="reason.value">
                            {{ reason.text }}
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>

                <div class="mt-2">
                    <validation-provider :rules="{ required: true }" v-slot="{ errors, classes }" name="Signature">
                        <input type="hidden" v-model="signaturePadValid" />
                        <div class="font-weight-bold">Senders Signature</div>
                        <div>
                            <div class="signature-wrapper">
                                <SignaturePad ref="signaturePad" :senders-name="sendersName" class="signature-pad" v-bind:class="classes" :options="signaturePadOptions" />
                            </div>
                            <div class="text-danger">{{ errors[0] }}</div>
                        </div>
                        <div>
                            <div class="text-right signature-eraser-container">
                                <button type="button"
                                        class="btn btn-stylised fa fa-eraser fa_custom_r fa-custom-r-hover-effect fa-5x px-0 py-0 shadow-none"
                                        title="Clear"
                                        v-on:click.stop.prevent="clearSignature">
                                </button>
                            </div>
                        </div>
                    </validation-provider>
                </div>

                <div class="mt-4">
                    <div id="PackingListErrorMessage" class="text-danger"></div>
                    <button type="submit" class="btn btn-success pkglistbutton" value="Save Packing List">Save Packing List</button>
                </div>
            </form>
        </ValidationObserver>


        <!--Modal popup for price greater and less-->
        <div class="modal fade"
             id="Smb__dialogErrorTotalValue-modal"
             tabindex="-1" role="dialog"
             aria-labelledby="Smb__prohibitedList-modal"
             aria-hidden="true"
             data-backdrop="static"
             data-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div id="ProhibitedInfoArea" class="modal-content card card-body">
                    <h2>{{errorInForm}}</h2>
                    <div v-html="differenceToTargetValue > 0 ? valueTooLowErrorMessage : valueTooHighErrorMessage"></div>
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-4 col-lg-9 mobile-button-text-center mb-3">
                            <input type="button" class="btn btn-outline-success" data-dismiss="modal" value="Back To Packing List" />
                        </div>
                        <div class="col-sm-12 col-md-5 col-lg-3 mobile-button-text-center mb-3">
                            <input type="button" id="btnAutoAdjustConfirm" class="btn btn-success" v-on:click="autoAdjustConfirm" value="Confirm" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Modal popup for unit price zero-->
        <div class="modal fade"
             id="Smb__dialogErrorValue-modal"
             tabindex="-1" role="dialog"
             aria-labelledby="Smb__prohibitedList-modal"
             aria-hidden="true"
             data-backdrop="static"
             data-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div id="ProhibitedInfoArea" class="modal-content card card-body">
                    <h2>{{errorInForm}}</h2>
                    <div>{{PackingListErrorValue}}</div>
                    <div class="row mt-3">
                        <div class="col-12 text-right">
                            <input type="button" class="btn btn-success" data-dismiss="modal" value="Close" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Modal popup for rows < 3-->
        <div class="modal fade"
             id="Smb__dialogErrorCount-modal"
             tabindex="-1" role="dialog"
             aria-labelledby="Smb__prohibitedList-modal"
             aria-hidden="true"
             data-backdrop="static"
             data-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div id="ProhibitedInfoArea" class="modal-content card card-body">
                    <h2>{{errorInForm}}</h2>
                    <div>{{PackingListErrorCount}}</div>
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-4 col-lg-9 mobile-button-text-center mb-3">
                            <input type="button" class="btn btn-outline-success" data-dismiss="modal" value="Back To Packing List" />
                        </div>
                        <div class="col-sm-12 col-md-5 col-lg-3 mobile-button-text-center mb-3">
                            <input type="button" id="btnAutoAdjustSave" class="btn btn-success pl-5 pr-5" v-on:click="autoAdjustSave" value="Save" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Prohibited Items Alert Modal-->
        <div class="modal fade"
             id="Smb__prohibitedList-modal"
             tabindex="-1" role="dialog"
             aria-labelledby="Smb__prohibitedList-modal"
             aria-hidden="true"
             data-backdrop="static"
             data-keyboard="false">
            <div class="modal-dialog modal-lg">
                <div id="ProhibitedInfoArea" class="modal-content card card-body">
                    <h2>{{metaData.importantInformation}}</h2>
                    <div v-for="(message, index) in prohibitedItemsModalMessages"
                         :key="index">
                        {{message}}
                    </div>
                    <div v-if="hasAlternativeSuggestions">
                        <div class="mt-3">Please select from one of the alternative options below or enter your own new description:</div>
                        <div class="mt-3">
                            <a v-for="(item, index) in alternativeSuggestions"
                               :key="index"
                               href="#/"
                               @click="selectAlternativeOption(item)"
                               class="badge badge-pill badge-light fs-1em m-1">
                                {{item}}
                            </a>
                        </div>

                        <input type="text" class="form-control mt-3" v-model="alternativeText" />
                        <input type="button"
                               class="btn btn-success mt-3"
                               v-bind:disabled="!hasAlternativeTextEntered"
                               @click="setNewDescription"
                               :value="metaData.confirm" />
                    </div>
                    <div v-else
                         class="top-gutter mt-3">
                        <input type="button" class="btn btn-success" data-dismiss="modal" :value="metaData.iAgree" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = window.$;
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import { required, min_value } from 'vee-validate/dist/rules';
    import SignaturePad from '../signature/SignaturePad.vue';
    import _ from 'lodash';
    import CountrySelect from '../routing/CountrySelect.vue';

    extend('required', { ...required, message: '{_field_} is required' });
    extend('min_value', { ...min_value, message: '{_field_} must be atleast {min}' });

    export default {
        name: "custacc-packinglist",

        components: {
            SignaturePad,
            ValidationProvider,
            ValidationObserver,
            CountrySelect
         },
        props: {
            journeyid: String,
            targetvalue: String,
            orderreference: String,
            metaData: Object
        },
        data: function () {
            return {
                items: [],
                currencyCode: '',
                currencySymbol: '�',
                prohibitedListItems: [],
                packingListErrorGreater: '',
                packingListErrorLess: '',
                PackingListErrorValue: '',
                PackingListErrorCount: '',
                ContactUsUrl: '',
                errorInForm: '',
                sendersName: '',
                exportReason: null,
                exportReasons: [],
                signaturePad: null,
                prohibitedItemsModalMessages: [],
                alternativeSuggestions: [],
                alternativeText: '',
                fixedCountryOfOrigin: '',

                newQuantity: null,
                newUnits: 'Piece',
                newDescription: '',
                newCountry: '',
                newValue: null,
                signaturePadOptions: {},
                signaturePadValid: '',

                isLoadingCountries: true,
                countryList: []
            };
        },
        computed: {
            hasAlternativeSuggestions: function () {
                return this.alternativeSuggestions.length > 0;
            },
            hasAlternativeTextEntered: function () {
                return this.alternativeText.trim() !== '';
            },
            totalValue: function () {
                var val = 0;
                for (var i = 0; i < this.items.length; i++) {
                    val += parseFloat(this.items[i].unit_value) * parseFloat(this.items[i].no_of_units);
                }
                return val.toFixed(2);
            },
            valueTooLowErrorMessage: function () {
                const message = this.packingListErrorLess
                    .replace("{0}", this.currencySymbol + this.totalValue)
                    .replace("{1}", this.currencySymbol + this.targetvalue)
                    .replace("{2}", this.ContactUsUrl);

                return message;
            },
            valueTooHighErrorMessage: function () {
                const message = this.packingListErrorGreater
                    .replace("{0}", this.currencySymbol + this.totalValue)
                    .replace("{1}", this.currencySymbol + this.targetvalue)
                    .replace("{2}", this.ContactUsUrl);

                return message;
            },
            differenceToTargetValue: function () {
                return parseFloat(this.targetvalue - this.totalValue).toFixed(2);
            },
            multiplierToTargetValue: function () {
                if (parseFloat(this.targetvalu) !== 0) {
                    return parseFloat(this.targetvalue / this.totalValue);
                }
                return 1;
            },
            hasFixedCountry: function () {
                if (this.fixedCountryOfOrigin)
                    return true;

                return false;
            }
        },
        mounted: function () {
            this.signaturePadOptions = {
                onEnd: this.signaturePadOnEnd
            }
            this.loadPackingListItems();
            this.getProhibitedItemsAlert();
            this.applyProhibitedItemsAlertModalEvents();
        },
        methods: {
            signaturePadOnEnd: function (e) {
                if (this.$refs.signaturePad && !this.$refs.signaturePad.isEmpty())
                    this.signaturePadValid = 'true';
                else
                    this.signaturePadValid = '';
            },
            getItemTotal: function (item) {
                let total = item.no_of_units * item.unit_value;
                return this.currencySymbol + total.toFixed(2);
            },
            saveSignature: function () {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                this.sendersName = data;
                return isEmpty;
            },
            clearSignature() {
                this.$refs.signaturePad.clearSignature();
                this.sendersName = '';
                this.signaturePadValid = '';
            },
            addItem: function () {
                if (this.fixedCountryOfOrigin) {
                    this.newCountry = this.fixedCountryOfOrigin;
                }

                this.items.push({
                    no_of_units: this.newQuantity,
                    unit_of_measure: this.newUnits,
                    description_of_goods: this.newDescription,
                    country_of_origin: this.newCountry,
                    unit_value: this.newValue
                })
                $('#PackingListErrorMessage').hide();

                // Reset the new item values
                this.newQuantity = null;
                this.newUnits = 'Piece';
                this.newDescription = '';
                this.newValue = null;
                this.$refs.addObserver.reset();
            },
            removeItem: function (index) {
                this.items.splice(index, 1);
            },
            saveForm: function () {
                const _this = this;
                this.$refs.formObserver.validate().then((isValid) => {
                    if (isValid) {
                        _this.addPackingListItems(false);
                    }
                });
            },
            addPackingListItems: function (overrideUnsavedFormCheck) {
                var self = this;

                if (self.packingListItemsIsValid()) {
                    $('#Smb__dialogErrorValue-modal').modal('show');
                    return;
                }

                if (self.packingListCount(overrideUnsavedFormCheck)) {
                    $('#Smb__dialogErrorCount-modal').modal('show');
                    return;
                }

                if (self.packingListValueIsValid()) {
                    $('#Smb__dialogErrorTotalValue-modal').modal('show');
                    return;
                }

                /*else if (self.differenceToTargetValue > 0.01 && ignoreRemainder == false) {
                    var totalValueLess = this.packingListErrorLess.replace("{0}", self.currencySymbol + self.totalValue);
                    var targetValueLess = totalValueLess.replace("{1}", self.currencySymbol + self.targetvalue);
                    var errorMessageLess = targetValueLess.replace("{2}", self.ContactUsUrl);
                    document.getElementById("packingListErrorGreaterId").innerHTML = errorMessageLess;
                    $('#Smb__dialogErrorTotalValue-modal').modal('show');
                } else if (self.differenceToTargetValue < -0.01 && ignoreRemainder == false) {
                    var totalValueGreater = this.packingListErrorGreater.replace("{0}", self.currencySymbol + self.totalValue);
                    var targetValueGreater = totalValueGreater.replace("{1}", self.currencySymbol + self.targetvalue);
                    var errorMessageGreater = targetValueGreater.replace("{2}", self.ContactUsUrl);
                    document.getElementById("packingListErrorGreaterId").innerHTML = errorMessageGreater;
                    $('#Smb__dialogErrorTotalValue-modal').modal('show');
                } else {*/

                var signed = Promise.resolve(self.saveSignature());
                var proceed = signed.then(function (value) {
                    if (value) {
                        return value;
                    }
                    self.items.forEach(function (element) {
                        if (element.no_of_units <= 0) {
                            $('#PackingListErrorMessage').html('Packing List data is incomplete. Please enter missing details in highlighted rows.');
                            $('#PackingListErrorMessage').show();
                        }
                    });
                    var params = {
                        OrderJourneyId: self.journeyid,
                        SendersName: self.sendersName,
                        CurrencyCode: self.currencyCode,
                        InvoiceItems: self.items,
                        ExportReason: self.exportReason
                    }
                    $.post('/en-gb/api/account/createpackinglistasync', params, function (response) {
                        if (response.success) {
                            window.location.replace('/account/documents?id=' + self.orderreference);
                        }
                        else {
                            $('#PackingListErrorMessage').html('An error occured, please contact us.');
                        }
                    });
                });

            },
            autoAdjustConfirm: function () {
                var self = this;
                $('#Smb__dialogErrorTotalValue-modal').modal('hide');
                self.adjustValuesToMatchTarget();
                self.addPackingListItems(true);
            },
            autoAdjustSave: function () {
                var self = this;
                $('#Smb__dialogErrorCount-modal').modal('hide');
                self.adjustValuesToMatchTarget();
                self.addPackingListItems(true);
            },
            calculateLineTotal: function (packing_list) {
                var total = parseFloat(packing_list.no_of_units) * parseFloat(packing_list.unit_value);
                if (!isNaN(total)) {
                    packing_list.total_value = total.toFixed(2);
                }
            },
            loadPackingListItems: function () {
                var self = this;
                var params = {
                    orderJourneyId: this.journeyid
                }
                $.getJSON('/en-gb/api/account/getpackinglist', params, function (result) {
                    self.currencyCode = result.data.currencyCode;
                    self.currencySymbol = result.data.currencySymbol;
                    self.items = result.data.invoiceItems;
                    self.sendersName = result.data.sendersName;
                    self.signaturePadValid = result.data.sendersName;
                    self.exportReason = result.data.exportReason;
                    self.exportReasons = result.data.exportReasons;
                    self.packingListErrorGreater = result.data.packingListErrorGreater;
                    self.packingListErrorLess = result.data.packingListErrorLess;
                    self.PackingListErrorValue = result.data.packingListErrorValue;
                    self.PackingListErrorCount = result.data.packingListErrorCount;
                    self.ContactUsUrl = result.data.contactUsUrl;
                    self.errorInForm = result.data.errorInForm;
                    self.fixedCountryOfOrigin = result.data.fixedCountryOfOrigin;
                    // Default our country to either the fixed country or our origin
                    if (result.data.fixedCountryOfOrigin)
                        self.newCountry = result.data.fixedCountryOfOrigin;
                    else
                        self.newCountry = result.data.countryIso;
                });

                $.getJSON('/en-gb/api/addressapi/getbillingcountriesjsonasync', function (result) {
                    self.isLoadingCountries = false;
                    self.countryList = result;
                });

            },
            adjustValuesToMatchTarget: function () {
                // First we adjst the values by a percentage to get them as close as possible in a relative cost
                var self = this;
                var multiplier = self.multiplierToTargetValue;
                var difference = self.differenceToTargetValue;
                if (multiplier > 0 && (difference < -0.01 || difference > 0.01)) {
                    for (var i = 0; i < self.items.length; i++) {
                        if (self.items[i].unit_value > 0) {
                            var adjustNewValue = parseFloat(Math.max(0.01, parseFloat(self.items[i].unit_value) * multiplier).toFixed(2));
                            self.items[i].unit_value = adjustNewValue;
                            self.items[i].total_value = parseFloat(adjustNewValue * self.items[i].no_of_units).toFixed(2);
                        }
                    }
                }

                // Now we just move everything by a fixed amount if the penny difference is greater than the number of items
                difference = self.DifferenceToTargetValue;
                var totalQuantity = self.items.reduce((a, b) => +a + +b.no_of_units, 0);
                if (totalQuantity > 0 && totalQuantity <= Math.abs(difference) * 100 && (difference < -0.01 || difference > 0.01)) {
                    var differenctUnitAdjustment = parseFloat(parseFloat(difference / totalQuantity).toFixed(2));
                    for (var j = 0; j < self.items.length; j++) {
                        if (self.items[j].unit_value > 0) {
                            var differenceNewValue = parseFloat(Math.max(0.01, parseFloat(self.items[j].unit_value) + differenctUnitAdjustment).toFixed(2));
                            self.items[j].unit_value = differenceNewValue;
                        }
                    }
                }

                // Make small changes to get exact amount if possible
                for (var tries = 0; tries < 10; tries++) {
                    difference = self.differenceToTargetValue;
                    if (Math.abs(self.differenceToTargetValue) < 0.005)
                        break;

                    var unitAdjustment = difference > 0 ? 0.01 : -0.01;
                    for (var k = 0; k < self.items.length; k++) {
                        if (Math.abs(self.differenceToTargetValue) * 100 >= self.items[k].no_of_units) {
                            if (self.items[k].unit_value > 0) {
                                var unitNewValue = parseFloat(Math.max(0.01, parseFloat(self.items[k].unit_value) + unitAdjustment).toFixed(2));
                                self.items[k].unit_value = unitNewValue;

                                if (Math.abs(self.differenceToTargetValue) < 0.005)
                                    break;
                            }
                        }
                    }
                }
            },
            clearPackingListErrorMessage: function () {
                $('#smPackingListErrorMessage').html('');
            },


            // Prohibited Items
            checkDescriptionForProhibitedItems: function () {
                this.alternativeSuggestions = [];

                const descriptionOfGoods = this.newDescription;
                const guids = []; // storing Prohibited Items GUID's
                const messages = [];

                for (const prohibitedItem of this.prohibitedListItems) {
                    const prohibitedItemWords = prohibitedItem.words.split(/[,]+/);

                    for (const prohibitedItemWord of prohibitedItemWords) {
                        const descriptionIsProhibitedItemWord =
                            prohibitedItemWord.replace(/^\s+|\s+$/g, "").toLowerCase() === descriptionOfGoods.trim().toLowerCase();

                        if (prohibitedItem.isExactMatchCheck && descriptionIsProhibitedItemWord) {
                            if (!guids.includes(prohibitedItem.id)) {
                                guids.push(prohibitedItem.id);
                                messages.push(prohibitedItem.description);
                                this.alternativeSuggestions = prohibitedItem.alternativeSuggestions;
                                break;
                            }
                        }

                        const regEx = new RegExp(`(\\b${prohibitedItemWord} | ${prohibitedItemWord} | ${prohibitedItemWord}\\b)`, 'i');
                        const descriptionContainsProhibitedItemWord = descriptionOfGoods.trim().match(regEx);

                        if (!prohibitedItem.isExactMatchCheck && (descriptionIsProhibitedItemWord || descriptionContainsProhibitedItemWord)) {
                            //There may be multiple Prohibited Item words with same GUID. Still message has to show only once.
                            if (!guids.includes(prohibitedItem.id)) {
                                guids.push(prohibitedItem.id);
                                messages.push(prohibitedItem.description);
                                break;
                            }
                        }
                    }

                    if (this.alternativeSuggestions.length > 0)
                        break;
                }

                this.prohibitedItemsModalMessages = messages;

                if (messages.length > 0)
                    $('#Smb__prohibitedList-modal').modal('show');
            },
            // Apply the events for opening and closing the prohibited items alerts
            applyProhibitedItemsAlertModalEvents: function () {
                const self = this;

                $('#Smb__prohibitedList-modal').on('hidden.bs.modal', function (e) {
                    // Re-check for any prohibited items in new description entered through modal
                    if (self.hasAlternativeSuggestions) {
                        self.checkDescriptionForProhibitedItems();
                        self.alternativeText = '';
                    }
                });

                $('#Smb__prohibitedList-modal').on('shown.bs.modal', function (e) {
                    // Make sure we aren't showing modal if no messages to show
                    if (self.prohibitedItemsModalMessages.length === 0)
                        $('#Smb__prohibitedList-modal').modal('hide');
                });
            },
            // Load the prohibited items from the server
            getProhibitedItemsAlert: function () {
                var self = this;
                $.getJSON('/en-gb/api/accountapi/getprohibiteditemsasync', function (result) {
                    let resultsOrderedByIsExactMatchCheckItemsFirst = [];

                    if (result)
                        resultsOrderedByIsExactMatchCheckItemsFirst = result.sort((a, b) => b.isExactMatchCheck - a.isExactMatchCheck);

                    self.prohibitedListItems = resultsOrderedByIsExactMatchCheckItemsFirst;
                });
            },
            packingListValueIsValid: function () {
                if (Math.abs(this.differenceToTargetValue) > 0.01) {
                    return true;
                }
                return false;
            },
            packingListItemsIsValid: function () {
                var self = this;
                for (var i = 0; i < self.items.length - 1; i++) {
                    if (self.items[i].unit_value <= 0) {
                        return true;
                    }
                }
                return false;
            },
            packingListCount: function (overrideUnsavedFormCheck) {
                var self = this;
                if (self.items.length < 3 && overrideUnsavedFormCheck !== true) {
                    return true;
                }
                return false;
            },
            updateGoodsDescription: function (newDescription) {
                this.newDescription = newDescription;
                $('#Smb__prohibitedList-modal').modal('hide');
            },
            selectAlternativeOption: function (selectedOption) {
                this.updateGoodsDescription(selectedOption);
            },
            setNewDescription: function () {
                this.updateGoodsDescription(this.alternativeText);
            }
        },
        watch: {
            newDescription: function (newValue, oldValue) {
                if (newValue === oldValue)
                    return;

                this.checkDescriptionForProhibitedItems();
            }
        }
    }

</script>

