<template>
    <div>
        <div class="form-group row">
            <validation-provider :rules="{ required: true, min_value: 1 }" :custom-messages="{ min_value: '{_field_} is required' }" v-slot="{ classes, errors }" :name="smbI18n.checkout.AddressType" vid="address-addresstype" slim>
                <div class="col-12" v-bind:class="classes">
                    <label for="address-addresstype">{{ smbI18n.checkout.AddressType }}</label>
                    <select-2 id="address-addresstype"
                              :options="addressTypes"
                              v-model.number="value.addressTypeId"
                              class="form-control"
                              style="width:100%;"
                              placeholder="Please Select"
                              data-minimum-results-for-search="Infinity">
                    </select-2>
                    <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                </div>
            </validation-provider>
        </div>
        <warning-card v-if="addressTypeMessage.length" :messages="[addressTypeMessage]"></warning-card>

        <div v-if="value.addressTypeId">
            <div class="form-group row" v-show="isFieldVisible('contactName')">
                <validation-provider :rules="{ required: true, max: 35, minWordCount: 2 }" vid="address-contactname" v-slot="{ classes, errors }" :name="fieldName('contactName')" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-contactname">{{ fieldName('contactName') }}</label>
                        <input type="text" class="form-control" id="address-contactname" name="contactname" placeholder="" maxlength="35"
                               v-model="value.contactName" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                        <div class="text-danger" v-if="errors.length" v-html="smbI18n.checkout.ContactNameWarning"></div>
                    </div>
                </validation-provider>
            </div>
            <div class="form-group row" v-show="isFieldVisible('contactPhone')">
                <validation-provider :rules="{ phoneNumber: { min: 4, max: 25 } }" vid="address-contactphonenumber" v-slot="{ classes, errors }" :name="fieldName('contactPhone')" slim mode="eager">
                    <div class="col-12" v-bind:class="classes">
                        <phone-number v-model="value.phoneNumber" :label="fieldName('contactPhone')" :countries="phoneNumberCountries"></phone-number>
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row" v-if="countryDetails.usesPostcode" v-show="isFieldVisible('postcode')">
                <validation-provider v-if="usesPostcodeSearch" mode="eager" :rules="{ required: countryDetails.usesPostcode, max: 16, postcodeFormat: countryDetails.postcodeFormat }"
                                     :name="fieldName('postcode')" vid="address-postcode-tmp" v-slot="{ classes, errors }" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-postcode-tmp">{{ fieldName('postcode') }}</label>
                        <postcode-finder id="address-postcode-tmp"
                                         name="postcode"
                                         v-model="value.postcode"
                                         v-on:postcode-change="postcodeChange"
                                         v-on:address-selected="addressSelected"
                                         :country-code="value.countryIso"
                                         address-key="">
                        </postcode-finder>
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
                <validation-provider v-else :rules="{ required: countryDetails.usesPostcode, max: 16, postcodeFormat: countryDetails.postcodeFormat }"
                                     :name="fieldName('postcode')" vid="address-postcode" v-slot="{ classes, errors }" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-postcode">{{ fieldName('postcode') }}</label>
                        <input type="text" class="form-control" id="address-postcode" name="Postcode" maxlength="16"
                               v-model="value.postcode"
                               v-placeholder="postcodeText" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row"
                 v-show="isFieldVisible('companyName') && countryDetails.usesCompanyName">
                <validation-provider :rules="{ max: 35 }" vid="address-companyname" v-slot="{ classes, errors }" :name="fieldName('companyName')" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-companyname">{{ fieldName('companyName') }} <small class="text-muted">(optional)</small></label>
                        <input type="text" class="form-control" id="address-companyname" placeholder="" maxlength="35" v-model="value.companyName" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row" v-if="isFieldVisible('packageLocation')">
                <div class="col-12">
                    <label for="address-haspackagelocation">{{ smbI18n.checkout.DoYouHaveABuzzer }}</label>
                </div>
                <div class="col-12" v-bind:class="classes">
                    <label class="form-check form-check-inline radiobutton" for="address-haspackagelocation-yes">
                        {{ smbI18n.checkout.Yes }}
                        <input class="form-check-input" type="radio" name="haspackagelocation" id="address-haspackagelocation-yes" value="yes" v-model="hasPackageLocation" />
                        <span class="checkmark"></span>
                    </label>
                    <validation-provider :rules="{ required: true }" vid="address-hasPackageLocation" v-slot="{ errors }" name="This question" slim>
                        <label class="form-check form-check-inline radiobutton" for="address-haspackagelocation-no">
                            {{ smbI18n.checkout.No }}
                            <input class="form-check-input" type="radio" name="haspackagelocation" id="address-haspackagelocation-no" value="no" v-model="hasPackageLocation" />
                            <span class="checkmark"></span>
                        </label>
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
            </div>
            <div class="form-group row" v-show="isFieldVisible('packageLocation') && hasPackageLocation === 'yes'">
                <validation-provider :rules="{ max: 11 }" vid="address-packageLocation" v-slot="{ classes, errors }" :name="fieldName('packageLocation')" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-packagelocation">{{ fieldName('packageLocation') }} <small class="text-muted">(optional)</small></label>
                        <input type="text" class="form-control" id="address-packageLocation" name="Package location" placeholder="" maxlength="11"
                               v-model="value.packageLocation" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row" v-show="isFieldVisible('line1')">
                <validation-provider :rules="{ required: true, max: 35 }" vid="address-line1" v-slot="{ classes, errors }" :name="fieldName('line1')" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-line1">{{ fieldName('line1') }}</label>
                        <input type="text" class="form-control" id="address-line1" name="address-line1" placeholder="" data-val-required="Address Line 1" maxlength="35" v-model="value.line1" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>
            <div class="form-group row" v-show="isFieldVisible('line2')">
                <validation-provider :rules="{ max: 35 }" vid="address-line2" v-slot="{ classes, errors }" :name="fieldName('line2')" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-line2">{{ fieldName('line2') }} <small class="text-muted">(optional)</small></label>
                        <input type="text" class="form-control" id="address-line2" name="address-line2" maxlength="35" placeholder=""
                               v-model="value.line2" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>
            <!-- Only show this field if address uses company name is false -->
            <div class="form-group row" v-if="!countryDetails.usesCompanyName">
                <validation-provider :rules="{ max: 35 }" vid="address-line3" v-slot="{ classes, errors }" :name="fieldName('line3')" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-line3">{{ fieldName('line3') }} <small class="text-muted">(optional)</small></label>
                        <input type="text" class="form-control" id="address-line3" name="Address line 3" maxlength="35" placeholder=""
                               v-model="value.line3" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>
            <div class="form-group row" v-show="isFieldVisible('city')">
                <validation-provider :rules="{ required: true, max: 64 }" vid="address-cityname" v-slot="{ classes, errors }" :name="fieldName('city')" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-cityname">{{ fieldName('city') }}</label>
                        <input type="text" v-if="!cities || cities.length <= 1" class="form-control" id="address-cityname" placeholder="" maxlength="64" v-model="value.cityName" />
                        <select-2 v-else class="form-control w-100" placeholder="Please Select"
                                  id="address-cityname" :options="cities" v-model="value.cityName" data-minimum-results-for-search="Infinity"></select-2>
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row" v-if="countryDetails.usesRegions">
                <validation-provider :rules="{ required: true }" vid="address-regionid" v-slot="{ classes, errors }" :name="fieldName('region')" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="address-regionid">{{ fieldName('region') }}</label>
                        <select-2 id="address-regionid" class="form-control" style="width:100%;" placeholder="Please Select" data-minimum-results-for-search="Infinity"
                                  :options="countryDetails.regions"
                                  v-model="value.regionId">
                        </select-2>
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row">
                <div class="col-12">
                    <label for="address-countryiso">{{ fieldName('country') }}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="address-countryiso" disabled="disabled" :value="countryDetails.name" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$;
    import Select2 from '../../../components/select-2/Select2.vue';
    import PostcodeFinder from '../../../components/postcode/PostcodeFinder.vue';
    import PhoneNumber from './PhoneNumber.vue';
    import WarningCard from '../../../components/shared/WarningCard.vue';
    import { ValidationProvider, extend } from 'vee-validate';
    import { required, max, numeric, min_value } from 'vee-validate/dist/rules';
    import _ from 'lodash';

    extend('required', { ...required, message: '{_field_} is required' });
    extend('min_value', { ...min_value, message: '{_field_} must be at least {min}' });
    extend('max', { ...max, message: '{_field_} must be less than {length} characters long' });
    extend('numeric', { ...numeric, message: '{_field_} may only contain numeric characters' });
    extend('minWordCount', { ...numeric, message: '{_field_} must contain a first and last name' });

    export default {
        name: "journey-address",
        components: {
            Select2,
            PostcodeFinder,
            ValidationProvider,
            WarningCard,
            PhoneNumber
        },
        props: {
            value: {
                addressTypeId: Number,
            },
            addressFieldFlags: {},
            addressTypes: Array,
            phoneNumberCountries: Array,
            countryDetails: {},
            hasSelectedSavedAddress: { type: Boolean, default: false }
        },
        data: function () {
            return {
                hasPackageLocation: null,
                cities: []
            }
        },
        computed: {
            addressType: function () {
                let addressTypeId = this.value.addressTypeId;
                let result = this.addressTypes.filter(obj => {
                    return obj.id === addressTypeId;
                });
                return result ? result[0] : null;
            },
            addressTypeMessage: function () {
                return this.addressType ? this.addressType.description : '';
            },
            usesPostcodeSearch: function () {
                return this.value.countryIso.substring(0, 2) === 'GB';
            },
            postcodeValue: function () {
                return this.value.postcode;
            },
            addressId: function () {
                return this.value.id;
            },
            addressTypeId: function () {
                return this.value.addressTypeId;
            }
        },
        methods: {
            isFieldVisible: function(flagName) {
                let fieldFlag = this.addressFieldFlags.filter(obj => {
                    return obj.name === flagName;
                });

                if (!fieldFlag || !this.addressType)
                    return true;

                // Check our selected address type for any overwritten text
                return (this.addressType.showFieldFlags & fieldFlag[0].flag) !== 0;
            },

            fieldName: function(flagName) {
                // Get which field this is for based on the name
                let fieldFlag = this.addressFieldFlags.filter(obj => {
                    return obj.name === flagName;
                });

                if (!fieldFlag)
                    return '';

                // Check our selected address type for any overwritten text
                if (this.addressType) {
                    for (var i = 0; i < this.addressType.overwriteFieldNames.length; i++) {
                        if (fieldFlag[0].flag === this.addressType.overwriteFieldNames[i].fieldToOverwrite) {
                            return this.addressType.overwriteFieldNames[i].overwriteTextMapped;
                        }
                    }
                }

                return fieldFlag[0].text;
            },

            addressSelected: function (address) {
                if (address.Line5) {
                    this.value.line1 = address.Line1 + ', ' + address.Line2;
                    this.value.line2 = address.Line3 + ', ' + address.Line4 + ', ' + address.Line5;
                }
                else if (address.Line4) {
                    this.value.line1 = address.Line1 + ', ' + address.Line2;
                    this.value.line2 = address.Line3 + ', ' + address.Line4;
                }
                else if (address.Line3) {
                    this.value.line1 = address.Line1 + ', ' + address.Line2;
                    this.value.line2 = address.Line3;
                }
                else {
                    this.value.line1 = address.Line1;
                    this.value.line2 = address.Line2;
                }
                this.value.companyName = address.Company;
                this.value.postcode = address.PostalCode;
                this.value.cityName = address.City;

                // set the city list to just the valid city from the address search
                this.cities = [address.City];
            },

                // Need to check province values to set region
/*{
  "Id": "GB|RM|A|1868004",
  "DomesticId": "1868004",
  "Language": "ENG",
  "LanguageAlternatives": "ENG",
  "Department": "",
  "Company": "",
  "SubBuilding": "",
  "BuildingNumber": "148",
  "BuildingName": "",
  "SecondaryStreet": "",
  "Street": "Ringwood Road",
  "Block": "",
  "Neighbourhood": "",
  "District": "",
  "City": "Poole",
  "Line1": "148 Ringwood Road",
  "Line2": "",
  "Line3": "",
  "Line4": "",
  "Line5": "",
  "AdminAreaName": "Poole",
  "AdminAreaCode": "",
  "Province": "",
  "ProvinceName": "",
  "ProvinceCode": "",
  "PostalCode": "BH14 0RP",
  "CountryName": "United Kingdom",
  "CountryIso2": "GB",
  "CountryIso3": "GBR",
  "CountryIsoNumber": 826,
  "SortingNumber1": "92173",
  "SortingNumber2": "",
  "Barcode": "(BH140RP1LP)",
  "POBoxNumber": "",
  "Label": "148 Ringwood Road\nPOOLE\nBH14 0RP\nUNITED KINGDOM",
  "Type": "Residential",
  "DataLevel": "Premise",
}*/

            updateCities: function () {
                // We don't update the city list if it's Ireland because Ireland only has a small number of cities. This rule might change if we can grab the city from an address/eirecode search using loqate or similar service
                if (this.value.countryIso === 'IE')
                    return;

                // If there isn't a postcode entered then clear all cities
                if (this.countryDetails.usesPostcode && !this.value.postcode)
                    this.cities = [];

                // Use the PostcodeFinder's result instead if usesPostcodeSearch
                if (this.usesPostcodeSearch)
                    return;

                // If a saved address being selected, just use the saved address city (and region, if applicable)
                if (this.hasSelectedSavedAddress) {
                    this.$emit('selected-saved-address');
                    return;
                }

                // Load the available cities
                this.loadCities();
            },

            loadCities: function () {
                let _this = this;
                // Get the cities from the server
                var params = {
                    countryIso: this.value.countryIso,
                    postcode: this.value.postcode
                };

                var jqxhr = $.getJSON('/en-gb/api/addressapi/getaddresscitiesjsonasync', params, function (result) {
                    _this.cities = result;

                    // Clear city if no result
                    if (!_this.cities || _this.cities.length === 0)
                        _this.value.cityName = '';

                    if (_this.cities) {
                        // If only 1 result then set that as the city name
                        if (_this.cities.length === 1)
                            _this.value.cityName = _this.cities[0].text;

                        // If more than 1, clear city to require selection for field validation
                        else if (_this.cities.length > 1)
                            _this.value.cityName = '';
                    }

                    _this.updateRegion();

                    // Set region based on the region code of the first result (CW: I think we can assume all results are within the same region)
                    //if (this.countryDetails.usesRegions && this.cities[0])
                    //    dispatch('updateRegion', result[0].regionCode);
                });

                jqxhr.fail(function () {
                    dispatch('errorLog', { description: 'Action - loadCities failed with errors', outcome: 'Error' });
                    _this.cities = [];
                    //dispatch('showErrorToast');
                });

            },

            updateRegion: function () {
                if (!this.countryDetails.usesRegions)
                    return;

                // Set region based on region code associated with the city/cities
                if (this.cities[0]) {
                    const { regionCode } = this.cities[0];
                    const region = this.countryDetails.regions
                        .find(x => x.code === regionCode);

                    if (region !== undefined) {
                        this.value.regionId = region.id;
                        return;
                    }
                }

                this.value.regionId = '';
            },

            getShownAddressFields: function () {
                let fields = {};

                const _this = this;
                this.addressFieldFlags.forEach(field => {
                    let fieldName = field.name;

                    const isFieldShown = _this.isFieldVisible(fieldName);

                    // Match address field flag to certain address properties
                    if (fieldName === 'contactPhone')
                        fieldName = 'phoneNumber';
                    else if (fieldName === 'city')
                        fieldName = 'cityName';
                    else if (fieldName === 'region')
                        fieldName = 'regionId';

                    fields[fieldName] = isFieldShown;
                });

                // Additional field checks
                fields.postcode = fields.postcode && this.countryDetails.usesPostcode;
                fields.companyName = fields.companyName && this.countryDetails.usesCompanyName;
                fields.packageLocation = fields.packageLocation && this.hasPackageLocation === 'yes';
                fields.line3 = fields.line3 && !this.countryDetails.usesCompanyName;
                fields.regionId = fields.regionId && this.countryDetails.usesRegions;

                return fields;
            },
            getShownAddressFieldValues: function () {
                if (!this.addressTypeId)
                    return {};

                const addressValues = { ...this.value };
                const shownFields = this.getShownAddressFields();

                for (const [key, value] of Object.entries(shownFields)) {
                    if (Object.prototype.hasOwnProperty.call(addressValues, key)) {
                        addressValues[key] = value
                            ? this.getAddressFieldValue(addressValues, key)
                            : '';
                    }
                }

                return addressValues;
            },
            getAddressFieldValue: function (addressValues, key) {
                let value = addressValues[key];

                if (typeof value === 'string')
                    value = value.trim();

                return value;
            }
        },
        watch: {
            postcodeValue: _.debounce(function () {
                this.updateCities();
            }, 500),

            addressId: function () {
                if (this.value.line1) {
                    if (this.value.packageLocation && this.value.packageLocation.length > 0) {
                        this.hasPackageLocation = 'yes';
                    } else {
                        this.hasPackageLocation = 'no';
                    }
                }
            }
        },
        created() {
            this.$successLog({ description: 'Component - JourneyAddress', outcome: 'Created' });

            // Load the initial city list for Ireland as Ireland doesn't update on postcode change
            if (this.value.countryIso === 'IE')
                this.loadCities();

            // If an address has not been entered then no value, otherwise we use whether or not a value has been entered for the package location
            if (this.value.line1) {
                if (this.value.packageLocation && this.value.packageLocation.length > 0) {
                    this.hasPackageLocation = 'yes';
                } else {
                    this.hasPackageLocation = 'no';
                }
            }
        },
    }
</script>