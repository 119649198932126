 <template>
     <label class="form-check form-check-inline radiobutton" :for="id">
         <slot></slot>
         <input class="form-check-input" v-bind:class="cssclass" type="radio" :name="name" :id="id" v-model="value" :value="formValue" v-on:input="$emit('input', $event.target.value)">
         <span class="checkmark"></span>
     </label>
</template>
<script> 
    export default {
        name: 'radio-button',
        props: {
            value: [String, Number, Boolean],
            id: String,
            name: String,
            formValue: [String, Number, Boolean],
            cssclass: String
        },
    }
</script>