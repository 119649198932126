<template>
    <div class="card-body" v-if="items && items.length" id="summaryItems">
        <div class="font-weight-bold">Items</div>
        <basket-summary-item v-for="(item, index) in items"
                             :item="item"
                             :index="index"
                             :key="item.number"></basket-summary-item>
    </div>
</template>
<script>
    import BasketSummaryItem from './BasketSummaryItem.vue'

    export default {
        name: 'basket-summary-items',
        components: {
            BasketSummaryItem
        },
        computed: {
            items: function () {
                return this.$store.state.summary.data.items;
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Summary Items', outcome: 'Created' });
        }
    }
</script>

