<template>
    <div class="card card-gray mb-3">
        <div class="card-header text-center">
            <div class="h2 mb-0">Flexi</div>
            <div class="small">Own the ability to modify or cancel your order</div>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label for="essentials-cancellationcover">
                    Cancellation and Change Cover {{priceFormatted}}
                </label>
                <i class="fas fa-info-circle nav-hover text-blue float-right"
                   v-on:click.stop.prevent="showModal('cancellationmodal')"></i>
                <validation-provider :rules="{ required: true }" v-slot="{ errors }" vid="essentials-cancellationcover" name="Cancellation cover">
                    <select-2 id="essentials-cancellationcover" class="form-control" style="width:100%;" placeholder="Please Select" data-minimum-results-for-search="Infinity"
                              :options="options"
                              :default-value-on-options-update="false"
                              v-model="cancellationCover"
                              :key="cancellationCover">
                    </select-2>
                    <div class="text-danger">{{ errors[0] }}</div>
                </validation-provider>
            </div>
        </div>

        <generic-modal id="cancellationmodal" :title="smbData.CMS.Essentials.Cancellation.Header" :content="smbData.CMS.Essentials.Cancellation.Content"></generic-modal>
    </div>
</template>
<script>
    const $ = window.$;
    import { ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', { ...required, message: 'The {_field_} is required' });

    export default {
        components: {
            ValidationProvider,
        },
        name: "cancellation-cover",
        props: {
            priceFormatted: String,
        },
        computed: {
            cancellationCover: {
                get: function () { return this.$store.state.essentials.cancellationCover; },
                set: function (value) { this.$store.commit('setCancellationCover', value); }
            },
            options: function () {
                return [
                    { id: 'true', text: window.smbI18n.checkout.Yes },
                    { id: 'false', text: window.smbI18n.checkout.No }];
            },
        },
        methods: {
            showModal: function (modal) {
                $('#' + modal).modal('show');
            },
        },
        watch: {
        //    cancellationCover: function (newValue) {
        //        if (newValue === 'true')
        //            this.$store.dispatch('addExtrasItem', {
        //                description: 'Cancellation and Change Cover',
        //                extraPrice: this.cancellationCost,
        //                extraPriceString: this.cancellationCostString,
        //                oneTimeCharge: true,
        //                extraType: 'CancellationCover'
        //            })
        //        else if (newValue === 'false')
        //            this.$store.dispatch('removeExtrasItem', { extraType: 'CancellationCover' })
        //    },
        },
        created() {
            this.$successLog({ description: 'Component - Basket Essentials Cancellation Cover', outcome: 'Created'})
        },
    }
</script>