<template>
    <div class="col-lg-5 col-xl-4 col-md-6 mb-4 px-3 px-md-2 d-flex card-quote-result">
        <div class="card card-quote flex-fill">
            <div class="quote-discount-banner" v-if="showDiscountBanner && !hasPreviousPrices">
                {{ isStudentPrice ? "#SMBStudents Prices" : `${voucherName} Prices` }}
            </div>
            <div class="quote-discount-banner sale-price-banner" v-if="hasPreviousPrices">
                Summer Sale
            </div>
            <div class="card-header h2" v-bind:class="{ 'mt-3': showDiscountBanner }">{{ name }}</div>
            <p class="card-subtitle supplementary-text text-secondary">{{ minTransitTime }}-{{ maxTransitTime }} Working Days</p>
            <div class="card-body d-flex flex-column">
                <div class="supplementary-text text-right">Price per item</div>
                <dl class="row mb-2 align-items-center" v-for="tier in tiers" :key="tier">
                    <dt class="col-6 mt-0">Up to {{ tier.maxWeight }}{{weightUnit}}</dt>
                    <dd class="col-6 mt-0 text-nowrap" v-bind:class="{ 'sale-price': tier.previousPrice.length }">{{ tier.price }}</dd>
                    <div v-if="tier.previousPrice.length" class="supplementary-text text-right col-12">was <span style="text-decoration:line-through">{{ tier.previousPrice }}</span></div>
                </dl>
                <!--<dl class="row mb-0 align-items-center" v-if="biggestTier">
            <dt class="col-6 mt-0">Over {{ biggestTier.maxWeight }}{{weightUnit}}</dt>
            <dd class="col-6 mt-0 text-nowrap">{{ biggestTier.price }}</dd>
        </dl>-->
                <!--<div class="supplementary-text text-right flex-grow-1">+{{ pricePerKgOverWeight }} per additional {{ weightUnit }}</div>-->
                <div v-if="hasRasCharge" class="align-items-center mt-4">
                    <dl>
                        <dt class="text-center font-weight-bold">Remote Area Fee</dt>
                        <dt class="text-center">From {{ rasCharge }}</dt>
                        <dt class="supplementary-text text-secondary text-center">
                            Exact charge based on what you are sending will be displayed during booking.
                            <a :href="`/${cultureCode}/remote-areas`" target="_blank"><i class="primary fa fa-info-circle fa-fw"></i></a>
                        </dt>
                    </dl>
                </div>
                <div class="mt-4" v-if="hasVoucher && !isReferral">
                    <dl class="row mb-0 float-left">
                        <dt class="col-12 font-weight-bold">{{ voucherDescription }}</dt>
                    </dl>
                    <dl class="row mb-0 float-right">
                        <dt class="col-12 font-weight-bold text-danger">- {{ voucherDiscountAmount }}</dt>
                    </dl>
                </div>
                <div v-if="hasVoucher && isReferral">
                    <dl class="row mb-0 float-left">
                        <dt class="col-12 font-weight-bold">{{ referralDescription }}</dt>
                    </dl>
                    <dl v-if="isReferral" class="row mb-0 float-right">
                        <dt class="col-12 font-weight-bold text-danger">- {{ voucherDiscountAmount }}</dt>
                    </dl>
                </div>
                <div v-if="hasPreviousPrices">
                    <dl class="row mb-0 float-left text-center small">
                        <dt class="col-12 font-weight-bold">Previous prices charged <span v-if="previousPriceFrom.length">from {{ previousPriceFrom }}</span> <span v-if="previousPriceTo.length">to {{ previousPriceTo }}</span></dt>
                    </dl>
                </div>
            </div>
            <div class="form-wrapper d-flex align-items-end">
                <div class="form-inner-wrapper col-12 pl-4 pr-4">
                    <form @submit.stop.prevent="submit">
                        <div class="form-group">
                            <button type="submit" :id="submitId" @click.prevent="loadOrder" class="btn w-100 my-4 btn-success">Book {{ name }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = window.$;

    export default {
        name: 'quoteresultcard',
        props: {
            index: Number,
            routeServiceId: Number,
            routeServiceReturnId: Number,
            hideRouteStats: Boolean,
            name: String,
            pricePerKgOverWeight: Number,
            minTransitTime: Number,
            maxTransitTime: Number,
            tiers: Array,
            isReturn: Boolean,
            isStudentPrice: Boolean,
            hasVoucher: Boolean,
            voucherDescription: String,
            voucherDiscountAmount: String,
            voucherName: String,
            isReferral: Boolean,
            referralDescription: String,
            cultureCode: String,
            originCountryIso: String,
            originPostcode: String,
            originRegionId: Number,
            destinationCountryIso: String,
            destinationPostcode: String,
            destinationRegionId: Number,
            weightUnit: String,
            hasRasCharge: Boolean,
            rasCharge: String,
            previousPriceFrom: String,
            previousPriceTo: String,
        },
        computed: {
            biggestTier: function () {
                if (this.tiers && this.tiers.length > 0) {
                    var result = this.tiers[0];

                    for (var index = 0; index < this.tiers.length; index++) {
                        if (this.tiers[index].maxWeight > result.maxWeight) {
                            result = this.tiers[index];
                        }
                    }

                    return result;
                }

                return null;
            },
            submitId: function() {
                return this.name + "SubmitButton";
            },
            showDiscountBanner: function () {
                if (this.isReferral && !this.isStudentPrice)
                    return false;

                if (this.isStudentPrice || this.hasVoucher)
                    return true;

                return false;
            },
            hasPreviousPrices: function () {
                if (this.tiers && this.tiers.length > 0) {

                    for (var index = 0; index < this.tiers.length; index++) {
                        if (this.tiers[index].previousPrice.length) {
                            return true;
                        }
                    }
                }

                return false;
            }
        },

        methods: {
            loadOrder: function() {
                var self = this;

                var quoteDetail = {
                    ServiceId: this.routeServiceId,
                    ReturnServiceId: this.routeServiceReturnId,
                    IsReturn: this.isReturn,
                    OriginIso: this.originCountryIso,
                    OriginRegionId: this.originRegionId,
                    DestinationIso: this.destinationCountryIso,
                    DestinationRegionId: this.destinationRegionId,
                    OriginPostcode: this.originPostcode,
                    DestinationPostcode: this.destinationPostcode,
                };
                //console.log(quoteDetail);
                var jqxhr = $.post('/en-gb/api/basketapi/createbasket', quoteDetail, function (result) {
                    if (result.success) {
                        window.smbData.basketId = result.basketId;
                        window.location.href = '/' + self.cultureCode + '/order';
                    }
                });

                jqxhr.fail(function () {
                    //self.isError = true;
                });
                jqxhr.always(function () {
                });
            }
        }
    }
</script>

