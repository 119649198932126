<template>
    <div>
        <div v-html="introParagraph" />
        <div v-html="collapsableContent" v-show="show" />
        <div v-bind:style="buttonContainerStyles">
            <button class="btn btn-info mt-2 mb-4" v-on:click="toggleVisible">{{closeButtonText}} Instructions</button>
        </div>
    </div>
</template>

<script>
    const $ = window.$;
    export default {
        name: 'packinglistsupplementarytext',
        props: {
            content: String
        },
        data: function () {
            return {
                show: true,
                buttonContainerStyles: {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }   
            }
        },
        methods: {
            toggleVisible: function () {
                this.show = !this.show;
                if (!this.show) {
                    $(window).scrollTop(0);
                }
                
            },
        },
        computed: {
            introParagraph: function () {
                var i = this.content.indexOf('</p>');
                return this.content.slice(0,i)
            },
            collapsableContent: function () {
                var i = this.content.indexOf('</p>');
                
                return this.content.slice(i+4)
            },
            closeButtonText: function() {
                return this.show ? 'Close' : 'Show'
            }
        }

    }
</script>

