<template>
    <div class="mb-3" id="items-additem-container">
        <h1>{{ smbI18n.checkout.AddItems }}</h1>

        <validation-provider :rules="{ required: true, min_value: 1 }" v-slot="{ errors }">
            <input type="hidden" v-model="itemCount" />
            <error-card v-if="errors && errors.length" :messages="['You must add at least 1 item']"></error-card>
            <input type="hidden" :set="setHasMissingItemAlert(errors && errors.length > 0)" />
        </validation-provider>

        <validation-observer ref="form" :disabled="itemCount > 0">
            <div v-show="!hasItems" class="form-group row">
                <div class="col-12 col-sm-auto mb-2">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">{{ smbI18n.checkout.MeasurementUnits }}</label>
                    </div>
                </div>
                <div class="col-12 col-sm-auto ">
                    <label class="form-check form-check-inline radiobutton" for="items-additems-metric">
                        {{ smbI18n.checkout.Metric }} {{ smbI18n.checkout.MetricDescription }}
                        <input class="form-check-input" type="radio" name="orderadditems" id="items-additems-metric" v-model="isMetric" v-bind:value="true">
                        <span class="checkmark"></span>
                    </label>
                    <label class="form-check form-check-inline radiobutton" for="items-additems-imperial">
                        {{ smbI18n.checkout.Imperial }} {{ smbI18n.checkout.ImperialDescription }}
                        <input class="form-check-input" type="radio" name="orderadditems" id="items-additems-imperial" v-model="isMetric" v-bind:value="false">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-6">
                    <validation-provider :rules="{ required: true, between: [1, maxLength] }" v-slot="{ errors, classes }" :name="smbI18n.checkout.Width" :mode="interactionMode">
                        <label for="items-additems-width">{{ smbI18n.checkout.Width }}</label><img src="https://media.sendmybag.com/assets/6d69196b-3eac-4e83-a3a3-dade71e024a6/Dim-Wid.svg" alt="Width" class="order-bags-dimension-icon" />
                        <div class="input-group">
                            <input type="number" inputmode="decimal" class="form-control" v-bind:class="classes" id="items-additems-width" name="width" ref="width" v-model.number="width" min="0" step="any" placeholder="0" />
                            <div class="input-group-append" v-bind:class="classes">
                                <span class="input-group-text"><span class="basket-additem-input-unit">{{ lengthUnitText }}</span></span>
                            </div>
                        </div>
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
                <div class="col-6">
                    <validation-provider :rules="{ required: true, between: [1, maxLength] }" v-slot="{ errors, classes }" :name="smbI18n.checkout.Height" :mode="interactionMode">
                        <label for="items-additems-height">{{ smbI18n.checkout.Height }}</label><img src="https://media.sendmybag.com/assets/a1afbea8-cd31-421f-9cce-75d2a098fe82/Dim-Hei.svg" alt="Height" class="order-bags-dimension-icon" />
                        <div class="input-group">
                            <input type="number" inputmode="decimal" class="form-control" v-bind:class="classes" id="items-additems-height" name="height" v-model.number="height" min="0" step="any" placeholder="0" />
                            <div class="input-group-append" v-bind:class="classes">
                                <span class="input-group-text"><span class="basket-additem-input-unit">{{ lengthUnitText }}</span></span>
                            </div>
                        </div>
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-6">
                    <validation-provider :rules="{ required: true, between: [1, maxLength] }" v-slot="{ errors, classes }" :name="smbI18n.checkout.Length" :mode="interactionMode">
                        <label for="items-additems-length">{{ smbI18n.checkout.Length }}</label><img src="https://media.sendmybag.com/assets/b429fef4-6ff7-4b57-9505-81cfca443947/Dim-Len.svg" alt="Length" class="order-bags-dimension-icon" />
                        <div class="input-group">
                            <input type="number" inputmode="decimal" class="form-control" v-bind:class="classes" id="items-additems-length" name="length" v-model.number="length" min="0" step="any" placeholder="0" />
                            <div class="input-group-append" v-bind:class="classes">
                                <span class="input-group-text"><span class="basket-additem-input-unit">{{ lengthUnitText }}</span></span>
                            </div>
                        </div>
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
                <div class="col-6">
                    <validation-provider :rules="{ required: true, between: [1, maxWeight] }" v-slot="{ errors, classes }" :name="smbI18n.checkout.Weight" :mode="interactionMode">
                        <label for="items-additems-weight">{{ smbI18n.checkout.Weight }}</label>
                        <div class="input-group">
                            <input type="number" inputmode="decimal" class="form-control" v-bind:class="classes" id="items-additems-weight" name="weight" v-model.number="weight" min="0" step="any" placeholder="0" />
                            <div class="input-group-append" v-bind:class="classes">
                                <span class="input-group-text"><span class="basket-additem-input-unit">{{ weightUnitText }}</span></span>
                            </div>
                        </div>
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
            </div>

            <validation-provider :rules="{ max_value: maxVolumetricWeight }" mode="aggressive" v-slot="{ errors }">
                <input type="hidden" v-model="volumetricWeight" />
                <error-card v-if="errors && errors.length" :messages="[smbI18n.checkout.Error_DimensionsTooBig]"></error-card>
            </validation-provider>
            <validation-provider :rules="{ max_value: maxLengthAndGirth }" mode="aggressive" v-slot="{ errors }">
                <input type="hidden" v-model="lengthAndGirth" />
                <error-card v-if="errors && errors.length" :messages="[smbI18n.checkout.Error_DimensionsTooBig]"></error-card>
            </validation-provider>

            <div class="form-group row">
                <div class="col-12">
                    <validation-provider rules="required" v-slot="{ errors, classes }" :name="smbI18n.checkout.ItemType" :mode="interactionMode">
                        <div v-bind:class="classes">
                            <label for="items-additems-itemtype">{{ smbI18n.checkout.ItemType }}</label>
                            <select-2 id="items-additems-itemtype" name="item type" :options="itemTypes" v-model="itemType" class="form-control" style="width:100%;" :placeholder="smbI18n.checkout.PleaseSelect" data-minimum-results-for-search="Infinity"></select-2>
                            <div class="text-danger">{{ errors[0] }}</div>
                        </div>
                    </validation-provider>
                </div>
            </div>
            <warning-card :messages="[itemTypeDescription]"></warning-card>

            <div class="form-group row">
                <div class="col-12">
                    <text-input v-model.trim="description" :rules="{ max: 32 }" type="text"
                                :name="smbI18n.checkout.ItemDescription" id="items-additems-itemdescription"
                                :label="smbI18n.checkout.ItemDescription" :placeholder="smbI18n.checkout.ItemDescriptionPlaceholder"></text-input>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12 text-center text-md-right my-1">
                    <button type="button" id="OrderAddItem"
                            v-bind:class="addItemBtnClass"
                            v-on:click.stop.prevent="addItem"
                            v-bind:disabled="addingItem">
                        Add Item
                    </button>
                </div>
                <div id="OrderAddItemErrors" class="col-12 text-center text-md-right text-danger"></div>
            </div>
            <div v-if="formerrors && formerrors.length">
                <error-card v-for="formerror in formerrors" :key="formerror" :messages="formerror"></error-card>
            </div>
        </validation-observer>

        <generic-modal id="oversizemodal" :title="smbI18n.checkout.ImportantInformation" :content="oversizeWarningMessage" :hideCloseIcon="true" :closeButtonText="smbI18n.checkout.IAgree"></generic-modal>
    </div>
</template>

<script>
    const $ = window.$;
    import Select2 from '../../../components/select-2/Select2.vue';
    import WarningCard from '../../../components/shared/WarningCard.vue';
    import ErrorCard from '../../../components/shared/ErrorCard.vue';
    import { mapActions, mapGetters } from 'vuex';
    import { ValidationProvider, ValidationObserver, extend, setInteractionMode  } from 'vee-validate';
    import { required, between, max, min_value, max_value } from 'vee-validate/dist/rules';
    import { eagerButNotOnBlur } from '../../../validation/customValidationModes.js';

    setInteractionMode('eager');
    extend('required', { ...required, message: '{_field_} is required' });
    extend('between', { ...between, message: '{_field_} must be between {min} and {max}' });
    extend('max', { ...max, message: '{_field_} must be less than {length} characters long' });
    extend('min_value', { ...min_value, message: '{_field_} must be atleast {min}' });
    extend('max_value', { ...max_value, message: '{_field_} must be no more than {max}' });

    export default {
        name: 'basket-add-items',
        components: {
            Select2,
            WarningCard,
            ErrorCard,
            ValidationProvider,
            ValidationObserver
        },
        data: function () {
            return {
                width: null,
                height: null,
                length: null,
                weight: null,
                itemType: null,
                description: null,
                isFormValid: true,
                hasMissingItemAlert: false,
                addingItem: false,
                formerrors: []
            };
        },

        computed: {
            ...mapGetters([
                'lengthUnitText',
                'weightUnitText',
                'hasItems'
            ]),
            ...mapActions(['setStep1Request']),

            //----- Basic getters from store values
            itemTypes: function () { return this.$store.state.items.itemTypes; },
            maxLength: function () { return this.isMetric ? this.$store.state.items.maxLength : parseFloat((this.$store.state.items.maxLength / 2.54).toFixed(1)); },
            maxWeight: function () { return this.isMetric ? this.$store.state.items.maxWeight : parseFloat((this.$store.state.items.maxWeight * 2.20462).toFixed(1)); },
            maxVolumetricWeight: function () { return this.isMetric ? this.$store.state.items.maxVolumetricWeight : parseFloat((this.$store.state.items.maxVolumetricWeight * 2.20462).toFixed(1)); },
            maxLengthAndGirth: function () { return this.isMetric ? this.$store.state.items.maxLengthAndGirth : parseFloat((this.$store.state.items.maxLengthAndGirth / 2.54).toFixed(1)); },
            oversizeWarningMessage: function () { return this.$store.state.items.oversizeWarningMessage; },

            //----- Two way binding of store values
            isMetric: {
                get: function () { return this.$store.state.items.isMetric },
                set: function (value) { this.$store.commit('setIsMetric', value) }
            },

            //----- Other calculated properties
            itemTypeDescription: function () {
                var self = this;

                // If we don't have a selected item then there is no description
                if (!self.itemType) {
                    return null;
                }

                var result = this.$store.state.items.itemTypes.filter(function (itemType) {
                    return itemType.id.toString() === self.itemType;
                });

                if (!result) {
                    return null;
                }

                return result.shift().description;
            },

            itemCount: function () {
                if (this.$store.state.items.items)
                    return this.$store.state.items.items.length;

                return 0;
            },

            volumetricWeight: function () {
                if (this.isMetric)
                    return (this.width * this.height * this.length) / 5000;
                else
                    return (this.width * this.height * this.length) / 139;
            },

            lengthAndGirth: function () {
                const maxLength = Math.max(this.width, this.height, this.length);
                const lengthAndGirth = maxLength + (2 * (this.width + this.height + this.length - maxLength)); // Calculate the length + girth
                return lengthAndGirth;
            },

            addItemBtnClass: function () {
                let btnClass = 'btn btn-outline-success';

                if (!this.isFormValid || this.hasMissingItemAlert)
                    btnClass = 'btn btn-danger alert-danger';

                return btnClass;
            },

            interactionMode: function () {
                return this.itemCount === 0 ? 'eager' : eagerButNotOnBlur;
            }
        },

        methods: {
            addItem: function () {

                var self = this;
                self.$refs.form.validate().then((isValid) => {
                    self.formerrors = [];
                    self.isFormValid = isValid;

                    if (isValid) {
                        // Stop the button from allowing multiple items to be added by quickly clicking
                        self.addingItem = true;

                        self.$store.dispatch('startedLoading');
                        self.$store.dispatch('addItem', {
                            isMetric: self.isMetric,
                            width: self.width,
                            height: self.height,
                            length: self.length,
                            weight: self.weight,
                            itemType: self.itemType,
                            description: self.description
                        }).then((result) => {
                            if (result.success) {
                                if (result.updatedOversizeValues)
                                    self.$store.dispatch('updateOversizeValues', result.updatedOversizeValues);

                                // Check if the item triggers the oversize warning, and if so we show the message
                                self.checkOversizeWarning();

                                // Now clear the form and validator
                                self.width = null;
                                self.height = null;
                                self.length = null;
                                self.weight = null;
                                self.itemType = null;
                                self.description = null;
                            }
                            else if (result.errors && result.errors.length) {
                                self.formerrors = result.errors.map(function (item) {
                                    return item.value;
                                });
                            } else {
                                self.formerrors = [['Sorry there was an error adding your item, please try again. If this problem continues then contact us']];
                            }
                        }).catch((error) => {
                            self.formerrors = [['Sorry there was an error adding your item, please try again. If this problem continues then contact us']];
                        }
                        ).always(() => {
                            self.$store.dispatch('updateSummary');
                            self.$store.dispatch('finishedLoading');
                            // Allow adding an item again
                            self.addingItem = false;
                        });

                        self.$refs.form.reset();
                        //document.getElementById('items-additems-itemdescription').scrollIntoView({
                        //    behavior: 'smooth'
                        //});

                    } else {
                        // scroll to first error
                        const errors = Object.entries(this.$refs.form.refs)
                            .map(([key, value]) => ({
                                key,
                                value
                            }))
                            .filter(error => {
                                if (!error || !error.value || !error.value.failedRules) return false;
                                return Object.keys(error.value.failedRules).length > 0;
                            });

                        if (errors && errors.length > 0) {
                            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView();
                        }
                    }
                });

            },
            checkOversizeWarning: function () {
                if ((this.length > this.$store.state.items.oversizeWarningMin - 1 && this.length < this.$store.state.items.oversizeWarningMax)
                    || (this.width > this.$store.state.items.oversizeWarningMin - 1 && this.width < this.$store.state.items.oversizeWarningMax)
                    || (this.height > this.$store.state.items.oversizeWarningMin - 1 && this.height < this.$store.state.items.oversizeWarningMax)) {
                    this.showModalAgree('oversizemodal');
                }
            },
            showModal: function (model) {
                $('#' + model).modal('show');
            },
            showModalAgree: function (model) {
                $('#' + model).modal({ backdrop: 'static', keyboard: false });
            },
            setHasMissingItemAlert: function (hasMissingItemAlert) {
                this.hasMissingItemAlert = hasMissingItemAlert;
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Add Items', outcome: 'Created' })
        }
    }
</script>
