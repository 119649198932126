<template>
    <div
        class="card card-blue order-summary"
        v-bind:class="{ loading: isLoading && !isInitialSummaryLoad }"
    >
        <shimmer-effect-items-wrapper v-if="isInitialSummaryLoad">
            <div class="card-header">
                <div class="shimmer-text-line-w40"></div>
            </div>
            <div class="card-body">
                <div class="shimmer-text-line-w25"></div>
            </div>
            <div class="d-flex">
                <div class="shimmer-summary-button"></div>
            </div>
        </shimmer-effect-items-wrapper>
        <div v-else>
            <div>
                <div v-on:click="showMoreInfoMobile">
                    <div class="order-summary-showmore my-2">
                        Tap to
                        {{ this.isShowMore ? 'Hide Details' : 'Show More' }}
                    </div>
                    <div
                        class="order-summary-info"
                        v-bind:class="{
                            'order-summary-mobileshowall': this.showMore,
                        }"
                    >
                        <div class="card-header"><h2>Your Order</h2></div>

                        <basket-summary-locations></basket-summary-locations>
                        <basket-summary-items></basket-summary-items>
                        <basket-summary-products></basket-summary-products>
                        <basket-summary-discount></basket-summary-discount>
                    </div>
                    <basket-summary-total></basket-summary-total>
                </div>
                <div class="d-flex" v-if="showContinueButton">
                    <button
                        type="submit"
                        class="btn btn-success btn-order-summary"
                        id="continueOrderButton"
                        v-bind:disabled="isLoading">
                        {{ buttonText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const $ = window.$
import ShimmerEffectItemsWrapper from '../../components/shared/ShimmerEffectItemsWrapper.vue'
import BasketSummaryDiscount from './summary/BasketSummaryDiscount.vue'
import BasketSummaryItems from './summary/BasketSummaryItems.vue'
import BasketSummaryLocations from './summary/BasketSummaryLocations.vue'
import BasketSummaryProducts from './summary/BasketSummaryProducts.vue'
import BasketSummaryTotal from './summary/BasketSummaryTotal.vue'

export default {
    name: 'basket-summary',
    components: {
        BasketSummaryLocations,
        BasketSummaryItems,
        BasketSummaryProducts,
        BasketSummaryDiscount,
        BasketSummaryTotal,
        ShimmerEffectItemsWrapper,
    },
    data: function () {
        return {
            showMore: false,
        }
    },
    methods: {
        showMoreInfoMobile: function () {
            this.showMore = !this.showMore
        },
    },
    computed: {
        //...mapGetters([
        //    'step',
        //]),
        step: function () {
            return this.$store.state.step
        },
        isUserSignedIn: {
            get() {
                return this.$store.state.isUserSignedIn
            },
        },
        isLoading: function () {
            return this.$store.state.isLoading
        },
        isShowMore: {
            get() {
                return this.showMore
            },
        },

        //isInitialSummaryLoad: function () {
        //    return this.$store.state.isSummaryLoading;
        //},
        buttonText: {
            get() {
                return this.step === smbData.STEPS_CONFIRM
                    ? 'Pay Now'
                    : 'Continue'
            },
        },
        showContinueButton: function () {
            if (this.step === smbData.STEPS_CONFIRM) return false

            //if (this.step === smbData.STEPS_CONFIRM && !this.isUserSignedIn)
            //    return false;

            return true
        },
    },
    created() {
        this.$successLog({
            description: 'Component - Basket Summary',
            outcome: 'Created',
        })
    },
}
</script>
