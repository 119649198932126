export default {
    state: {
        // Payload values
        origin: {},
        destination: {},
        isReturn: false,

        // Other data
        addressFieldFlags: [],
        addressTypes: [],
        phoneNumberCountries: [],
        originCountry: {},
        destinationCountry: {},
        hasRasCharge: false,

        originSavedAddresses: [],
        destinationSavedAddresses: [],

        getOriginVisibleFieldValues: () => { },
        getDestinationVisibleFieldValues: () => { },

        addressesAvailableForCollection: true,
        addressesAvailableForCollectionErrorMessages: []
    },

    mutations: {
        setInitialStateAddresses: function (state, payload) {
            state.origin = payload.origin;
            state.destination = payload.destination;
            state.isReturn = payload.isReturn;

            state.addressFieldFlags = payload.addressFieldFlags;
            state.addressTypes = payload.addressTypes;
            state.phoneNumberCountries = payload.phoneNumberCountries;
            state.originCountry = payload.originCountry;
            state.destinationCountry = payload.destinationCountry;
            state.hasRasCharge = payload.hasRasCharge;

            state.originSavedAddresses = payload.originSavedAddresses;
            state.destinationSavedAddresses = payload.destinationSavedAddresses;
        },

        setOrigin: function (state, payload) {
            state.origin = payload;
        },
        setDestination: function (state, payload) {
            state.destination = payload;
        },
        setIsReturn: function (state, payload) {
            state.isReturn = payload;
        },
        setHasRasCharge: function (state, payload) {
            state.hasRasCharge = payload;
        },

        setGetOriginVisibleFieldValues: function (state, payload) {
            state.getOriginVisibleFieldValues = payload;
        },
        setGetDestinationVisibleFieldValues: function (state, payload) {
            state.getDestinationVisibleFieldValues = payload;
        },
        setAddressesAvailableForCollection: function (state, payload) {
            state.addressesAvailableForCollection = payload.areAvailableForCollection;
            state.addressesAvailableForCollectionErrorMessages = payload.errorMessages;
        }
    },
    actions: {
        async saveOrigin({ state, commit, dispatch }) {
            var params = {
                model: state.getOriginVisibleFieldValues()
            };

            var jqxhr = $.post('/en-gb/api/basketaddressapi/saveorigin/', params, function (result) {
                let errors = result.errors ?? {};
                commit('setValidationErrors', errors);

                if (result && result.success) {
                    commit('setOutboundServiceId', result.data.outboundRouteServiceId);
                    commit('setInboundServiceId', result.data.inboundRouteServiceId);
                    commit('setHasRasCharge', result.data.hasRasCharge);

                    commit('setStepForward', window.smbData.STEPS_DESTINATIONADDRESS);
                    commit('setOrigin', result.data.address);
                }
            });

            jqxhr.fail(function () {
                dispatch('showErrorToast');
            });

            jqxhr.always(function () {
                dispatch('setIsLoading', false);
            });
        },
        async saveDestination({ state, commit, dispatch }) {
            var params = {
                model: state.getDestinationVisibleFieldValues()
            };

            var jqxhr = $.post('/en-gb/api/basketaddressapi/savedestination/', params, function (result) {
                let errors = result.errors ?? {};
                commit('setValidationErrors', errors);

                if (result && result.success) {
                    commit('setOutboundServiceId', result.data.outboundRouteServiceId);
                    commit('setInboundServiceId', result.data.inboundRouteServiceId);
                    commit('setHasRasCharge', result.data.hasRasCharge);

                    commit('setAddressesAvailableForCollection', result.data.addressesAvailableForCollection);

                    commit('setStepForward', window.smbData.STEPS_ADDRESSCONFIRM);
                    commit('setDestination', result.data.address);
                }
            });

            jqxhr.fail(function () {
                dispatch('showErrorToast');
            });

            jqxhr.always(function () {
                dispatch('setIsLoading', false);
            });
        },
        async saveIsReturn({ state, rootState, commit, dispatch }) {
            var params = {
                model: state.isReturn
            };

            var jqxhr = $.post('/en-gb/api/basketaddressapi/saveisreturn/', params, function (result) {
                let errors = result.errors ?? {};
                commit('setValidationErrors', errors);

                if (result && result.success) {
                    // update the return service id because it can be changed in here
                    if (!rootState.dates.inboundServiceId && result.inboundServiceId) {
                        commit('setInboundServiceId', result.inboundServiceId);
                    }

                    commit('setAddressesAvailableForCollection', result.addressesAvailableForCollection);

                    if (state.addressesAvailableForCollection === true)
                        commit('setStepForward', window.smbData.STEPS_OUTBOUNDDATE);
                }
            });

            jqxhr.fail(function () {
                dispatch('showErrorToast');
            });

            jqxhr.always(function () {
                dispatch('setIsLoading', false);
            });
        },

    }
};
