<template>
    <div>
        <!--<h3>Apple Pay Log</h3>
        <ul id="applepayoutput"></ul>-->

        <h2 v-if="showPaymentSelection && totalPaymentMethodsAvailable > 1">Choose a payment method</h2>
        <div v-if="showTermsRequiredMessage" class="alert alert-danger" role="alert">
            You must agree to our terms and conditions before selecting a payment method
        </div>
        <div v-show="!showTermsRequiredMessage">
            <div class="payment-method-container payment-method-applepay" v-show="isApplePayAvailable">
                <div class="d-flex align-items-center" v-on:click="selectPaymentMethod('apple')" v-if="showPaymentSelection">
                    <div class="flex-grow-1 pt-2">
                        <label for="payment-method-applepay" class="form-check form-check-inline radiobutton">
                            Pay with Apple Pay
                            <input type="radio"
                                    name="paymentmethods"
                                    id="payment-method-applepay"
                                    class="form-check-input"
                                    value="apple"
                                    v-model="selectedPaymentMethod"><span class="checkmark"></span>
                        </label>
                    </div>
                    <img src="https://media.sendmybag.com/assets/fb222c6a-60f0-4697-86a4-8dd3854cbe91/Apple_Pay_Mark_RGB_041619.svg" class="ml-1 payment-method-logo payment-method-logo-apple">
                </div>
                <div class="payment-method-content text-center" v-show="!showPaymentSelection || selectedPaymentMethod === 'apple'">
                    <apple-pay-button id="applePayButton" buttonstyle="black" type="pay" locale="en"></apple-pay-button>
                </div>
            </div>

            <div class="d-flex align-items-center mt-2" v-if="totalPaymentMethodsAvailable > 1">
                <div class="flex-grow-1 payment-separator"></div><div class="px-2 payment-separator-text">or</div><div class="flex-grow-1 payment-separator"></div>
            </div>

            <div class="payment-method-container payment-method-creditcard">
                <div class="d-flex align-items-center" v-on:click="selectPaymentMethod('card')">
                    <div class="flex-grow-1 pt-2">
                        <label for="payment-method-creditcard" class="form-check form-check-inline radiobutton">
                            Pay by Card
                            <input type="radio"
                                   name="paymentmethods"
                                   id="payment-method-creditcard"
                                   class="form-check-input"
                                   value="card"
                                   v-model="selectedPaymentMethod"
                                   v-bind:disabled="!formValid"><span class="checkmark"></span>
                        </label>
                    </div>
                    <img src="https://media.sendmybag.com/assets/0f0e6d6c-bae0-4b9e-ba84-103c6590b3b5/mc_symbol.svg" class="ml-1 payment-method-logo payment-method-logo-mc">
                    <img src="https://media.sendmybag.com/assets/2e95c02c-422d-4daf-b07e-e90c21d3f779/Visa_Brandmark_Blue_RGB_2021.svg" class="ml-1 payment-method-logo payment-method-logo-visa">
                </div>
                <div class="payment-method-content" v-show="selectedPaymentMethod === 'card'">

                    <div class="card card-gray mb-3" v-bind:class="{ 'payment-form-content-hidden': paymentSubmitted }">
                        <div class="card-header text-center">
                            <div class="h2 mb-0">Billing Address</div>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <div v-bind:class="classes">
                                    <billing-address id="billingAddress" :customer-id="cid" :billing-address="billingAddress" v-on:valueChanged="billingAddressChanged"></billing-address>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-gray mb-3" v-bind:class="{ 'payment-form-content-hidden': paymentSubmitted }">
                        <div class="card-header text-center">
                            <div class="h2 mb-0">Payment Details</div>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <validation-provider :rules="{ required: true, max: 64, cardHolderName }" vid="payment-nameoncard" v-slot="{ classes, errors }" :name="smbI18n.payment.NameOnCard" slim>
                                    <div v-bind:class="classes">
                                        <label for="payment-nameoncard">{{ smbI18n.payment.NameOnCard }}</label>
                                        <input type="text" class="form-control disable-autofill-highlight" id="payment-nameoncard" name="nameoncard" placeholder="" maxlength="64" v-model="nameOnCard" />
                                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                                    </div>
                                </validation-provider>
                            </div>
                            <div class="form-group">
                                <validation-provider :rules="{ required: true, creditCardNumber, creditCardType }" vid="payment-cardnumber" v-slot="{ classes, errors }" :name="smbI18n.payment.CardNumber" slim>
                                    <div v-bind:class="classes">
                                        <label for="payment-cardnumber">{{ smbI18n.payment.CardNumber }}</label>
                                        <input type="tel" class="form-control disable-autofill-highlight" id="payment-cardnumber" name="cardnumber" placeholder="0000 0000 0000 0000" maxlength="23" v-model="cardNumberDisplay" />
                                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                                    </div>
                                </validation-provider>
                            </div>
                            <div class="form-group">
                                <validation-provider :rules="{ required: true, max: 3, min: 3, creditCardCvn }" vid="payment-cvv" v-slot="{ classes, errors }" :name="smbI18n.payment.CardSecurityCode" slim>
                                    <div v-bind:class="classes">
                                        <label for="payment-cvv">{{ smbI18n.payment.CardSecurityCode }}</label>
                                        <input type="tel" class="form-control payment-securitycode" id="payment-cvv" name="cvv" placeholder="000" maxlength="3" v-model="cvv" />
                                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                                    </div>
                                </validation-provider>
                            </div>
                            <div class="form-group">
                                <validation-provider :rules="{ required: true }" vid="payment-expirydate" v-slot="{ classes, errors }" :name="smbI18n.payment.ExpiryDate" slim>
                                    <div v-bind:class="classes">
                                        <label for="payment-expirydate">{{ smbI18n.payment.ExpiryDate }}</label>
                                        <input type="hidden" v-model="expiryDate" />
                                        <div class="input-group">
                                            <select class="form-control payment-expireydate" id="payment-expirymonth" name="expirymonth" v-model="expiryMonth">
                                                <option value="01">1</option>
                                                <option value="02">2</option>
                                                <option value="03">3</option>
                                                <option value="04">4</option>
                                                <option value="05">5</option>
                                                <option value="06">6</option>
                                                <option value="07">7</option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                            <select class="form-control payment-expireydate" id="payment-expiryyear" name="expiryyear" v-model="expiryYear">
                                                <option v-for="year in cardYearsAvailable" :key="year" :value="year">{{ year }}</option>
                                            </select>
                                        </div>
                                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                                    </div>
                                </validation-provider>
                            </div>

                            <div class="row" v-if="showButton">
                                <div class="col-12 text-center my-1">
                                    <button type="button" id="payment-paynow" class="btn btn-success" v-bind:disabled='paymentSubmitted'
                                            v-on:click.stop.prevent="buttonValidationHandler(processPayment)">
                                        Pay Now
                                    </button>
                                </div>
                                <div id="OrderAddItemErrors" class="col-12 text-center text-md-right text-danger"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modal" id="threeDSModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Verifying your payment</h5>
                    </div>

                    <div class="modal-body">
                        <div id="threeDSForm" class="three-ds-challenge-container"></div>
                        <div class="three-ds-loading" v-if="loadingChallenge">
                            <i class="fad fa-spin fa-spinner fa-7x"></i>
                        </div>
                        <div class="small">
                            <p><strong>What is 3D Secure?</strong></p>
                            <p>We use 3D secure to provide an additional security layer for processing credit and debit card purchases.</p>
                            <p>If you have been stuck on this page for more than 15 seconds then we may be experiencing issues contacting your bank to verify your payment. To resolve this please try the following:</p>
                            <ul>
                                <li>If you have any pop-up or ad blockers please disable these for a few minutes and try your payment again. 3D Secure opens up an additional window that can be blocked by these.</li>
                                <li>Try a different browser, we recommend using Firefox or Safari for processing your payment.</li>
                            </ul>
                            <p>You can attempt your payment again by going to your <a :href="manageOrderUrl">My Account</a> page.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>  
    //const $ = window.$;
    const {
        checkVersion,
        getBrowserData,
        initiateAuthentication,
        ChallengeWindowSize,
    } = GlobalPayments.ThreeDSecure;

    import { ValidationProvider, extend } from 'vee-validate';
    import { required, max, min } from 'vee-validate/dist/rules';
    import BillingAddress from '../address/BillingAddress.vue';
    import '../../validation/paymentValidationRules.js';

    extend('required', { ...required, message: '{_field_} is required' });
    extend('max', { ...max, message: '{_field_} must be a maximum of {length} characters long' });
    extend('min', { ...min, message: '{_field_} must be at least {length} characters long' });

    export default {
        name: 'payment',
        components: {
            ValidationProvider,
            BillingAddress,
        //    creditCardNumber,
        //    creditCardType
        },
        props: {
            totalToPay: Number,
            currencyCode: String,
            cid: Number,
            oref: Number,
            basketId: Number,
            uniqueId: String,
            cultureCode: String,
            submittingPayment: Boolean,
            showButton: Boolean,
            buttonValidationHandler: {
                type: Function,
                default: (buttonClickFunc) => { buttonClickFunc(); }
            },
            newOrder: Boolean,
            formValid: Boolean
        },
        data: function () {
            return {
                // Card Data
                nameOnCard: '',
                cardNumber: '',
                cvv: '',
                expiryMonth: '',
                expiryYear: '',

                // Order Data
                customerId: this.cid,
                orderReference: this.oref,

                paymentSubmitted: false,
                loadingChallenge: false,

                //Billing Address Data
                billingAddress: Object,
                billingAddressId: Number,

                selectedPaymentMethod: '',
                isApplePayAvailable: false,
            }
        },
        computed: {
            cardNumberDisplay: {
                get: function () {
                    let result = '';
                    for (var i = 0; i < this.cardNumber.length; i++) {
                        result += this.cardNumber.charAt(i);

                        if (i % 4 === 3) {
                            result += ' ';
                        }
                    }
                    return result.trim();
                },
                set: function (newValue) {
                    this.cardNumber = newValue.replace(/\s+/g, '');
                }
            },
            cardData: function () {
                return {
                    number: this.cardNumber,
                    expiryMonth: this.expiryMonth,
                    expiryYear: this.expiryYear,
                    securityCode: this.cvv,
                    cardHolderName: this.nameOnCard
                };
            },
            orderData: function () {
                return {
                    orderReference: this.orderReference,
                    transactionId: this.transactionId,
                    cid: this.customerId
                };
            },
            applePayOrderData: function () {
                return {
                    orderReference: this.orderReference,
                    transactionId: this.transactionId,
                    cid: this.customerId,
                    basketId: this.basketId,
                    newOrder: this.newOrder,
                    cultureCode: this.cultureCode
                };
            },
            billingAddressData: function () {
                return {
                    id: this.billingAddressId,
                    line1: this.billingAddress.line1,
                    line2: this.billingAddress.line2,
                    city: this.billingAddress.cityName,
                    countryiso: this.billingAddress.countryIso,
                    postcode: this.billingAddress.postcode
                };
            },
            transactionId: function () {
                if (this.orderReference) {
                    return this.orderReference + this.uniqueId;
                }

                return this.uniqueId;
            },
            manageOrderUrl: function () {
                if (this.orderReference) {
                    return '/account/orderdetails?id=' + this.orderReference;
                }

                return '/account/orders';
            },
            expiryDate: function () {
                if (this.expiryMonth && this.expiryYear) {
                    return this.expiryMonth + this.expiryYear;
                }

                return null;
            },
            cardType: function () {
                if (cardNumber !== undefined && cardNumber !== null) {
                    //first check for MasterCard
                    if (/^(?:5[1-6]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)/.test(cardNumber)) {
                        return 'MC';//'mastercard';
                    }
                    //then check for Visa
                    else if (/^4/.test(cardNumber)) {
                        return 'VISA';//'visa';
                    }
                    //then check for AmEx
                    else if (/^3[47]/.test(cardNumber)) {
                        return 'AMEX';//'amex';
                    }
                    //then check for Maestro
                    else if (/^(50|5[6-9]|6)/.test(cardNumber)) {
                        return 'SWITCH';//'maestro';
                    }
                    //check for other cards that we don't accept, but we want to show
                    else if (/^3(?:0[0-5]|[68][0-9])[0-9]/.test(cardNumber)) { // diners
                        return 'DINERS';
                    }
                    else if (/^6(?:011|5[0-9]{2})[0-9]/.test(cardNumber)) { // discover
                        return 'DISCOVER';
                    }
                    else if (/^(?:2131|1800|35\d{3})/.test(cardNumber)) { // jcb
                        return 'JCB';
                    }
                    else if (/^(62|88)/.test(cardNumber)) { // union pay
                        return 'UNIONPAY';
                    }
                    else if (cardNumber.length > 5) {
                        return 'UNKNOWN';
                    }
                }

                return 'NONE';
            },

            totalPaymentMethodsAvailable: function () {
                let count = 1;

                if (this.isApplePayAvailable) {
                    count++;
                }

                return count;
            },

            showPaymentSelection: function () {
                if (this.oref || this.totalPaymentMethodsAvailable === 1)
                    return false;

                return true;
            },

            showTermsRequiredMessage: function () {
                return !this.formValid && this.showPaymentSelection;
            },

            cardYearsAvailable: function () {
                let currentYear = new Date().getFullYear();
                let years = [];
                let endYear = currentYear + 11;
                while (currentYear <= endYear) {
                    years.push(currentYear++);
                }
                return years;
            }
        },
        watch: {
            submittingPayment: function (newValue, oldValue) {
                //console.log('submittingPayment old', oldValue);
                //console.log('submittingPayment new', newValue);
                if (newValue !== oldValue && newValue === true) {
                    this.processPayment();
                }
            },

            //selectedPaymentMethod: function (newValue, oldValue) {
            //    if (newValue !== oldValue && newValue) {
            //        if (!this.formValid) {
            //            selectedPaymentMethod = '';
            //        }
            //    }
            //}
        },
        methods: {
            billingAddressChanged: function (address) {
                this.billingAddress = address;
                this.billingAddressId = address.id;
            },

            processPayment: async function () {
                if (this.paymentSubmitted)
                    return;

                // mark the form as submitted so we show a spinner and don't allow resubmission
                this.initiatePaymentSubmission();

                // Create an order from our basket
                await this.createOrder();
                // Set the link on the 3ds popup to point to the order now that we have created it
                //if (this.orderData.orderReference) {
                //    $('#threeDSFormOrderLink').prop('href', '/account/orderdetails?id=' + this.orderData.orderReference);
                //}
                // Submit adm pay if applicable
                //var admPaymentResult = submitAdmPayment(this.orderData, cardData, form);

                // If adm pay passes then finish the payment flow
                //if (admPaymentResult) {
                //    return;
                //}

                try {
                    // check if the card is enrolled for 3D Secure 2
                    const checkVersionParams = {
                        card: this.cardData,
                        order: this.orderData,
                    };
                    //console.log('checkVersionParams', checkVersionParams);
                    const versionCheckData = await checkVersion('/en-gb/api/paymentapi/checkversion', checkVersionParams);
                    //console.log('versionCheckData', versionCheckData);

                    if (versionCheckData.error) {
                        this.submitPaymentFailed([versionCheckData.error]);
                        return;
                    }

                    // If the card is enrolled then authenticate
                    const initiateAuthenticationParams = {
                        serverTransactionId: versionCheckData.serverTransactionId,
                        methodUrlComplete: true,
                        card: this.cardData,
                        challengeWindow: {
                            windowSize: ChallengeWindowSize.Windowed600x400,
                            displayMode: 'embedded',
                            target: '#threeDSForm'
                        },
                        order: this.orderData,
                        billingaddress: this.billingAddressData
                    };
                    //console.log('initiateAuthenticationParams', initiateAuthenticationParams);
                    const authenticationData = await initiateAuthentication('/en-gb/api/paymentapi/initiateauthentication', initiateAuthenticationParams);
                    //console.log('authenticationData', authenticationData);

                    // frictionless authentication success and authorization success
                    if (authenticationData.result == "AUTHORIZATION_SUCCESS") {
                        this.submitPaymentSuccess(authenticationData.status);
                    }
                    // frictionless authentication success and authorization failure
                    else if (authenticationData.result == "AUTHORIZATION_FAILURE") {
                        this.submitPaymentFailed(["Payment Failed [Frictionless] - authorization (payment) failed"]);
                    }
                    // frictionless authentication failure
                    else if (authenticationData.result == "AUTHENTICATION_FAILURE") {
                        this.submitPaymentFailed(["Payment Failed [Frictionless] - authentication (enrollment) failed"]);
                    }
                    // challenge success
                    else if (authenticationData.challenge.response.data.transStatus == "Y") {
                        this.submitPayment(authenticationData.challenge.response.data.threeDSServerTransID);
                    }
                    // challenge failure
                    else {
                        this.submitPaymentFailed(["Payment Failed [Challenge] - challenge failed"]);
                    }
                }
                catch (ex) {
                    //console.log('initiateAuthentication ex', ex);
                    this.submitPaymentFailed(ex.reasons);
                }
            },

            initiatePaymentSubmission: function () {
                const _this = this;
                _this.paymentSubmitted = true;
                _this.loadingChallenge = true;

                // Poll the form for the iframes existance
                var intv = setInterval(function () {
                    var iframe = document.querySelector('#threeDSForm iframe');

                    if (iframe !== null) {
                        clearInterval(intv);
                        _this.loadingChallenge = false;
                    }
                }, 500);

                $('#threeDSModal').modal({ backdrop: 'static', keyboard: false });
            },

            // Creates the order from the users basket contents
            createOrder: async function () {
                const _this = this;
                //console.log('createOrder', this.basketId);
                if (this.basketId) {
                    await $.ajax({
                        type: "POST",
                        url: '/en-gb/api/basketapi/processbasketforpayment/',
                        success: function (result) {
                            //console.log('createOrder result', result);
                            if (result.value && result.value.orderRef) {
                                _this.orderReference = result.value.orderRef;

                                if (result.value.cid) {
                                    _this.customerId = result.value.cid;
                                }
                            }
                            else {
                                //console.log('createOrder failed');
                                //window.location.reload();
                            }
                        },
                        error: function () {
                            //console.log('createOrder error');
                            //window.location.reload();
                        }
                    });
                }
            },

            // POST the data to authorize the payment after successful 3ds
            submitPayment: function (serverTransactionId) {
                const _this = this;
                var data = {
                    card: this.cardData,
                    serverTransactionId: serverTransactionId,
                    order: this.orderData,
                };
                //console.log('submitPayment', data);

                $.ajax({
                    url: '/en-gb/api/paymentapi/initiateauthorizepayment',
                    type: 'POST',
                    data: data,
                    success: function (response) {
                        //console.log('submitPayment result', response);
                        if (response.result === 'AUTHORIZATION_SUCCESS') {
                            _this.submitPaymentSuccess(response.status);
                        } else {
                            _this.submitPaymentFailed(["Payment Failed [Challenge] - authorization (payment) failed"]);
                        }
                    },
                    error: function () {
                        _this.submitPaymentFailed(["Payment Failed [Challenge] - authorization (payment) failed"]);
                    }
                });
            },

            submitPaymentSuccess: function (orderStatus) {
                if (this.basketId || this.newOrder) {
                    let basketGuid = '';
                    if (this.basketId) {
                        basketGuid = this.basketId
                    }
                    const url = '/' + this.cultureCode + '/order/orderconfirmed';
                    var form = $('<form action="' + url + '" method="post">' +
                        '<input type="text" name="orderRef" value="' + this.orderReference + '" />' +
                        '<input type="text" name="basketId" value="' + basketGuid + '" />' +
                        '</form>');
                    $('body').append(form);
                    form.submit();
                }
                else if (this.orderReference) {
                    window.location.replace('/account/orderdetails/?id=' + this.orderReference);
                }
                else {
                    window.location.reload();
                }
            },
            submitPaymentFailed: function(errors) {
                //console.log('submitPaymentFailed', errors);

                if (this.basketId || this.newOrder) {
                    const url = '/' + this.cultureCode + '/order/orderfailed';
                    var form = $('<form action="' + url + '" method="post">' +
                        '<input type="text" name="orderRef" value="' + this.orderReference + '" />' +
                        '</form>');
                    $('body').append(form);
                    form.submit();
                }
                else if (this.orderReference) {
                    window.location.replace('/account/orderdetails/?id=' + this.orderReference);
                }
                else {
                    window.location.reload();
                }
            },

            selectPaymentMethod: function (method) {
                this.selectedPaymentMethod = method;
            },
        },
        created() {
            this.$successLog({ description: 'Component - Payment', outcome: 'Created' });
        },
        mounted() {
            this.$successLog({ description: 'Component - Payment', outcome: 'Mounted' });
            let transaction = {
                shop_name: 'Send My Bag',
                product_price: this.totalToPay,
                shop_localisation: {
                    currencyCode: this.currencyCode,
                    countryCode: 'GB'
                }
            }

            // Initialise Apply Pay, the result returned indicates if it is available to use
            this.isApplePayAvailable = applePayController.init(transaction, this.applePayOrderData);
            
            // If we only have 1 payment method available then default to card (always available)
            if (this.totalPaymentMethodsAvailable === 1 || !this.showPaymentSelection) {
                this.selectedPaymentMethod = 'card';
            }
        }
    }
</script>

