<template>
    <div class="card-body">
        <div class="font-weight-bold">Route</div>
        <div><span id="outboundOriginName">{{ data.originCountry }}</span> <i class="fa-fw fas fa-arrow-alt-right fa-sm text-primary mx-2"></i> <span id="outboundDestinationName">{{ data.destinationCountry }}</span></div>
        <div v-show="outboundCollectionDate">
            <div class="font-weight-bold">Collection Date</div>
            <span id="outboundCollectionDate">{{ outboundCollectionDate }}</span>
        </div>

        <div v-if="isReturn" class="pt-2">
            <div><span id="inboundDestinationName">{{ data.destinationCountry }}</span> <i class="fa-fw fas fa-arrow-alt-right fa-sm text-primary mx-2"></i> <span id="inboundOriginName">{{ data.originCountry }}</span></div>
            <div v-show="inboundCollectionDate">
                <div class="font-weight-bold">Collection Date</div>
                <span id="inboundCollectionDate">{{ inboundCollectionDate }}</span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'basket-summary-locations',
        computed: {
            data: function () {
                return this.$store.state.summary.data;
            },
            isReturn: function () {
                return this.$store.state.addresses.isReturn;
            },
            outboundCollectionDate: function () {
                let date = this.$store.state.dates.outboundCollectionDate;
                if (date)
                    return moment(date, 'YYYY-MM-DD').format('Do MMMM YYYY');

                return '';
            },
            inboundCollectionDate: function () {
                let date = this.$store.state.dates.inboundCollectionDate;
                if (date)
                    return moment(date, 'YYYY-MM-DD').format('Do MMMM YYYY');

                return '';
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Summary Locations', outcome: 'Created' });
        }
    }
</script>
