<template>
    <div class="form-group row">
        <div class="col-12">
            <validation-provider :rules="{ required }" v-slot="{ errors }" vid="date-collectiondate" name="Collection date">
                <div class="input-group">
                    <flat-pickr class="flatpickr-input-arrow form-control flatpickr-clear-bg-color" placeholder="Select date" id="date-collectiondate"
                                v-model="collectionDate"
                                :config="flatpickrConfig"></flat-pickr>
                </div>
                <div class="text-danger">{{ errors[0] }}</div>
            </validation-provider>
        </div>
    </div> 
</template>

<script>
    import { ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';
    import flatPickr from '../../../components/flatpickr/vue-flatpickr-component.js';
    import moment from 'moment';

    extend('required', { ...required, message: 'The {_field_} is required' });

    export default {
        name: 'basket-date-select',
        components: {
            flatPickr,
            ValidationProvider
        },
        props: {
            value: String,
            dateParams: {
                minDate: null,
                maxDate: null,
                weekendDays: [],
                noCollectionDays: [],
                additionalCollectionServices: []
            },
        },
        data: function () {
            return {
                flatpickrConfig: {
                    disableMobile: true,
                    wrap: false,
                    allowInput: false,
                    defaultDate: '',
                    dateFormat: "YYYY-MM-DD",
                    formatDate: (date, format, locale) => {
                        return moment(date).format(format);
                    },
                    altInput: true,
                    altFormat: "dddd, Do MMMM YYYY",
                    parseDate: (datestr, format) => {
                        return moment(datestr, format, true).toDate();
                    },
                    minDate: this.dateParams.minDate,
                    maxDate: this.dateParams.maxDate,
                    disable: [],
                    monthSelectorType: "static",
                    onOpen: [
                        function () {
                            $(".flatpickr-month").css("pointer-events", "none");
                            $(".numInput.cur-year").addClass("flatpickr-year-readonly").attr('type', 'text');
                        }],
                    onValueUpdate: () => { }, // Added to trigger event for validation
                    inline: false
                }
            }
        },
        methods: {
            updateCollectionDate(value) {
                this.$emit('input', value);
            },

            updateFlatpickrConfig() {
                this.flatpickrConfig.minDate = this.dateParams.minDate;
                this.flatpickrConfig.maxDate = this.dateParams.maxDate;
                let _this = this;
                //console.log('additional collections', _this.dateParams.additionalCollectionServices);
                //console.log('no collections', _this.dateParams.noCollectionDays);
                //console.log('weekends', _this.dateParams.weekendDays);
                this.flatpickrConfig.disable = [function (date) {
                    let formattedDate = moment(date).format('YYYY-MM-DD');

                    // Check for additional collection services, we do this first and exit early as this overrides no colections dates and weekends
                    if (_this.dateParams.additionalCollectionServices.some((serviceVal) => serviceVal.date === formattedDate)) {
                        return false;
                    }

                    // Check for no collections dates
                    if (_this.dateParams.noCollectionDays.includes(formattedDate)) {
                        // return true to disable date
                        return true;
                    }

                    // Check weekend dates
                    if (_this.dateParams.weekendDays.includes(date.getDay())) {
                        return true;
                    }
                }];
            }
        },
        computed: {
            collectionDate: {
                get() { return this.value },
                set(value) { this.updateCollectionDate(value); }
            },
        },
        watch: {
            dateParams: function () {
                this.updateFlatpickrConfig();
            },
        },
        created() {
            this.$successLog({ description: 'Component - Basket Date Select Component', outcome: 'Created' })
        }
    }
</script>