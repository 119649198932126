<template>
    <div class="card card-gray mb-3" v-if="showRewardPoints">
        <div class="card-header text-center">
            <div class="h2 mb-0">Reward Points</div>
        </div>
        <div class="card-body row">
            <div class="custom-control custom-checkbox m">
                <label>
                    <input
                        class="custom-control-input pull-right"
                        type="checkbox"
                        id="essentials-userewardpoints"
                        v-model="usePoints"
                    />
                    <label
                        class="custom-control-label"
                        for="essentials-userewardpoints"
                        style="font-size: 15px; padding-top: 1px"
                    >
                        You have {{ points }} points, use it for this order?
                    </label>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
const $ = window.$
export default {
    name: 'use-reward-points',
    props: {
        points: Number,
        pointsValue: Number,
        pointsFormatted: String,
    },
    computed: {
        usePoints: {
            get: function () {
                return this.$store.state.essentials.usePoints
            },
            set: function (value) {
                this.$store.commit('setUsePoints', value)
            },
        },
        showRewardPoints: {
            get() {
                return this.points > 0
            },
        },
    },
    created() {
        this.$successLog({
            description: 'Component - Basket Essentials Reward Points',
            outcome: 'Created',
        })
    },
}
</script>
