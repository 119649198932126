var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.previousOrders.length && _vm.previousOrders != null)?_c('div',[_vm._l((_vm.previousOrders),function(order){return _c('div',{key:order.reference},_vm._l((order.journeys),function(journey){return _c('div',{key:journey.id},[_c('div',{staticClass:"card card-gray py-3 my-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row previous-orders"},[_c('div',{staticClass:"col-xl-8"},[_c('div',[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-4 font-weight-bold px-1"},[_vm._v(" Route ")]),_c('div',{staticClass:"col-8 text-left"},[_vm._v(" "+_vm._s(journey.originCountryName)+" to "+_vm._s(journey.destinationCountryName)+" ")])]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-4 font-weight-bold px-1"},[_vm._v(" Order Reference ")]),_c('div',{staticClass:"col-8 text-left"},[_vm._v(" "+_vm._s(order.reference)+" ")])]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-4 font-weight-bold px-1"},[_vm._v(" Date Booked ")]),_c('div',{staticClass:"col-8 text-left"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(order.createdOn))+" ")])]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-4 font-weight-bold px-1"},[_vm._v(" Collection ")]),_c('div',{staticClass:"col-8 text-left"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(journey.collectionDate))+" ")])])])]),_c('div',{staticClass:"col-xl-4 mt-xl-0 mt-4"},[(
                                        !order.receipts.length ||
                                        order.receipts.isDeleted == true
                                    )?_c('div',[_vm._m(0,true)]):_vm._e(),_vm._l((order.receipts),function(receipt){return _c('div',{key:receipt},[(
                                            order.receipts != null &&
                                            receipt.isDeleted == false
                                        )?_c('div',[_c('div',[_c('a',{staticClass:"btn btn-success col-md-12",attrs:{"href":order.awsPath +
                                                    'files/' +
                                                    receipt.file,"target":"_blank"}},[_vm._v("Download Receipt")])])]):_vm._e()])}),_c('div',{staticClass:"mt-5"},[_c('button',{staticClass:"btn btn-white-success btn-block",attrs:{"type":"submit"},on:{"click":function($event){return _vm.viewOrder(order)}}},[_vm._v(" View Order ")])])],2)])])])])}),0)}),_c('div',[(_vm.previousOrders.length > 0 && _vm.loadmoreOrders == true)?_c('div',[_c('button',{staticClass:"btn btn-success load-more-previous-orders",attrs:{"id":"loadMore"},on:{"click":_vm.loadMore}},[_vm._v(" Load More.. ")])]):_vm._e(),(_vm.loading)?_c('div',[_vm._m(1)]):_vm._e(),(_vm.errorResponse == 'No More Previous Orders!')?_c('div',[_c('div',{staticClass:"alert text-center text-underline"},[_c('strong',[_vm._v(_vm._s(_vm.errorResponse))])])]):_vm._e()])],2):_c('div',[_c('div',[_vm._v("You don't have any completed orders.")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"btn btn-success btn-block mb-3",attrs:{"type":"button","disabled":""}},[_vm._v(" Download Receipt ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-icon loading-icon-previous-orders"},[_c('div',{staticClass:"reverse-spinner"})])
}]

export { render, staticRenderFns }