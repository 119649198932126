<template>
    <div>
        <div class="pb-3">
            <h1>Review your collection and delivery addresses:</h1>
        </div>
        <error-card v-if="!addressesAvailableForCollection"
                    :title="smbI18n.checkout.AddressProblem"
                    :messages="addressesAvailableForCollectionErrorMessages">
        </error-card>
        <div class="row mb-4">
            <basket-address-review-card addressType="Collection" :addressDetails="originAddressDetails"></basket-address-review-card>
            <basket-address-review-card addressType="Delivery" :addressDetails="destinationAddressDetails"></basket-address-review-card>
        </div>
        <basket-switch-oneway-or-return-journey></basket-switch-oneway-or-return-journey>
        <basket-ras-charge-modal v-if="hasRasCharge"></basket-ras-charge-modal>
    </div>
</template>
<script>
    import BasketAddressReviewCard from './addresses/AddressReviewCard.vue'
    import BasketSwitchOnewayOrReturnJourney from './addresses/OnewayOrReturnJourney.vue'
    import BasketRasChargeModal from './addresses/RasChargeModal.vue'
    import ErrorCard from '../../components/shared/ErrorCard.vue';

    export default {
        name: 'basket-step4',
        components: {
            BasketAddressReviewCard,
            BasketSwitchOnewayOrReturnJourney,
            BasketRasChargeModal,
            ErrorCard
        },
        computed: {
            originAddressDetails: {
                get() { return this.$store.state.addresses.origin }
            },
            destinationAddressDetails: {
                get() { return this.$store.state.addresses.destination }
            },
            hasRasCharge: {
                get() { return this.$store.state.addresses.hasRasCharge }
            },
            addressesAvailableForCollection: {
                get() { return this.$store.state.addresses.addressesAvailableForCollection }
            },
            addressesAvailableForCollectionErrorMessages: {
                get() { return this.$store.state.addresses.addressesAvailableForCollectionErrorMessages }
            }
        },
        metaInfo() {
            return {
                title: window.smbData.CMS.Addresses.Meta.Title
            };
        },
        created() {
            this.$successLog({ description: 'Component - Basket Step 4', outcome: 'Created' });
            this.$store.dispatch('updateSummary');
        },
        mounted() {
            $(window).scrollTop(0);
        }
    }
</script>
