<template>
    <div class="modal fade" ref="confirm-modal" :id="id" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-html="title"></h5>
                </div>

                <div class="modal-body">
                    <slot>
                        <div v-html="body"></div>
                    </slot>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-success bg-white text-success" @click="cancel">Cancel</button>
                    <button type="button" class="btn btn-success" @click="confirm">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'confirm-modal',
        props: {
            id: {
                type: String,
                required: true
            },
            title: String,
            body: String
        },
        data: function () {
            return {
                resolvePromise: undefined,
                rejectPromise: undefined,
            };
        },
        methods: {
            open: function () {
                $('#' + this.id).modal('show');
            },
            close: function () {
                $('#' + this.id).modal('hide');
            },
            getConfirmation: function () {
                this.open();

                const _this = this;
                return new Promise((resolve, reject) => {
                    _this.resolvePromise = resolve;
                    _this.rejectPromise = reject;
                });
            },
            confirm: function () {
                this.close();
                this.resolvePromise(true);
            },
            cancel: function () {
                this.close();
                this.resolvePromise(false);
            }
        }
    }
</script>