<template>
    <div class="col-sm-12 col-md-6 flex-fill text-center d-flex">
        <div class="card card-gray pb-2 mb-4 flex-grow-1">
            <div class="mt-2 flex-grow-1"><h2>{{ addressType }}</h2></div>
            <div class="flex-grow-1">
                <div class="mt-2"><strong>{{ addressDetails.contactName }}</strong></div>
                <div>{{ addressDetails.companyname }}</div>
                <div>{{ addressDetails.line1 }}</div>
                <div>{{ addressDetails.line2 }}</div>
                <div>{{ addressDetails.line3 }}</div>
                <div>{{ addressDetails.cityName }}, {{ addressDetails.postcode }}</div>
                <div>{{ addressDetails.country }}</div>
            </div>
            <div class="mt-2 mb-2">
                <button type="button" class="btn btn-stylised px-0 py-0" v-on:click.stop.prevent="editAddress">
                    Change
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$;

    export default {
        name: 'basket-address-review-card',
        props: {
            addressType: String,
            addressDetails: Object,
        },
        methods: {
            editAddress: function () {
                if (this.addressType === 'Collection') {
                    this.$store.commit('setStepForward', window.smbData.STEPS_ORIGINADDRESS);
                } else {
                    this.$store.commit('setStepForward', window.smbData.STEPS_DESTINATIONADDRESS);
                }

                //this.$store.dispatch('updateAddress', this.addressType);
            }
        },
        created() {
            this.$successLog({ description: `Component - ${this.addressType} Basket Address Review Card`, outcome: 'Created' })
        },
    }
</script>

