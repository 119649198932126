<template>
    <div class="file-upload__actions container-wrapper-grey">
        <a href="#" class="file-upload__action file-upload__action-remove" data-toggle="tooltip" data-placement="bottom" title="Remove" v-on:click="removeimagefromAWS()" bg-transparent><i class="fas fa-times"></i></a>
        <div class="fileupload-item__preview"><img :id="id" :name="controlid" :src="url" class="fileupload-item__image" style="width:278px;height:200px;" /></div>
    </div>
</template>
<script>

    const $ = window.$;
    export default {
        name: 'preview-fileupload',
        props: {
            id: String,
            url: String,
            controlid: String
        },
        data: function () {
            return {
                isSelected: this.isSelectedInitial
            };
        }, 
        computed: {
            filesize: function () {
                try {
                    if (this.contentlength > 1024 * 1024) {
                        return (this.contentlength / (1024 * 1024)).toFixed(2) + ' MB';
                    }
                    else if (this.contentlength > 1024) {
                        return (this.contentlength / 1024).toFixed(2) + ' kB';
                    }

                    return this.contentlength.toFixed(0) + ' B';
                }
                catch (e) {
                    return '';
                }
            },
            hasMissingData: function () {
                if (!this.showAdditionalData || (this.alt && this.alt.length)) {
                    return false;
                }

                return true;
            }
        },
        methods: {
            triggerremoveasset: function () {
                this.$emit('triggerremoveasset', this.fileId);
            },
            removeimagefromAWS: function () {
                  var params = {
                    fileToDelete: document.getElementById(this.id).src
                }
                  $.getJSON('/en-gb/api/account/deleteimage', params, function () {
                });
                document.getElementById("upl_" + this.id).style.display = "none";
                document.getElementById(this.id).src = "";
            }

        }
    }
</script>

