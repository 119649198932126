<template>
    <flat-pickr v-model="date"
                :config="generalConfig?configGeneral:config"
                class="flatpickr-input-arrow form-control flatpickr-clear-bg-color"
                placeholder=""
                :name="name">
    </flat-pickr>

</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    const $ = window.$;
    export default {
        name: 'custom-forms-date-select',
        components: { flatPickr },
        props: {
            name: String,
            isDisabled: Boolean,
            value: Date,
            dateFormat: {
                type: String,
                default: 'Y-m-d'
            },
            isRequired: {
                type: Boolean,
                default: false
            },
            requiredValidationText: {
                type: String,
                default: ''
            }
        },
        data: function () {
            var _dateFromat = this.dateFormat;
            var _isRequired = this.isRequired;
            var _requiredValidationText = this.requiredValidationText;
            var _date = this.value;

            return {
                date: _date,
                config: {
                    wrap: false, // set wrap to true only when using 'input-group'
                    altFormat: 'D, J F Y',
                    altInput: true,
                    dateFormat: _dateFromat,
                    allowInput: false,
                    onReady: function (selectedDates, dateStr, instance) {
                        if (_isRequired) {
                            $(instance.element).attr({
                                "data-val": "true",
                                "data-val-required": _requiredValidationText,
                            });

                            if (this.altInput) {
                                $(instance.altInput).attr({
                                    "name": instance.element.name + "-alt",
                                    "data-val": "true",
                                    "data-val-required": _requiredValidationText,
                                });
                            }
                        }
                    },
                    onOpen: function (selectedDates, dateStr, instance) {
                        instance.currentYearElement.tabIndex = -1; // Prevent the year dropdown from being focused by default
                        instance.currentYearElement.addEventListener("change", function () {
                            // Jump to the selected year when the user changes the year in the dropdown
                            const year = this.value;
                            instance.jumpToDate(new Date(year, instance.currentMonth, 1));
                        });
                    },
                    onClose: function (selectedDates, dateStr, instance) {
                        $(instance.altInput).prop('readonly', true);
                        $(instance.altInput).blur();
                    }
                }
            }
        },
        methods: {

        },

        watch: {

        }
    }
</script>

