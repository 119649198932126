 <template>
    <select-2-country :options="countries"
                      v-model="selectedIso"
                      :default-selected-iso="defaultSelectedIso"
                      class="form-control form-control-lg"
                      style="width:100%;"
                      :placeholder="placeholderText"
                      :disabled="isDisabled"
                      :default-value-on-options-update="defaultValueOnOptionsUpdate">
    </select-2-country>
</template>

<script>     
    import Select2Country from '../select-2/Select2Country.vue';

    export default {
       components: { Select2Country },
        props: {
            defaultSelectedIso: String,
            placeholder: String,
            countries: Array,
            isLoading: { default: false, type: Boolean },
            isEnabled: { default: true, type: Boolean },
            // SMB3-222: Can these be better named to reflect use-case and intention?
            selectedIsoValue: String,
            shouldDefaultBeSelected: { default: true, type: Boolean }, // useDefaultIf ..
            defaultValueOnOptionsUpdate: { default: true, type: Boolean } // clearSelectionOnOptionsUpdate ..
            // -------------
        },
      //  template: '#component--country-select',
        data: function () {
            return {
                enabled: this.isEnabled
            };
        }, 
        computed: {
            selectedIso: {
                get() {
                    return this.shouldDefaultBeSelected
                        ? this.defaultSelectedIso
                        : this.selectedIsoValue;
                },
                set(value) {
                    this.$emit('input', value);
                    this.$emit('valueChanged', value);
                }
            },

            placeholderText: function () {
                if (this.isLoading) {
                    return 'Loading...';
                }

                return this.placeholder;
            },

            isDisabled: function () {
                // Disabled if loading
                if (this.isLoading || !this.enabled) {
                    return true;
                }

                // Disabled if no countries
                if (!(this.countries && this.countries.length > 0)) {
                    return true;
                }

                return false;
            }
        }
    }
</script>

 