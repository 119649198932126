<template>
    <div>
        <div class="card card-gray mb-3" v-if="hasPostageMethods">
            <div class="card-header text-center">
                <div class="h2 mb-0">Labels</div>
                <div
                    class="small"
                    v-if="
                        canBuyLabels &&
                        smbData.CMS.Essentials.CanBuyLabelsAlert.Message
                    "
                >
                    {{ smbData.CMS.Essentials.CanBuyLabelsAlert.Message }}
                </div>
                <warning-card
                    class="mt-2"
                    v-if="!canBuyLabels"
                    :icon="smbData.CMS.Essentials.CannotBuyLabelsAlert.Icon"
                    :messages="[
                        smbData.CMS.Essentials.CannotBuyLabelsAlert.Message,
                    ]"
                >
                </warning-card>
            </div>

            <div class="card-body">
                <div class="form-group">
                    <label for="essentials-labelholders"
                        >FREE Label Holders (excludes delivery)</label
                    >
                    <i
                        class="fas fa-info-circle nav-hover text-blue float-right"
                        v-on:click.stop.prevent="showModal('labelholdermodal')"
                    ></i>
                    <validation-provider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                        vid="essentials-labelholders"
                        name="Label holders"
                    >
                        <select-2
                            id="essentials-labelholders"
                            class="form-control"
                            style="width: 100%"
                            placeholder="Please Select"
                            data-minimum-results-for-search="Infinity"
                            :options="labelHolderOptions"
                            :default-value-on-options-update="false"
                            v-model="labelHolders"
                            :key="labelHolders"
                        >
                        </select-2>
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                    <warning-card
                        class="mt-2"
                        v-if="labelHoldersNotSelected"
                        :icon="
                            smbData.CMS.Essentials
                                .EnsureLabelsSecurelyAttachedAlert.Icon
                        "
                        :messages="[
                            smbData.CMS.Essentials
                                .EnsureLabelsSecurelyAttachedAlert.Message,
                        ]"
                    >
                    </warning-card>
                </div>

                <div class="form-group" v-if="canBuyLabels">
                    <label for="essentials-labels"
                        >Pre-printed Labels - {{ labelCostFormatted }} (excludes
                        delivery)</label
                    >
                    <i
                        class="fas fa-info-circle nav-hover text-blue float-right"
                        v-on:click.stop.prevent="showModal('postlabelmodal')"
                    ></i>
                    <validation-provider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                        vid="essentials-labels"
                        name="Labels"
                    >
                        <select-2
                            id="essentials-labels"
                            class="form-control"
                            style="width: 100%"
                            placeholder="Please Select"
                            data-minimum-results-for-search="Infinity"
                            :options="labelOptions"
                            :default-value-on-options-update="false"
                            v-model="labels"
                            :key="labels"
                        >
                        </select-2>
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
                <warning-card
                    class="mt-2"
                    v-if="showPrinterRequired"
                    :icon="smbData.CMS.Essentials.CannotBuyLabelsAlert.Icon"
                    :messages="[
                        smbData.CMS.Essentials.CannotBuyLabelsAlert.Message,
                    ]"
                >
                </warning-card>

                <div v-if="showPostageMethodOptions">
                    <div class="col-12 small">
                        {{
                            smbData.CMS.Essentials
                                .SelectedProductsWillBePostedAlert.Message
                        }}
                    </div>
                    <div class="pt-3 form-group">
                        <label class="form-check-label"
                            >Select Postage Method</label
                        >
                    </div>

                    <div
                        class="form-group"
                        v-for="postageMethod in postageMethods"
                        :key="postageMethod"
                    >
                        <label
                            class="form-check form-check-inline radiobutton mb-0"
                            v-bind:for="
                                'method-' + postageMethod.postageMethodName
                            "
                        >
                            {{ postageMethod.postageMethodName }} ({{
                                postageMethod.priceIncTaxString
                            }})
                            <input
                                class="form-check-input"
                                type="radio"
                                name="postage-method"
                                v-model="postageMethodId"
                                v-bind:id="
                                    'method-' + postageMethod.postageMethodName
                                "
                                v-bind:value="postageMethod.postageMethodId"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <validation-provider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                        vid="essentials-postagemethodid"
                        name="Postage method"
                    >
                        <input type="hidden" v-model="postageMethodId" />
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>

                    <div class="col-12 col-sm-auto mb-2">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label"
                                >Delivery address (for label holders)</label
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            class="form-check form-check-inline radiobutton"
                            for="collection-address"
                        >
                            {{ originAddress.line1 }}
                            <input
                                class="form-check-input"
                                id="collection-address"
                                type="radio"
                                name="delivery-method"
                                v-model="useCollectionAddressForDelivery"
                                v-bind:value="true"
                            />
                            <span class="checkmark"></span>
                        </label>
                        <label
                            class="form-check form-check-inline radiobutton"
                            for="different-address"
                        >
                            Enter a different address
                            <input
                                class="form-check-input"
                                id="different-address"
                                type="radio"
                                name="delivery-method"
                                v-model="useCollectionAddressForDelivery"
                                v-bind:value="false"
                            />
                            <span class="checkmark"></span>
                        </label>
                        <journey-address
                            v-if="!useCollectionAddressForDelivery"
                            v-model="postageAddress"
                            :addressFieldFlags="addressFieldFlags"
                            :addressTypes="addressTypes"
                            :phoneNumberCountries="phoneNumberCountries"
                            :countryDetails="originCountry"
                        ></journey-address>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!hasPostageMethods" class="card card-gray mb-3">
            <div class="card-header text-center">
                <div class="h2 mb-0">Labels</div>
                <div class="small">
                    {{ smbData.CMS.Essentials.CannotBuyLabelsAlert.Message }}
                </div>
            </div>
        </div>

        <generic-modal
            id="labelholdermodal"
            :title="smbData.CMS.Essentials.LabelHolder.Header"
            :content="smbData.CMS.Essentials.LabelHolder.Content"
        ></generic-modal>
        <generic-modal
            id="postlabelmodal"
            :title="smbData.CMS.Essentials.PostLabel.Header"
            :content="smbData.CMS.Essentials.PostLabel.Content"
        ></generic-modal>
        <generic-modal
            id="postageCutoffWarningModal"
            :title="smbData.CMS.Essentials.CollectionDateLabelHolder.Header"
            :content="smbData.CMS.Essentials.CollectionDateLabelHolder.Content"
        ></generic-modal>
    </div>
</template>
<script>
import { ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import WarningCard from '../../../components/shared/WarningCard.vue'
import JourneyAddress from '../addresses/JourneyAddress.vue'

extend('required', { ...required, message: 'The {_field_} is required' })

export default {
    name: 'labels-and-label-holders',
    components: {
        JourneyAddress,
        WarningCard,
        ValidationProvider,
    },
    props: {
        // Other Data
        canBuyLabels: Boolean,
        labelHolderOptions: Array,
        postageMethods: Array,
        labelCostFormatted: String,
        addressFieldFlags: Object,
        addressTypes: Array,
        phoneNumberCountries: Array,
        originCountry: Object,
        originAddress: Object,
    },
    computed: {
        labelHolders: {
            get: function () {
                return this.$store.state.essentials.labelHolders
            },
            set: function (value) {
                this.$store.commit('setLabelHolders', value)
            },
        },
        labels: {
            get: function () {
                return this.$store.state.essentials.labels
            },
            set: function (value) {
                this.$store.commit('setLabels', value)
            },
        },
        postageMethodId: {
            get: function () {
                return this.$store.state.essentials.postageMethodId
            },
            set: function (value) {
                this.$store.commit('setPostageMethodId', value)
            },
        },
        useCollectionAddressForDelivery: {
            get: function () {
                return this.$store.state.essentials
                    .useCollectionAddressForDelivery
            },
            set: function (value) {
                this.$store.commit('setUseCollectionAddressForDelivery', value)
            },
        },
        postageAddress: {
            get: function () {
                return this.$store.state.essentials.postageAddress
            },
            set: function (value) {
                this.$store.commit('setPostageAddress', value)
            },
        },

        hasPostageMethods: function () {
            if (this.postageMethods) return true

            return false
        },
        postageMethod: function () {
            const _this = this
            if (this.postageMethodId) {
                const result = _this.postageMethods.filter((obj) => {
                    return obj.postageMethodId === _this.postageMethodId
                })

                if (result) {
                    return result[0]
                }
            }

            return null
        },
        showPrinterRequired: function () {
            return this.labels == 'false' && this.canBuyLabels
        },
        labelHoldersNotSelected: function () {
            return this.labelHolders && this.labelHolders == 0
        },
        labelOptions: function () {
            return [
                { id: 'true', text: window.smbI18n.checkout.Yes },
                { id: 'false', text: window.smbI18n.checkout.No },
            ]
        },
        showPostageMethodOptions: function () {
            if (this.labelHolders && this.labelHolders > 0) return true

            if (this.labels == 'true') return true

            return false
        },
    },
    methods: {
        showModal: function (modal) {
            $('#' + modal).modal('show')
        },
        hideModal: function (modal) {
            $('#' + modal).modal('hide')
        },
    },
    watch: {
        postageMethodId: function (newValue, originalValue) {
            if (
                newValue > 0 &&
                newValue !== originalValue &&
                this.postageMethod
            ) {
                if (this.postageMethod.cutoffDays < 1)
                    this.showModal('postageCutoffWarningModal')
            }
        },
    },
    created() {
        this.$successLog({
            description: 'Component - Basket Essentials Labels',
            outcome: 'Created',
        })
    },
}
</script>
