export default {
    state: {
        countryOptions: [],
        useCollectionAddressForBilling: true,
        bid: 0,
        cid: 0,
        uid: ''
    },

    mutations: {
        setInitialStateConfirmation: function (state, payload) {
            state.countryOptions = payload.billingAddressCountries;
            state.useCollectionAddressForBilling = payload.useCollectionAddressForBilling;
            state.bid = payload.bid;
            state.cid = payload.cid;
            state.uid = payload.uid;
        },

        setUseCollectionAddressForBilling: function (state, payload) {
            state.useCollectionAddressForBilling = payload;
        },
    },
}