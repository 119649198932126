<template>
    <div v-if="data">
        <div class="text-center my-4">
            <div class="referrals-kpi-box referrals-kpi-box--credit">
                <div class="referrals-kpi-title">AFFILIATE CREDIT</div>

                <div class="referrals-kpi-subtitle">
                    {{ data && data.credit ? data.credit : '' }}
                </div>
            </div>
        </div>
        <div class="referrals-kpis">
            <div class="referrals-kpi-box" @click="setChartKey('click')">
                <div class="referrals-kpi-title">CLICK</div>
                <div class="referrals-kpi-subtitle my-4">
                    TODAY
                    {{ data && data.click ? data.click.today : '' }}
                </div>
                <div class="referrals-kpi-value">
                    This Week:
                    {{ data && data.click ? data.click.week : '' }}
                </div>
                <div class="referrals-kpi-value">
                    This Month:
                    {{ data && data.click ? data.click.month : '' }}
                </div>
                <div class="referrals-kpi-value">
                    All Time:
                    {{ data && data.click ? data.click.allTime : '' }}
                </div>
                <div class="referrals-kpi-data">VIEW DATA</div>
            </div>
            <div class="referrals-kpi-box" @click="setChartKey('referral')">
                <div class="referrals-kpi-title">REFERRAL</div>
                <div class="referrals-kpi-subtitle my-4">
                    TODAY
                    {{ data && data.referral ? data.referral.today : '' }}
                </div>
                <div class="referrals-kpi-value">
                    This Week:
                    {{ data && data.referral ? data.referral.week : '' }}
                </div>
                <div class="referrals-kpi-value">
                    This Month:
                    {{ data && data.referral ? data.referral.month : '' }}
                </div>
                <div class="referrals-kpi-value">
                    All Time:
                    {{ data && data.referral ? data.referral.allTime : '' }}
                </div>
                <div class="referrals-kpi-data">VIEW DATA</div>
            </div>
            <div class="referrals-kpi-box" @click="setChartKey('commission')">
                <div class="referrals-kpi-title">COMMISSION</div>
                <div class="referrals-kpi-subtitle my-4">
                    TODAY
                    {{ data && data.commission ? data.commission.today : '' }}
                </div>
                <div class="referrals-kpi-value">
                    This Week:
                    {{ data && data.commission ? data.commission.week : '' }}
                </div>
                <div class="referrals-kpi-value">
                    This Month:
                    {{ data && data.commission ? data.commission.month : '' }}
                </div>
                <div class="referrals-kpi-value">
                    All Time:
                    {{ data && data.commission ? data.commission.allTime : '' }}
                </div>

                <div class="referrals-kpi-data">VIEW DATA</div>
            </div>
        </div>
        <div class="my-4" v-if="data.click.charts.lastMonth.chartData">
            <LineChart
                :data="data[chartKey].charts[chartType].chartData"
                :initialLabel="chartKey"
            />
            <select v-model="chartType"
                    @change="setChartType($event)"
                    class="my-2 form-control">
                <option value="monthToDate">Month To Date</option>
                <option value="lastMonth">Last Month</option>
                <option value="yearToDate">Year To Date</option>
                <option value="lastYear">Last Year</option>
            </select>
            <!-- <BarChart /> -->
        </div>

        <div class="my-4" v-if="recentReferrals">
            <div class="my-2 referrals-kpi-subtitle">
                Most recent orders:
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Date Placed</th>
                        <th scope="col">Commission</th>
                        <th scope="col">Route</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(recentReferral, i) in recentReferrals" :key="i">
                        <td>{{ recentReferral.date }}</td>
                        <td>{{ recentReferral.commission }}</td>
                        <td>
                            {{ recentReferral.destination }} to
                            {{ recentReferral.origin }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="my-5">
            <h2>
                SHARE A
                {{ data && data.discountRate ? data.discountRate : '' }}%
                DISCOUNT AND RECEIVE
                {{ data && data.commissionRate ? data.commissionRate : '' }}% IN
                COMMISSION.
            </h2>
            <div>
                Your unique affiliate link: https://www.sendmybag.com{{
                    data && data.referralLink ? data.referralLink : ''
                }}
            </div>
        </div>
        <div class="my-5">
            <h2>SPREAD THE WORD</h2>
            <div>
                Your SendMyBag link can be shared on social, in newsletters and
                on your website. If you have any questions or would like a
                custom banner get in touch: traveltrade@sendmybag.com
            </div>
        </div>
        <div class="my-5">
            <h2>READY FOR COMMISSION TO BE PAID?</h2>
            <div>
                Once your account shows a minimum of £50 affiliate commission
                simply email traveltrade@sendmybag.com including an invoice to
                "SendMyBag (NI) Ltd" for the amount you wish to withdraw.
            </div>
            <div>
                On your invoice please specify the payment bank or paypal
                address. Payments will be made to UK based GBP bank accounts
                with no bank fees deducted.
            </div>
            <div>
                If your account is based outside of the UK or in a foriegn
                currency please be aware bank / currency conversion charges will
                apply and you may prefer to have the commission transferred in
                GBP to a paypal account.
            </div>
        </div>
        <div class="referrals-link-box my-5">
            <div>
                Use this link for referrals: https://www.sendmybag.com{{
                    data && data.referralLink ? data.referralLink : ''
                }}
            </div>
            <div>
                Commission Rate:
                {{ data && data.commissionRate ? data.commissionRate : '' }}%
            </div>
            <div>
                Discount Rate:
                {{ data && data.commissionRate ? data.discountRate : '' }}%
            </div>
        </div>
    </div>
</template>

<script>
//import BarChart from '../charts/Bar.vue'
import LineChart from '../charts/Line.vue'
export default {
    name: 'accountreferrals',
    components: {
        //BarChart,
        LineChart,
    },
    data: function () {
        return {
            loading: true,
            data: undefined,
            recentReferrals: undefined,
            chartKey: 'click',
            chartType: 'lastMonth',
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        setChartKey: function (key) {
            const _this = this
            _this.chartKey = key
        },
        setChartType: function (event) {
            const _this = this
            _this.chartType = event.target.value
        },
        loadData: function () {
            const _this = this
            _this.loading = true
            var jqxhr = $.getJSON(
                '/en-gb/api/accountapi/getaffiliateoverview/',
                function (result) {
                    _this.data = result
                    if (result && result.recentReferrals) {
                        _this.recentReferrals = result.recentReferrals
                    }
                }
            )

            jqxhr.always(() => {
                _this.loading = false
            })
        },
    },
}
</script>
