var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container p-0"},[_c('div',{class:[_vm.confirmOrderAlerts ? '' : '']},[_vm._t("default",function(){return [(
                    _vm.defaultAlertMessages != undefined &&
                    _vm.defaultAlertMessages.length > 0
                )?_c('div',[(_vm.defaultDestinationName !== '')?_c('div',{class:[
                        _vm.confirmOrderAlerts
                            ? 'alert alert-warning text-danger my-5 text-center text-md-left'
                            : 'alert alert-warning text-danger text-center text-md-left',
                    ],attrs:{"role":"alert"}},[_c('div',{staticClass:"row",attrs:{"id":"notes"}},[_c('div',{staticClass:"col-12 col-md-1 text-center text-md-right p-0"}),_c('div',{staticClass:"col-12 col-md-11 text-center text-md-left"},[_c('h2',[_vm._v(" "+_vm._s(_vm.defaultDestinationName)+" Route Alerts ")])]),_vm._l((_vm.defaultAlertMessages),function(alert){return _c('div',{key:alert,staticClass:"col-12 col-md-12 text-center text-md-left",attrs:{"id":"notes"}},[_c('div',{staticClass:"row pb-3 icon-container",attrs:{"id":"notes"}},[_vm._m(0,true),_c('div',{staticClass:"col-11 col-md-11 text-center text-md-left",domProps:{"innerHTML":_vm._s(alert)}})])])})],2)]):_vm._e()]):_vm._e()]})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1 col-md-1 text-center text-md-right p-0 centre-icon"},[_c('i',{staticClass:"fas fa-exclamation-circle fa-lg my-3 md-my-0"})])
}]

export { render, staticRenderFns }