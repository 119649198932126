<template>
    <validation-observer ref="observer" tag="form">
        <journey-address v-model="value"
                         :address-field-flags="fieldFlags"
                         :address-types="types"
                         :phone-number-countries="countryPhoneNumbers"
                         :country-details="country"
                         :key="key"
                         ref="journeyAddress">
        </journey-address>
    </validation-observer>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import JourneyAddress from '../../basket/components/addresses/JourneyAddress';
    import _ from 'lodash';

    export default {
        name: 'update-journey-address',
        components: {
            ValidationObserver,
            JourneyAddress
        },
        props: {
            address: {
                addressTypeId: Number,
                phoneNumber: {
                    number: String,
                    countryIso: String
                },
            },
            addressFieldFlags: {},
            addressTypes: Array,
            phoneNumberCountries: Array,
            countryDetails: {}
        },
        data: function () {
            return {
                value: this.address,
                fieldFlags: this.addressFieldFlags,
                types: this.addressTypes,
                countryPhoneNumbers: this.phoneNumberCountries,
                country: this.countryDetails,
                key: _.uniqueId(),
                addressResetValues: this.address
            };
        },
        methods: {
            reset: function () {
                this.value = { ...this.addressResetValues };
                this.key = _.uniqueId();
            },
            submit: async function (updateAddress) {
                const isValid = await this.$refs.observer.validate();

                if (!isValid) {
                    this.scrollToErrors();
                    return;
                }

                const model = this.$refs.journeyAddress.getShownAddressFieldValues();
                updateAddress(model);
            },
            scrollToErrors: function () {
                const errors = Object.entries(this.$refs.observer.refs)
                    .map(([key, value]) => ({
                        key,
                        value
                    }))
                    .filter(error => {
                        if (!error || !error.value || !error.value.failedRules)
                            return false;

                        return Object.keys(error.value.failedRules).length > 0;
                    });

                if (errors && errors.length > 0)
                    this.$refs.observer.refs[errors[0]['key']].$el.scrollIntoView();
            },
            updateAddressResetValues: function () {
                this.addressResetValues = { ...this.value };
            }
        },
        mounted() {
            window.updateJourneyAddress = this;
        }
    }
</script>