<template>
    <div>
        <basket-add-items></basket-add-items>
        <basket-item-list></basket-item-list>
        <basket-customs></basket-customs>
        <generic-modal id="countryRouteInformation" :title="countryRouteInformationTitle" :content="countryRouteInformation"></generic-modal>
    </div>
</template>
<script>
    import BasketAddItems from './items/BasketAddItems.vue'
    import BasketItemList from './items/BasketItemList.vue'
    import BasketCustoms from './items/BasketCustoms.vue'

    export default {
        name: 'basket-step1',
        components: {
            BasketAddItems,
            BasketItemList,
            BasketCustoms
        },
        metaInfo() {
            return {
                title: window.smbData.CMS.Items.Meta.Title
            };
        },
        computed: {
            countryRouteInformationTitle: function () {
                return window.smbI18n.checkout.ImportantInformation;
            },
            countryRouteInformation: function () {
                let result = '';
                if (this.$store.state.route.notSuitableForHoliday)
                    result += '<p>' + window.smbData.CMS.Items.ImportantInformationDetail.NotSuitableForHolidayDetail + '</p>';

                if (this.$store.state.route.notSuitableForHolidayOutbound)
                    result += '<p>' + window.smbData.CMS.Items.ImportantInformationDetail.NotSuitableForHolidayOutboundDetail + '</p>';

                if (this.$store.state.route.isDoorToPort)
                    result += '<p>' + window.smbData.CMS.Items.ImportantInformationDetail.IsDoorToPortDetail + '</p>';

                if (this.$store.state.route.isDoorToPortOutbound)
                    result += '<p>' + window.smbData.CMS.Items.ImportantInformationDetail.IsDoorToPortOutboundDetail + '</p>';

                return result;
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Step 1', outcome: 'Created' });
            this.$store.dispatch('updateSummary');
        },
        mounted() {
            $(window).scrollTop(0);
            if (this.countryRouteInformation) {
                $('#countryRouteInformation').modal({ backdrop: 'static', keyboard: false });
            }
       }
    }
</script>
