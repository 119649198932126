<template>
    <div class="mb-3" v-show="items && items.length > 0">
        <div class="row half-gutters">
            <basket-item v-for="(item, index) in items"
                         v-bind:width="item.width" v-bind:height="item.height" v-bind:length="item.length" v-bind:weight="item.weight"
                         v-bind:itemType="item.itemType" v-bind:description="item.description"
                         v-bind:index="index" v-bind:key="item">
            </basket-item>
        </div>
        <confirm-modal ref="copyItemConfirmModal" 
                       id="copy-item-confirm-modal"
                       :title="smbData.CMS.Items.ImportantInformationDetail.Header"
                       :body="smbData.CMS.Items.ImportantInformationDetail.CopyItemDetail">
        </confirm-modal>
    </div>
</template>
<script>
    import BasketItem from './BasketItem.vue';
    import ConfirmModal from '../../../components/shared/ConfirmModal.vue';

    export default {
        name: 'basket-item-list',
        components: { BasketItem, ConfirmModal },
        computed: {
            //----- Basic getters from store values
            items: function () { return this.$store.state.items.items; }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Item List', outcome: 'Created' })
        },
    }
</script>