<template>
    <div>
        <div class="input-group">
            <input type="text" name="search" class="form-control" id="searchBox"
                   :value="postcode"
                   @input="onInput"
                   placeholder="Enter Postcode"
                   v-on:keyup="enterSearch"
                   v-on:change="clearResults"
                   @blur="$emit('blur')" />
            <div class="input-group-append">
                <button class="btn btn-success" type="button"
                        v-on:submit.prevent
                        v-on:click="findAddress">
                    Search
                </button>
            </div>
        </div>
        <div class="pt-3" v-if="addresses && addresses.length">
            <select class="form-control w-100" v-model="selectedOption">
                <option value="" selected="selected">Select Address</option>
                <option v-for="(item) in addresses" :value="item.Id" :key="item.Id">{{item.Text}}, {{item.Description}}</option>
            </select>
        </div>
        <div class="text-danger" id="errorMessage"></div>
    </div>
</template>

<script>
    const $ = window.$;
    export default {
        name: 'postcodefinder',
        model: {
            prop: 'prePopulate',
            event: 'input'
        },
        props: {
            countryCode: String,
            prePopulate: String,
            addressKey: String,
        },
        data: function () {
            return {
                postcode: this.prePopulate,
                searchData: this.prePopulate,
                addresses: [],
                Key: this.addressKey,
                IsMiddleware: false,
                Origin: "",
                Countries: this.countryCode.startsWith("GB") ? 'GB' : this.countryCode,
                Limit: "10",
                Language: "en-gb",
                url: 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws',
                retrieveurl: 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws',
                newAddressKey: '',
                selectedOption: '',
            }
        },
        watch: {
            countryCode: function () {
                this.Countries = this.countryCode.startsWith("GB") ? 'GB' : this.countryCode;
            },
            prePopulate: function (value) {
                this.postcode = value;
                this.searchData = value;

                this.$emit('postcode-change', value);
            },
            selectedOption: function () {
                if (!this.selectedOption)
                    return;

                this.selectAddress(this.selectedOption);
            },
        },
        methods: {
            clearResults: function () {
                this.addresses = [];
            },
            showError: function (message) {
                var error = document.getElementById("errorMessage");
                error.innerText = message;
                error.style.display = "block";
                setTimeout(function () {
                    error.style.display = "none";
                }, 10000)
            },

            selectAddress: function (addressId) {
                let address = this.addresses.filter(obj => {
                    return obj.Id === addressId;
                });

                if (!address || !address[0].Type)
                    return;

                if (address[0].Type === 'Address') {
                    this.retrieveAddress(addressId);
                } else {
                    this.findAddress(addressId);
                }
            },

            retrieveAddress: function (addressId) {
                var self = this;
                var Field1Format = "";
                var params = '';

                var key = this.Key !== '' ? this.Key : this.newAddressKey

                params += "&Key=" + encodeURIComponent(key);
                params += "&Id=" + encodeURIComponent(addressId);
                params += "&Field1Format=" + encodeURIComponent(Field1Format);

                var http = new XMLHttpRequest();
                http.open('POST', this.retrieveurl, true);
                http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                http.onreadystatechange = function () {
                    if (http.readyState == 4 && http.status == 200) {
                        var response = JSON.parse(http.responseText);

                        if (response.Items.length == 0) {
                            self.showError("Sorry, there were no results");
                        }
                        else if (response.Items.length == 1 && typeof (response.Items[0].Error) != "undefined") {
                            self.showError('Sorry, it looks like there was an error searching for your address, please try again or enter it manually.');
                        }
                        else {
                            var res = response.Items[0];
                            self.$emit('address-selected', res);
                        }
                    }
                }
                http.send(params);
            },

            enterSearch: function (e) {
                e.preventDefault();

                this.addresses.length = 0;
                if (e.keyCode == 13) {
                    this.findAddress();
                }

                return false;
            },

            findAddress: function (addressId) {
                var self = this;
                this.selectedOption = '';

                var Text = document.getElementById("searchBox").value;

                if (Text === "") {
                    self.showError("Please enter an address");
                    return;
                }

                var key = this.Key !== '' ? this.Key : this.newAddressKey

                var params = '';
                params += "&Key=" + encodeURIComponent(key);
                params += "&Text=" + encodeURIComponent(Text);
                params += "&IsMiddleware=" + encodeURIComponent(this.IsMiddleware);
                params += "&Container=" + encodeURIComponent(addressId);
                params += "&Origin=" + encodeURIComponent(this.Origin);
                params += "&Countries=" + encodeURIComponent(this.Countries);
                params += "&Limit=" + encodeURIComponent(this.Limit);
                params += "&Language=" + encodeURIComponent(this.Language);

                var http = new XMLHttpRequest();
                http.open('POST', this.url, true);
                http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                http.onreadystatechange = function () {
                    if (http.readyState == 4 && http.status == 200) {
                        var response = JSON.parse(http.responseText);

                        if (response.Items.length === 0) {
                            self.showError("Sorry, there were no results");
                        }
                        else if (response.Items.length === 1 && typeof (response.Items[0].Error) != "undefined") {
                            self.showError(response.Items[0].Description);
                        }
                        else if (response.Items.length === 1) {
                            if (response.Items[0].Type !== 'Address') {
                                // Not an address so drill down further
                                self.findAddress(response.Items[0].Id);
                            } else {
                                // A single address found so select this by default
                                self.retrieveAddress(response.Items[0].Id);
                            }
                        }
                        else {
                            // Multiple results so display them in the list
                            self.addresses = response.Items;

                        }
                    }
                }
                http.send(params);
            },
            onInput($event) {
                this.$emit('input', $event.target.value);
            }
        },
        created() {
            var self = this;
            $.getJSON('/en-gb/api/addressapi/getaddresskey', function (result) {
                self.newAddressKey = result.key
            })
        }
    }
</script>