<template>
    <div class="card card-gray mb-3">
        <div class="card-header text-center">
            <div class="h2 mb-0">Why are you using us?</div>
        </div>
        <div class="card-body">
            <div class="form-group"> 
                <label for="essentials-reasonforusingus"></label>
                <validation-provider :rules="{ required: true }" v-slot="{ errors }" vid="essentials-reasonforusingus" name="Reason for using us">
                    <select-2 id="essentials-reasonforusingus" class="form-control" placeholder="Please Select" data-minimum-results-for-search="Infinity"
                              :options="reasonsForUsingUs"
                              :default-value-on-options-update="false"
                              v-model.number="reasonForUsingUs">
                    </select-2>
                    <div class="text-danger">{{ errors[0] }}</div>
                </validation-provider>
            </div>
            <div class="form-group universitydiv"
                 v-if="showUniversities">
                <label for="essentials-university">What University are you sending to?</label>
                <validation-provider :rules="{ required: true }" v-slot="{ errors }" vid="essentials-university" name="University">
                    <select-2 id="essentials-university" name="university" class="form-control" placeholder="Please Select" data-minimum-results-for-search="Infinity"
                              :options="universities"
                              :default-value-on-options-update="false"
                              :key="university"
                              v-model.number="university">
                    </select-2>
                    <div class="text-danger">{{ errors[0] }}</div>
                </validation-provider>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$;
    import Select2 from '../../../components/select-2/Select2.vue';
    import { ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', { ...required, message: 'The {_field_} is required' });

    export default {
        name: "reason-for-use",
        components: {
            Select2,
            ValidationProvider
        },
        computed: {
            reasonForUsingUs: {
                get() { return this.$store.state.essentials.reasonForUsingUs },
                set(value) { this.$store.commit('setReasonForUsingUs', value) }
            },
            university: {
                get() { return this.$store.state.essentials.university },
                set(value) { this.$store.commit('setUniversity', value) }
            },

            reasonsForUsingUs: function () { return this.$store.state.essentials.reasonsForUsingUs; },
            universities: {
                get() {
                    let options = this.$store.state.essentials.universities ?? [];

                    if (options.length > 0)
                        options = [{ id: 0, text: 'Other' }, ...options];

                    return options;
                }
            },
            showUniversities: {
                get() { return this.reasonForUsingUs == 7 && this.universities.length > 0 }
            }
        },
        watch: {
            reasonForUsingUs: function (newValue) {
                if (!this.showUniversities)
                    this.university = null;
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Essentials Why Are You Using Us', outcome: 'Created'})
        },
    }
</script>