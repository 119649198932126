<template>
    <div>
        <button type="button" class="btn btn-fb btn-block" v-on:click="buttonClicked()">
            <img :src="icon" alt="Facebook Logo" /> Sign in with Facebook
        </button>
    </div>
</template>
<script>
    const $ = window.$; 

    export default {
        name: 'facebooklogin',
        props: {
            appId: String,
            version: {
                type: String,
                default: 'v4.0'
            },
            logoutLabel: {
                type: String,
                default: 'Log out from Facebook'
            },
            loginLabel: {
                type: String,
                default: 'Log in to Facebook'
            },
            loginOptions: {
                type: Object,
                default: function () {
                    return {
                        scope: 'email'
                    }
                }
            }
        },
        data: function () {
            return {
                isWorking: false,
                isConnected: false,
                isLoaded: false,
                icon: 'https://media.sendmybag.com/assets/d176e539-4dd9-4396-852b-e848d633271e/Signature.svg',
                name: '',
                email: '',
                provideruserid: 0,
                facebooktoken: '',
                requestVerificationToken: null,
                params: [],
                userid:''
            }
        },
        mounted: function () {
            // this.isWorking = true;
            //// this.icon = 'https://media.sendmybag.com/assets/d176e539-4dd9-4396-852b-e848d633271e/Signature.svg';

            // getFbSdk(this.appid, this.version)
            //     .then(getFbLoginStatus)
            //     .then(response => {
            //         if (response.status === 'connected') {

            //             this.personalID = response.authResponse.userID;
            //             this.facebooktoken = response.authResponse.accessToken;
            //             this.isConnected = true;
            //         }

            //         this.isWorking = false;



            //         if (this.isConnected) this.getUserData();
            //     });
        },
        computed: {
            getButtonText: function () {
                switch (this.isConnected) {
                    case true:
                        return this.logoutLabel;
                    case false:
                        return this.loginLabel;
                    default:
                        return 'this is default';
                }
            }
        },
        methods: {
            getUserData: function () {
                return new Promise(resolve => {

                    window.FB.api('/me', 'GET', { fields: 'id,name,email,first_name,last_name, picture' },
                        userInformation => {
                            this.personalID = userInformation.id;
                            this.email = userInformation.email;
                            this.name = userInformation.name;
                            //this.icon = userInformation.picture.data.url;
                             
                            var token = "";
                            var tokens = document.getElementsByName("__RequestVerificationToken");
                            for (var i = 0; i < tokens.length; i++) {

                                token = tokens[i].value;

                            }
                            //if (document.getElementById("registerpage") != null || $('#registermodalbody').is(':visible')) {
                            //    this.requestVerificationToken = document.getElementById("registerform").elements.namedItem("__RequestVerificationToken").value;
                            //}
                            //else if (document.getElementById("loginpage") != null || $('#loginmodalbody').is(':visible')) {
                            //    this.requestVerificationToken = document.getElementById("loginform").elements.namedItem("__RequestVerificationToken").value;
                            //}
                            if (this.facebooktoken != null) {
                                 
                                if (this.isLoaded) {
                                    this.params = {
                                        Email: this.email,
                                        Name: this.name,
                                        Password: 'none',
                                        FacebookToken: this.facebooktoken,
                                        ProviderUserId: this.provideruserid,
                                        __RequestVerificationToken: token
                                    };
                                      

                                    $.post('/en-gb/api/accountapi/login', this.params, function () {
                                         
                                        window.location.href = '';
                                    });
                                }
                            }
                        });
                    return resolve;
                });

            },

            buttonClicked: function () {
                this.isLoaded = true;
                this.isWorking = true;
                this.getFbSdk(this.appId, this.version)
                    .then(this.getFbLoginStatus)
                    .then(response => { 
                        if (response.status === 'connected') {
                            this.provideruserid = response.authResponse.userID;
                            this.facebooktoken = response.authResponse.accessToken;
                            this.isConnected = true;
                        }
                        if (this.isConnected) { 
                            this.getUserData();
                        }
                        this.$emit('click');

                        if (this.isConnected) {
                             
                            this.logout();
                        } else { 
                            this.login();
                        }
                    });



            },
            login: function () {
                this.isWorking = true;

                this.isLoaded = true;
                this.fbLogin(this.loginOptions)
                    .then(response => { 
                        this.provideruserid = response.authResponse.userID;
                        this.facebooktoken = response.authResponse.accessToken;
                        if (response.status === 'connected') { 
                            this.isConnected = true;
                            if (this.isConnected) {
                                this.getUserData();
                            }
                        } else { 
                            this.isConnected = false;
                        }
                        this.isWorking = false; 
                        this.$emit('login', {
                            response,
                            FB: window.FB
                        })
                    })
            },
            logout: function () {
                this.isWorking = true;
                this.fbLogout()
                    .then(response => {
                        this.isWorking = false;
                        this.isConnected = false;
                        this.userid = '';
                        this.facebooktoken = '';
                        this.$emit('logout', response);
                        this.provideruserid = '';
                        this.isConnected = false;
                        //  this.icon= 'https://media.sendmybag.com/assets/d176e539-4dd9-4396-852b-e848d633271e/Signature.svg';
                    }
                    )
            },
             initFbSdk: function (appId, version, options) {
                return new Promise(resolve => { 
                    window.fbAsyncInit = function () { 
                        const defaults = { cookie: true, xfbml: true }
                        // options = { ...defaults, ...options }
                        // window.FB.init(options)
                       this.FB.init({
                            appId,
                            xfbml: false,
                            version,
                            cookie: true
                       });
                         
                        this.FB.AppEvents.logPageView();
                        resolve()
                    };
                    /* eslint-disable */
                    (function (d, s, id) {

                        const fjs = d.getElementsByTagName(s)[0]
                        if (d.getElementById(id)) { return; }

                        const js = d.createElement(s); js.id = id

                        js.src = '//connect.facebook.net/en_US/sdk.js'
                        fjs.parentNode.insertBefore(js, fjs)
                    }(document, 'script', 'facebook-jssdk'))
                    /* eslint-enable */
                });
           },
            getFbSdk: function (appId, version, options) {
                 
                return new Promise( resolve => {
                    if (window.FB) {
                         
                        resolve(window.FB)
                    } else { 
                        this.initFbSdk(appId, version, options)
                        resolve(window.FB)
                    }
                })
            },

            fbLogin: function (options) { 
                return new Promise(resolve => {
                    window.FB.login(response => resolve(response), options) 
                });
            },

            getFbLoginStatus: function () { 
                return new Promise(resolve => {
                    window.FB.getLoginStatus(response => resolve(response)) 
                })
            },

            fbLogout: function () { 
                return new Promise(resolve => {
                    window.FB.logout(response => resolve(response)) 
                });
            }
        }

        
    }
</script>