<template>
    <div class="shimmer-items-wrapper">
        <slot>
            <!--The default content if none is supplied-->
            <div class="shimmer-text-line-w25"></div>
            <div class="shimmer-text-line"></div>
            <div class="shimmer-text-line"></div>
        </slot>
    </div>
</template>

<script>
    export default {
        name: 'shimmer-effect-items-wrapper'
    };
</script>