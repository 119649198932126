<template>
    <div class="text-center">
        <div class="mt-2 ">
            <h2>{{ header }}</h2>
        </div>
        <div class="mt-2">
            <strong>{{ address.contactName }}</strong>
        </div>
        <div>
            {{ address.companyname }}
        </div>
        <div>
            {{ address.line1 }}
        </div>
        <div>
            {{ address.line2 }}
        </div>
        <div>
            {{ address.line3 }}
        </div>
        <div>
            {{address.cityName}}, {{address.postcode}}
        </div>
        <div>
            {{address.country}}
        </div>
    </div>
</template>
<script>
    export default {
        name: 'address-details',
        props: {
            address: Object,
            header: String
        }
    }
</script>

