const $ = window.$;

export default {
    state: {
        data: {}
    },

    mutations: {
        setSummary: function (state, payload) {
            state.data = payload;
        },
    },

    actions: {
        updateSummary: function ({ commit }) {
            //console.log('update Summary');

            var jqxhr = $.getJSON('/en-gb/api/basketsummaryapi/getsummary/', function (result) {
                //console.log("update Summary Complete:", result);
                if (result.success) {
                    commit('setSummary', result.summary);
                }
            });
            /*
                    jqxhr.fail(function () {
                    });
            
                    jqxhr.always(function () {
                    });*/
        },
        updateSummaryWithOptions: function ({ commit }, payload) {
            //console.log('update Summary with options', payload);

            var jqxhr = $.getJSON('/en-gb/api/basketsummaryapi/getsummarywithoptions/', payload, function (result) {
                if (result.success) {
                    commit('setSummary', result.summary);
                }
            });
        },
    }
}