<template>
    <div>
        <div class="alert border-light mt-4">
            <i class="fas fa-info-circle nav-hover text-blue float-right" v-on:click.stop.prevent="showModel('infomodal')"></i>
            <div class="row p-3">
                <div class="col-12 text-center">
                    <div>Estimated <strong>{{ serviceName }}</strong></div>
                    <div>Delivery Date</div>

                    <shimmer-effect-items-wrapper v-if="isLoading">
                        <div class="h2 mt-3">
                            <div class="shimmer-text-line-w100" style="height:1.75rem"></div>
                        </div>
                    </shimmer-effect-items-wrapper>
                    <div class="h2 mt-3" v-else>{{ dateString }}</div>
                </div>
            </div>
        </div>
        <div v-show="!isCourierResult" class="alert alert-warning text-danger mt-4" role="alert">
            <div class="row p-3">
                <div class="col-12 col-md-1 text-center text-md-right pb-2">
                    <i class="fas fa-exclamation-circle fa-lg"></i>
                </div>
                <div class="col-12 col-md-11 text-center text-md-left">
                    <div class="mb-2">
                        <!-- Why is this hard coded in here should this come from CMS ? -->
                        <p>We have been unable to perform a postcode to postcode transit time check.</p>
                        <p>The estimated delivery date displayed assumes capital to capital. If you are sending outside of the capital city please be aware delivery may take longer, you can contact us for more information.</p>
                        <p>We will attempt a postcode to postcode check again when your labels generate, if your estimated transit time updates you will be notified by email.</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="showPublicHolidayMessages" class="alert alert-warning text-danger mt-4" role="alert">
            <div class="row p-3">
                <div class="col-12 col-md-1 text-center text-md-right pb-2">
                    <i class="fas fa-exclamation-circle fa-lg"></i>
                </div>
                <div class="col-12 col-md-11 text-center text-md-left">
                    <div class="mb-2">Please be aware that the following dates are public holidays and could potentially affect your transit times:</div>
                    <div class="font-weight-bold"
                         v-for="holiday in publicHolidays"
                         v-bind:key="holiday">{{ holiday.key }} - {{ holiday.value }}
                    </div>
                </div>
            </div>
        </div>

        <generic-modal id="infomodal" :title="estimatedDateTitle" :content="estimatedDateMessage"></generic-modal>
    </div>
</template>
<script>
    const $ = window.$;
    import moment from 'moment';
    import ShimmerEffectItemsWrapper from '../../../components/shared/ShimmerEffectItemsWrapper.vue';

    export default {
        name: 'estimated-delivery-date',
        components: {
            ShimmerEffectItemsWrapper
        },
        props: {
            min: String,
            max: String,
            serviceName: String,
            publicHolidays: Array,
            isCourierResult: Boolean,
            isLoading: Boolean,
            estimatedDateMessage: String
        },
        computed: {
            showPublicHolidayMessages: function () {
                return this.publicHolidays && this.publicHolidays.length > 0;
            },
            dateString: function () {
                let from = moment(this.min).format('Do MMMM YYYY');
                let to = moment(this.max).format('Do MMMM YYYY');

                if (from !== to)
                    return from + ' / ' + to;

                return from;
            },
            estimatedDateTitle: function () {
                return 'Estimated ' + this.serviceName + ' Delivery Date';
            },
        },
        methods: {
            showModel: function (model) {
                $('#' + model).modal('show');
            },
        },
        created() {
            this.$successLog({ description: 'Component - Basket Date Estimated Delivery Component', outcome: 'Created' })
        }
    }
</script>

