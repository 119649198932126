<template>
    <div class="row half-gutters my-1">
        <!--<div class="col-2 text-center"><i class="fa-2x fa-fw text-primary fa-lg mt-2" v-bind:class="icon"></i></div>-->
        <div class="col-8" name="item">
            <div class="item-description"><i class="fas fa-circle"></i>{{ name }}</div>

            <div v-if="item.overWeightCharge || item.overSizeCharge || item.volumetricOverWeightCharge" class="item-includes item-includes-label">includes:</div>
            <div v-if="item.overWeightCharge" class="item-includes">{{ item.overWeightCharge }} {{ smbI18n.checkout.OverWeightCharge }} <a href="/allowances" target="_blank"><i class="fas fa-external-link"></i></a></div>
            <div v-if="item.overSizeCharge" class="item-includes">{{ item.overSizeCharge }} {{ oversizeChargeDescription }} <a href="/allowances" target="_blank"><i class="fas fa-external-link"></i></a></div>
            <div v-if="item.volumetricOverWeightCharge" class="item-includes">{{ item.volumetricOverWeightCharge }} {{ smbI18n.checkout.VolumetricOverWeightCharge }} <a href="/allowances" target="_blank"><i class="fas fa-external-link"></i></a></div>
        </div>
        <div class="col-4 text-right text-nowrap">
            <div v-text="item.totalPrice"> </div>
        </div>
    </div>
</template>
<script>
    //import { mapGetters } from 'vuex';

    export default {
        name: 'basket-summary-item',
        props: {
            item: Object
        },
        methods: {
        },
        computed: {
            itemTypes: function () { return this.$store.state.items.itemTypes; },
            oversizeChargeDescription: function () { return this.$store.state.summary.data.oversizeChargeDescription; },

            name: function () {
                let result = this.typeName;
                if (this.item.name)
                    return result + ' - ' + this.item.name;

                return result;
            },
            //icon: function () {
            //    switch (this.item.type) {
            //        case 1:
            //            return 'fad fa-suitcase';
            //        case 2:
            //            return 'fas fa-box-open';
            //        case 3:
            //            return 'fas fa-backpack';
            //        case 4:
            //            return 'fas fa-backpack';
            //        case 5:
            //            return 'fad fa-golf-club';
            //        case 6:
            //            return 'fas fa-box-open';
            //        case 7:
            //            return 'fad fa-bicycle';
            //        case 8:
            //            return 'fas fa-saxophone';
            //        case 9:
            //            return 'fad fa-suitcase';
            //    }

            //    return '';
            //}
            typeName: function () {
                const _this = this;
                let itemType = this.itemTypes.filter(obj => {
                    return obj.id === _this.item.type;
                });

                if (itemType) {
                    return itemType[0].text;
                }

                return '';
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Summary Item', outcome: 'Created' });
        }
    }
</script>

