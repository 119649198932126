 <template>
    <select v-bind:name="name"  autocomplete='off' class="form-control"><slot></slot></select>
</template>
<script>

    import  select2Mixin  from '../mixins/select-2-mixin'
    const $ = window.$;
    export default { 
            mixins: [select2Mixin],
          //  template: '<select v-bind:name="name" class="form-control"><slot></slot></select>',
            mounted: function () {
                var vm = this;
                $(this.$el)
                    // init select2
                    .select2({
                        data: this.options,
                        placeholder: this.placeholder,
                        matcher: this.customMatcher,
                        templateResult: this.formatState,
                        templateSelection: this.formatStateSelection,
                        dropdownAutoWidth: 'true'
                    })
                    .val(this.value)
                    .trigger('change')
                    // emit event on change.
                    .on('change', function () {
                        vm.$emit('input', this.value);
                    });
            },
            methods: {
                optionsWatcher: function (options) {
                    // update options
                    $(this.$el).empty().select2({
                        data: options,
                        placeholder: this.placeholder,
                        matcher: this.customMatcher,
                        templateResult: this.formatState,
                        templateSelection: this.formatStateSelection,
                        dropdownAutoWidth: 'true'
                    });

                    $(this.$el)
                        .val(this.value)
                        .trigger('change');
                },

                customMatcher: function (params, data) {
                    // If there are no search terms, return all of the data           
                    if ($.trim(params.term) === '') {
                        return data;
                    }

                    const termUpper = params.term.toUpperCase();
                    let isMatch = data.text.toUpperCase().indexOf(termUpper) >= 0
                        || data.id.toUpperCase().indexOf(termUpper) >= 0
                        || data.country.toUpperCase().indexOf(termUpper) >= 0;

                    if (!isMatch && data.alt && data.alt.toUpperCase().indexOf(termUpper) >= 0) {
                        isMatch = true;
                    }

                    // If we matched add the item to the result list
                    if (isMatch) {
                        var modifiedData = $.extend({}, data, true);

                        // You can return modified objects from here
                        // This includes matching the `children` how you want in nested data sets
                        return modifiedData;
                    }

                    // Return `null` if the term should not be displayed
                    return null;
                },
                formatState: function (opt) {
                    if (!opt.id) {
                        return opt.text;//$(opt.element).attr('data-country') + ' ' + $(opt.element).attr('data-code');
                    }
                    var optimage = opt.id;
                    var optcountry = opt.country;
                    var optcode = opt.text;
                    if (!optimage) {
                        return optcountry + ' ' + optcode;
                    } else {
                        var $opt = $(
                            '<div><span class="flag flag-' + optimage.toLowerCase() + ' mx-2"></span>' + optcountry + '<span class="address-phone-country-code">' + optcode + '</span></div>'
                        );
                        return $opt;
                    }
                },
                formatStateSelection: function (opt) {
                    if (!opt.id) {
                        return opt.text;
                    }
                    var optimage = opt.id;
                    var optcode = opt.text;
                    if (!optimage) {
                        return optcode;
                    } else {
                        var $opt = $(
                            '<div><span class="flag flag-' + optimage.toLowerCase() + ' mr-2"></span> ' + optcode + '</div>'
                        );
                        return $opt;
                    }
                }

            } 

    }
</script>

 