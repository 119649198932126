<template>
    <div>
        <h1>{{smbData.CMS.Addresses.Headings.OriginAddressHeading}}</h1>
        <saved-addresses v-model="originId" :addresses="originSavedAddresses"></saved-addresses>
        <journey-address
                         v-model="originAddress"
                         :addressFieldFlags="addressFieldFlags"
                         :addressTypes="addressTypes"
                         :phoneNumberCountries="phoneNumberCountries"
                         :countryDetails="originCountry"
                         :has-selected-saved-address="hasSelectedSavedAddress"
                         @selected-saved-address="savedAddressSelected"
                         ref="journeyAddress"></journey-address>
    </div>
</template>
<script>
    import JourneyAddress from './addresses/JourneyAddress.vue';
    import SavedAddresses from './addresses/SavedAddresses.vue';
    import { mapState } from 'vuex';

    export default {
        name: 'basket-step2',
        components: {
            JourneyAddress,
            SavedAddresses
        },
        data: function () {
            return {
                hasSelectedSavedAddress: false
            };
        },
        computed: {
            ...mapState({
                addressFieldFlags: state => state.addresses.addressFieldFlags,
                addressTypes: state => state.addresses.addressTypes,
                phoneNumberCountries: state => state.addresses.phoneNumberCountries,
                originCountry: state => state.addresses.originCountry,
                originSavedAddresses: state => state.addresses.originSavedAddresses,
            }),

            originAddress: {
                get: function () { return this.$store.state.addresses.origin },
                set: function (value) { this.$store.commit('setOrigin', value) }
            },

            originId: {
                get: function () { return this.$store.state.addresses.origin.id },
                set: function (value) {
                    let address = this.originSavedAddresses.filter(obj => {
                        return obj.id === value;
                    });

                    if (address) {
                        this.hasSelectedSavedAddress = true;
                        this.$store.commit('setOrigin', address[0]);
                    }
                }
            }
        },
        methods: {
            setGetOriginVisibleFieldValues: function () {
                const value = this.$refs.journeyAddress.getShownAddressFieldValues;
                this.$store.commit('setGetOriginVisibleFieldValues', value);
            },
            savedAddressSelected: function () {
                this.hasSelectedSavedAddress = false;
            }
        },
        metaInfo() {
            return {
                title: window.smbData.CMS.Addresses.Meta.Title
            };
        },
        created() {
            this.$successLog({ description: 'Component - Basket Step 2', outcome: 'Created' });
            this.$store.dispatch('updateSummary');
        },
        mounted() {
            this.setGetOriginVisibleFieldValues();
            $(window).scrollTop(0);
        }
    }
</script>
