import { extend } from 'vee-validate';

// Add extensions to the vee-validate library in here

extend('creditCardNumber', {
    validate(value) {
        const cardNumber = value.replace(/\s+/g, '');

        // test numeric and length between 12 and 19
        if (!/^\d{12,19}$/.test(cardNumber)) {
            return false;
        }

        // luhn check
        var sum = 0;
        var digit = 0;
        var addend = 0;
        var timesTwo = false;

        for (var i = cardNumber.length - 1; i >= 0; i--) {
            digit = parseInt(cardNumber.substring(i, i + 1), 10);
            if (timesTwo) {
                addend = digit * 2;
                if (addend > 9) {
                    addend -= 9;
                }
            } else {
                addend = digit;
            }
            sum += addend;
            timesTwo = !timesTwo;
        }

        var modulus = sum % 10;
        if (modulus !== 0) {
            return false;
        }

        return true;
    }
});

extend('creditCardType', {
    validate(value) {
        if (value !== undefined && value !== null) {
            const cardNumber = value.replace(/\s+/g, '');

            //first check for MasterCard
            if (/^(?:5[1-6]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)/.test(cardNumber)) {
                return true;//'mastercard';
            }
            //then check for Visa
            else if (/^4/.test(cardNumber)) {
                return true;//'visa';
            }
            //then check for AmEx
            else if (/^3[47]/.test(cardNumber)) {
                return 'We don\'t currently accept AMEX, please use either Visa or MasterCard.';//'amex';
            }
            //then check for Maestro
            //else if (/^(50|5[6-9]|6)/.test(cardNumber)) {
            //    return 'SWITCH';//'maestro';
            //}
            ////check for other cards that we don't accept, but we want to show
            //else if (/^3(?:0[0-5]|[68][0-9])[0-9]/.test(cardNumber)) { // diners
            //    return 'DINERS';
            //}
            //else if (/^6(?:011|5[0-9]{2})[0-9]/.test(cardNumber)) { // discover
            //    return 'DISCOVER';
            //}
            //else if (/^(?:2131|1800|35\d{3})/.test(cardNumber)) { // jcb
            //    return 'JCB';
            //}
            //else if (/^(62|88)/.test(cardNumber)) { // union pay
            //    return 'UNIONPAY';
            //}
            //else if (cardNumber.length > 5) {
            //    return 'UNKNOWN';
            //}

            return 'We don\'t currently accept this card type, please use either Visa or MasterCard.';
        }
    }
})

extend('cardHolderName', {
    validate(value) {
        // test for undefined
        if (!value) {
            return '{_field_} is required';
        }

        // test white space only
        if (!value.trim()) {
            return '{_field_} is required';
        }

        // test ISO/IEC 8859-1 characters between 1 and 100
        if (!/^[\u0020-\u007E\u00A0-\u00FF]{1,100}$/.test(value)) {
            return '{_field_} contains invalid characters';
        }

        return true;
    }
});

extend('creditCardCvn', {
    validate(value) {
        // test numeric length 3
        if (!/^\d{3}$/.test(value)) {
            return false;
        }

        return true;
    }
});
