<template>
    <div
        v-if="messagesToDisplay && messagesToDisplay.length"
        class="alert alert-warning text-danger"
        role="alert"
    >
        <div
            class="row p-2"
            v-for="message in messagesToDisplay"
            v-bind:key="message"
        >
            <div
                class="col-12 col-md-1 text-center text-md-right p-0 mb-3 md-mb-0"
            >
                <i :class="[icon, iconPadding]"></i>
            </div>
            <div
                class="col-12 col-md-11 text-center text-md-left"
                v-html="message"
            ></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'warningcard',
    props: {
        messages: [],
        icon: {
            default: 'fas fa-exclamation-circle fa-lg',
            type: String,
        },
        iconPadding: {
            default: 'pt-1',
            type: String,
        },
    },
    computed: {
        messagesToDisplay: function () {
            if (!(this.messages && this.messages.length)) {
                return null
            }

            return this.messages.filter(function (el) {
                return el && el.length
            })
        },
    },
}
</script>
