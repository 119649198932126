<template>
    <div>
        <div class="form-group row" v-if="showTimeSlots">
            <div class="col-12">
                <validation-provider :rules="{ required: true }" v-slot="{ errors }" name="Time slot">
                    <!--SMB3-222: Should this label content come from CMS?-->
                    <label for="date-timeslot">As standard collection times are 9am-6pm. Would you like to take part in our collection time slot trials? If yes, pick one below</label>
                    <select-2 id="date-timeslot" name="timeslot" class="form-control" style="width:100%;" placeholder="Select time" data-minimum-results-for-search="Infinity"
                              v-model="timeSlot"
                              :options="availableTimeSlots"
                              :default-value-on-options-update="false">
                    </select-2>
                    <div class="text-danger">{{ errors[0] }}</div>
                </validation-provider>
            </div>
        </div>
        <input v-else type="hidden" id="date-timeslot" name="timeslot" v-model="timeSlot" />

        <generic-modal id="timeslotmodal" :title="smbData.CMS.Dates.DateDetail.Header" :content="smbData.CMS.Dates.DateDetail.Content" :hideCloseIcon="true" :closeButtonText="smbI18n.checkout.IAgree"></generic-modal>
    </div>
</template>
<script>
    const $ = window.$;
    import Select2 from '../../../components/select-2/Select2.vue';
    import { ValidationProvider, extend } from 'vee-validate';
    import { required  } from 'vee-validate/dist/rules';

    extend('required', { ...required, message: 'The {_field_} is required' });

    export default {
        components: {
            Select2,
            ValidationProvider,
        },
        name: 'time-slot-select',
        props: {
            value: String,
            availableTimeSlots: Array,
        },
        data: function () {
            return {
                hasShownTimeSlotPopup: false
            }
        },
        computed: {
            timeSlot: {
                get() { return this.value },
                set(value) {
                    if (this.value !== value) {
                        this.$emit('input', value);

                        if (!this.hasShownTimeSlotPopup && value !== '' && value !== '09:00-18:00') {
                            this.hasShownTimeSlotPopup = true;
                            $('#timeslotmodal').modal({ backdrop: 'static', keyboard: false });
                        }
                    }
                }
            },
            showTimeSlots: function () {
                return this.availableTimeSlots.length > 1;
            }
        },
        methods: {
        },
        created() {
            this.$successLog({ description: 'Component - Basket Date Time Slot Component', outcome: 'Created' });

            if (this.availableTimeSlots.length === 1) {
                this.timeSlot = this.availableTimeSlots[0].id;
            }
        }
    }
</script>

