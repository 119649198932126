<template>
    <div class="card-body" v-if="totalPrice">
        <div class="row">
            <div class="col-8 font-weight-bold">Total</div>
            <div class="col-4 text-right font-weight-bold text-nowrap" v-text="totalPriceDisplay"></div>
        </div>
        <div v-if="pointsUsed > 0" class="row">
            <div class="col-8">{{ pointsUsed }} Points Used</div>
            <div class="col-4 text-right text-nowrap">-{{ valueOfPointsUsed }}</div>
        </div>
        <div v-if="creditUsed" class="row">
            <div class="col-8">Credit Used</div>
            <div class="col-4 text-right text-nowrap">-{{ creditUsed }}</div>
        </div>
        <div v-if="totalPrice !== totalToPay" class="row">
            <div class="col-8 font-weight-bold">Total to Pay</div>
            <div class="col-4 text-right font-weight-bold text-nowrap" v-text="totalToPayDisplay"></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'basket-summary-total',
        computed: {
            totalPrice: function () {
                return this.$store.state.summary.data.totalPrice;
            },
            totalToPay: function () {
                return this.$store.state.summary.data.totalToPay;
            },
            valueOfPointsUsed: function () {
                return this.$store.state.summary.data.valueOfPointsUsed;
            },
            pointsUsed: function () {
                return this.$store.state.summary.data.pointsUsed;
            },
            creditUsed: function () {
                return this.$store.state.summary.data.creditUsed;
            },
            currencyCode: function () {
                return this.$store.state.summary.data.currencyCode;
            },
            totalPriceDisplay: function () {
                return `${this.currencyCode} ${this.totalPrice}`;
            },
            totalToPayDisplay: function () {
                return `${this.currencyCode} ${this.totalToPay}`;
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Summary Total', outcome: 'Created' });
        }
    }
</script>
