const numberOnlyInput = {
    bind: (el) => {
        el.handler = () => {
            const regEx = new RegExp(/[^\d]/, 'ig');
            if (regEx.test(el.value)) {
                el.value = el.value.replace(regEx, '');

                const inputEvent = new Event('input');
                el.dispatchEvent(inputEvent);
            }
        }

        el.addEventListener('input', el.handler);
    },
    unbind: (el) => {
        el.removeEventListener('input', el.handler);
    }
};

export {
    numberOnlyInput
};