<template>
    <div>
        <basket-login v-if="!isUserSignedIn"></basket-login>
        <basket-confirmation v-else></basket-confirmation>
    </div>
</template>
<script>
    import BasketLogin from './confirmation/BasketLogin.vue';
    import BasketConfirmation from './confirmation/BasketConfirmation.vue';

    export default {
        name: 'basket-step8',
        components: {
            BasketLogin,
            BasketConfirmation
        },
        computed: {
            isUserSignedIn: {
                get() { return this.$store.state.isUserSignedIn; }
            }
        },
        metaInfo() {
            return {
                title: 'Confirm your order. Send My Bag'//window.smbData.CMS.Items.Meta.Title
            };
        },
        created() {
            this.$successLog({ description: 'Component - Basket Step 8', outcome: 'Created' });
            this.$store.dispatch('updateSummary');
        },
        mounted() {
            $(window).scrollTop(0);
        }
    }
</script>
