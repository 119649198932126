 <template>
     <div class="container-order-progress">
         <div class="container">
             <div class="row text-center">
                 <div class="col-3 p-0" v-bind:class="{ active: progressStep === 1, complete: progressStep > 1 }">
                     <div class="order-step-progress-bridge-over"></div>
                     <div class="order-progress-step">1</div>
                     <div>Items</div>
                 </div>
                 <div class="col-3 p-0" v-bind:class="{ active: progressStep === 2, complete: progressStep > 2 }">
                     <div class="order-step-progress-bridge"></div>
                     <div class="order-step-progress-bridge-over"></div>
                     <div class="order-progress-step">2</div>
                     <div>Addresses</div>
                 </div>
                 <div class="col-3 p-0" v-bind:class="{ active: progressStep === 3, complete: progressStep > 3 }">
                     <div class="order-step-progress-bridge"></div>
                     <div class="order-step-progress-bridge-over"></div>
                     <div class="order-progress-step">3</div>
                     <div>Dates</div>
                 </div>
                 <div class="col-3 p-0" v-bind:class="{ active: progressStep === 4, complete: progressStep > 4 }">
                     <div class="order-step-progress-bridge"></div>
                     <div class="order-progress-step">4</div>
                     <div>Essentials</div>
                 </div>
             </div>
         </div> 
     </div>

</template>
<script>
    export default {
        name: 'basketprogress', 
        computed: {
            progressStep: function () {
                const step = this.$store.state.step;

                if (step <= 1) {
                    return 1;
                } else if (step <= 5) {
                    return 2;
                } else if (step <= 7) {
                    return 3;
                } else {
                    return 4;
                }
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Progress', outcome: 'Created' })
        }
    }

</script>

 