const $ = window.$;
export default {
    props: {
        defaultOriginIso: String,
        defaultDestinationIso: String,
        isReturn: Boolean,
        postcodeText: String,
        collectionPostcodeText: String,
        destinationPostcodeText: String,
        ifAvailableInBrackets: String,
        originOnly: Boolean,
        isForDestination: { type: Boolean, default: false }
    },
    data: function () {
        return {
            originIso: this.defaultOriginIso,
            destinationIso: this.defaultDestinationIso,
            originCountries: [],
            destinationCountries: [],
            isLoadingOrigin: false,
            isLoadingDestination: false,
            isEnabledOrigin: true,
            isEnabledDestination: this.isForDestination ? false : true,
            latestRequestId: 0,
            alertMessages: [],
            originName: "",
            destinationName: "",
            regions: [],
            originRegionCode: "",
            destinationRegionCode: "",
            originRegionId: "",
            destinationRegionId: "",
            routeMessage: false
        };
    },
    methods: {
        originIsoChanged: function (iso) {

            if (iso === '')
                return

            this.originIso = iso;
            this.updateOnOriginChange();
            this.updateAlert();
        },
        updateOnOriginChange: function () {
            // US to US load regions
            if ((this.originIso === 'US' && this.destinationIso === 'US') && this.regions.length === 0)
                this.loadRegions('US')

            if (this.originOnly) {
                this.updateCustoms();
                return;
            }

            if (this.destinationIso === undefined || this.destinationIso === '' || this.destinationIso === 'blank') 
                this.isEnabledDestination = true;

            // For quote page; we need to know this has completed setting available destinations before updating the quote result
            const promise = this.loadCountryListsByOriginOnly(false);
            this.updateCustoms();

            return promise;
        },
        destinationIsoChanged: function (iso) {
            if (iso === '')
                return;

            this.destinationIso = iso;
            this.updateOnDestinationChange();
            this.updateAlert();
        },
        updateOnDestinationChange: function () {
            if ((this.originIso == 'US' && this.destinationIso == 'US') && this.regions.length == 0)
                this.loadRegions('US');

            if (this.originIso === undefined || this.originIso === '')
                this.loadCountryListsByDestinationOnly(false);
        },
        loadCountryListsByOriginOnly: function (updateOrigin) {
            let self = this;
            var params = {
                originIso: this.originIso,
            }

            return $.getJSON('/en-gb/api/addressapi/getcountrylistsbyorigin', params, function (result) {
                if(updateOrigin)
                    self.originCountries = result.originCountries

                self.destinationCountries = result.destinationCountries;
            })
        },
        loadCountryListsByDestinationOnly: function (updateDestination) {
            let self = this
            var params = {
                destinationIso: this.destinationIso,
            }

            $.getJSON('/en-gb/api/addressapi/getcountrylistsbydestination', params, function (result) {
                self.originCountries = result.originCountries
                if (updateDestination)
                    self.destinationCountries = result.destinationCountries;
            })
        },
        loadDefaultCountryCode: function () {
            let self = this
            $.getJSON('/en-gb/api/addressapi/getdefaultcountrycode', function (result) {
                self.originIso = result.countryCode;
            })
        },
        getCountriesJsonAsync: function () {
            let self = this
            $.getJSON('/en-gb/api/addressapi/getorigincountriesjsonasync', function (result) {
                self.originCountries = result;
                self.destinationCountries = result;
            })
        },

        loadCountryData: function () {

            this.isLoadingOrigin = true;
            this.isLoadingDestination = true;

            if (this.originIso !== '' && this.originIso != "blank" && (this.destinationIso === undefined || this.destinationIso === '')) {
                // Based on only the origin iso is known
                this.loadCountryListsByOriginOnly(true);

            }
            else if ((this.originIso === undefined || this.originIso === '') && this.destinationIso !== '') {
                // Based on only the destination iso is known
                this.loadCountryListsByDestinationOnly(true);
                
            } else if ((this.originIso !== '' && this.originIso != "blank" && this.originIso !== undefined)
                && (this.destinationIso !== '' || this.destinationIso !== undefined)) {
                // Based on both the origin and destination known
                if (this.isForDestination)
                    this.loadCountryListsByDestinationOnly(true);
                else
                    this.loadCountryListsByOriginOnly(true);
                
            } else if (this.originIso == "blank" && this.destinationIso == "blank") {
                // Based on both origin and destination being blank. 
                this.getCountriesJsonAsync();
            }
            else {
                // Based on neither iso being known - set the origin to the default 
                this.loadDefaultCountryCode();
                this.loadCountryListsByOriginOnly(true);
            }

            this.isLoadingOrigin = false;
            this.isLoadingDestination = false;
        },
        originRegionChanged: function (originRegionCode) {
            this.originRegionCode = originRegionCode;
        },
        destinationRegionChanged: function (destinationRegionCode) {
            this.destinationRegionCode = destinationRegionCode;
        },
        updateCustoms: function () {
            var $customs = $('.small_cus_customs');
            var $customsLink = $('.cus_noncustoms');
            var $noLiquidsItems = $('.liq_noliquids_small');
            var $someLiquidsItems = $('.liq_someliquids_small');
            var $allLiquidsItems = $('.liq_all_small');
            var $allLiquidsItems_Large = $('.liq_all_large');
            var $noLiquidsItems_Large = $('.liq_noliquids_large');
            var $someLiquidsItems_Large = $('.liq_someliquids_large');
            var $showmore = $('.card-show-more-container');
            var speed = 0;// 50;
            var data = { originCountryIso: this.originIso, destinationCountryIso: this.defaultDestinationIso };

            $.getJSON('/en-gb/api/destination/customs/', $.param(data, true), function (response) {
                if (response.hasCustoms == 'true') {
                    $customs.show(speed);
                    $customsLink.show(speed);
                    $noLiquidsItems_Large.hide(speed);
                    $someLiquidsItems_Large.hide(speed);

                    if (response.hasSomeLiquids === 'true') {
                        $noLiquidsItems.hide(speed);
                        for (var li = 0; li < $showmore.length; li++) {
                            $showmore[li].classList.remove('show');
                        }
                        $someLiquidsItems.show(speed);
                    } else {
                        $noLiquidsItems.show(speed);
                        $someLiquidsItems.hide(speed);
                        $allLiquidsItems.show(speed);
                        $allLiquidsItems_Large.hide(speed);
                        for (var lj = 0; lj < $showmore.length; lj++) {
                            $showmore[lj].classList.remove('show');
                        }
                    }
                } else {
                    $customs.hide(speed);
                    $customsLink.hide(speed);
                    $noLiquidsItems.hide(speed);
                    $someLiquidsItems.hide(speed);

                    if (response.hasSomeLiquids === 'true') {
                        $noLiquidsItems_Large.hide(speed);
                        for (var i = 0; i < $showmore.length; i++) {
                            $showmore[i].classList.remove('show');
                        }
                        $someLiquidsItems_Large.show(speed);
                    } else {
                        $someLiquidsItems_Large.hide(speed);
                        for (var j = 0; j < $showmore.length; j++) {
                            $showmore[j].classList.remove('show');
                        }
                        $noLiquidsItems_Large.show(speed);
                    }
                }
            });
        },
        updateAlert: function () {
            var self = this;
            var html = "";
            var isoparams = {
                destinationName: "",
                originIso: this.originIso,
                destinationIso: this.defaultDestinationIso,
                isReturn: false
            };
            var $alertContainer = $('#routealerts')

            $.getJSON('/en-gb/api/destination/routealerts/', $.param(isoparams, true), function (result) {
                //data is the JSON string
                if (result.alerts.length > 0) {
                    self.isLoading = false;
                    $alertContainer.html(html);
                    self.$emit('updatealert', result.alerts, result.destinationName, self.originIso);
                } else {
                    self.$emit('updatealert', null, null, null);
                }
            })
        },
        loadRegions: function (originIso) {
            var self = this;
            self.regions = []

            var params = {
                countryIso: originIso
            }

            $.getJSON('/en-gb/api/addressapi/getisoregionsjsonasync', params, function (data) {
                self.regions = data;
            })
        },
    },
    created() {
        this.loadCountryData();
        this.$successLog({ description: 'Mixin - Route Select Mixin', outcome: 'Initialized' })
    },

};