<template>
    <div>
        <date-and-service-select :date="inboundCollectionDate" 
                                 :serviceId="inboundServiceId" 
                                 :collectionServiceId="inboundCollectionServiceId" 
                                 :timeSlot="inboundTimeSlot" 
                                 :estimateDateData="estimateDateData"
                                 :estimatedDateLoading="estimatedDateLoading" 
                                 :collectionAddress="collectionAddress"
                                 :availableServices="availableServices"
                                 v-on:dateupdated="dateUpdated" 
                                 v-on:timeslotupdated="timeSlotUpdated" 
                                 v-on:serviceupdated="serviceUpdated" 
                                 v-on:collectionserviceupdated="collectionServiceUpdated"></date-and-service-select>
        <generic-modal id="inboundCollectionDateAlerts" title="Important Information" :contentArray="collectionDateAlerts"></generic-modal>
    </div>
</template>
<script>
    import DateAndServiceSelect from './dates/DateAndServiceSelect.vue'

    export default {
        name: 'basket-step6',
        components: {
            DateAndServiceSelect
        },
        data: function () {
            return {
                availableServices: [],
                estimateDateData: {},
                estimatedDateLoading: false,
                servicesLoading: false
            }
        },

        computed: {
            inboundCollectionDate: {
                get: function () { return this.$store.state.dates.inboundCollectionDate; },
                set: function (value) { this.$store.commit('setInboundCollectionDate', value); }
            },
            inboundTimeSlot: {
                get: function () { return this.$store.state.dates.inboundTimeSlot; },
                set: function (value) { this.$store.commit('setInboundTimeSlot', value); }
            },
            inboundServiceId: {
                get: function () { return this.$store.state.dates.inboundServiceId; },
                set: function (value) {
                    this.$store.commit('setInboundServiceId', value);
                    this.$store.dispatch('updateSummaryWithOptions', { inboundRouteServiceId: value, inboundCollectionServiceId: this.inboundCollectionServiceId });
                }
            },
            inboundCollectionServiceId: {
                get: function () { return this.$store.state.dates.inboundCollectionServiceId; },
                set: function (value) {
                    this.$store.commit('setInboundCollectionServiceId', value);
                    this.$store.dispatch('updateSummaryWithOptions', { inboundRouteServiceId: this.inboundServiceId, inboundCollectionServiceId: value });
                }
            },
            collectionAddress: function () {
                return this.$store.state.addresses.destination;
            },
            collectionDateAlerts: function () {
                return this.estimateDateData.collectionDateAlerts;
            }
        },
        methods: {
            dateUpdated: function (value) {
                this.inboundCollectionDate = value;
                this.getEstimatedDate();
            },
            timeSlotUpdated: function (value) {
                this.inboundTimeSlot = value;
            },
            serviceUpdated: function (value) {
                this.inboundServiceId = value;
                this.getEstimatedDate();
            },
            collectionServiceUpdated: function (value) {
                this.inboundCollectionServiceId = value;
                this.getEstimatedDate();
            },
            loadAvailableServices: function () {
                var params = {
                    isOutbound: false
                };

                this.$store.dispatch('startedLoading');
                this.servicesLoading = true;

                let _this = this;
                var jqxhr = $.getJSON('/en-gb/api/basketdateapi/getavailableservices/', params, function (result) {
                    _this.availableServices = result.data.services;

                    if (!_this.inboundServiceId && _this.availableServices) {
                        _this.inboundServiceId = _this.availableServices[0].serviceId;
                    }
                });

                jqxhr.fail(function (errors) {
                    _this.$store.dispatch('errorLog', { description: 'Action - loadAvailableServices failed with errors', outcome: 'Error' });
                    _this.$store.dispatch('showErrorToast');
                });

                jqxhr.always(() => {
                    _this.$store.dispatch('finishedLoading');
                    _this.servicesLoading = false;
                });
            },
            getEstimatedDate: function () {
                if (!this.inboundCollectionDate || !this.inboundServiceId) {
                    return;
                }

                this.estimatedDateLoading = true;
                var params = {
                    model: {
                        collectionDate: this.inboundCollectionDate,
                        routeServiceId: this.inboundServiceId,
                        collectionServiceId: this.inboundCollectionServiceId
                    },
                    //requestId: window.smbData.STEPS_OUTBOUNDDATE
                };

                let _this = this;
                var jqxhr = $.post('/en-gb/api/basketdateapi/getinboundestimateddateasync/', params, function (result) {
                    _this.estimateDateData = result.data;

                    if (_this.estimateDateData.collectionDateAlerts && _this.estimateDateData.collectionDateAlerts.length) {
                        $('#inboundCollectionDateAlerts').modal({ backdrop: 'static', keyboard: false });
                    }
                });

                jqxhr.fail(function () {
                    _this.$store.dispatch('errorLog', { description: 'Action - loadAvailableServices failed with errors', outcome: 'Error' });

                    // EH: Crucial?
                    //_this.$store.dispatch('showErrorToast');
                });

                jqxhr.always(function () {
                    _this.estimatedDateLoading = false;
                });
            }
        },
        metaInfo() {
            return {
                title: window.smbData.CMS.Dates.Meta.Title
            };
        },
        created() {
            this.$successLog({ description: 'Component - Basket Step 5', outcome: 'Created' });
            this.$store.dispatch('updateSummary');
            this.loadAvailableServices();
            this.getEstimatedDate();
        },
        mounted() {
            $(window).scrollTop(0);
        }
    }
</script>
