<template>
    <div class="col-6 col-md-4 d-flex mb-3 basketitem" v-bind:class="{ 'removing' : isRemoved }">
        <div class="card card-blue flex-grow-1">
            <div class="card-body text-center py-4">
                <div class="text-primary mb-3"><i class="fa-2x fa-fw" v-bind:class="getItemIcon"></i></div>
                <div class="small" style="min-height:3rem;">
                    <div class="mb-1 overflow-hidden-with-ellipses">{{ description }}</div>
                    <div>{{ Math.ceil(width) }}{{ lengthUnitText }} x {{ Math.ceil(height) }}{{ lengthUnitText }} x {{ Math.ceil(length) }}{{ lengthUnitText }}</div>
                    <div>{{ Math.ceil(weight) }}{{ weightUnitText }}</div>
                </div>
                <div class="mt-2 mb-3" v-show="shouldShowCopyItem">
                    <a href="#/" class="link-stylised" title="Copy item" v-on:click="copyItem">
                        Copy
                    </a>
                </div>
                <div class="mt-2"><a href="#" class="link-stylised" v-on:click.stop.once="removeItem">Remove</a></div>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$;
    import { mapGetters } from 'vuex';
    export default {
        name: 'basket-item',
        props: {
            width: Number,
            height: Number,
            length: Number,
            weight: Number,
            itemType: Number,
            description: String,
            index: Number
        },

        data: function () {
            return {
                isRemoved: false
            }
        },

        computed: {
            ...mapGetters([
                'lengthUnitText',
                'weightUnitText',
            ]),

            getItemIcon: function () {
                switch (this.itemType) {
                    case 1:
                        return 'fad fa-suitcase';
                    case 2:
                        return 'fas fa-box-open';
                    case 3:
                        return 'fas fa-backpack';
                    case 4:
                        return 'fas fa-backpack';
                    case 5:
                        return 'fad fa-golf-club';
                    case 6:
                        return 'fas fa-box-open';
                    case 7:
                        return 'fad fa-bicycle';
                    case 8:
                        return 'fas fa-saxophone';
                    case 9:
                        return 'fad fa-suitcase';
                }

                return 'fas fa-box-open';
            },
            shouldShowCopyItem: function () {
                if (this.$store.state.items.items)
                    return this.$store.state.items.items.length > 2;

                return false;
            },
            hasShownCopyItemMessage: function () {
                return this.$store.state.items.hasShownCopyItemMessage;
            },
            isMetric: function () {
                return this.$store.state.items.isMetric;
            },
        },

        methods: {
            removeItem: function () {
                this.isRemoved = true;

                this.$store.dispatch('startedLoading');
                this.$store.dispatch('removeItem', this.index).always(() => {
                    this.$store.dispatch('updateSummary');
                    this.$store.dispatch('finishedLoading');
                });
            },
            copyItem: async function () {
                const hasConfirmed = await this.getCopyItemConfirmation();

                if (!hasConfirmed)
                    return;

                if (!this.hasShownCopyItemMessage)
                    this.$store.commit('setHasShownCopyItemMessage', true);

                const item = {
                    width: this.width,
                    height: this.height,
                    length: this.length,
                    weight: this.weight,
                    isMetric: this.isMetric,
                    itemType: this.itemType,
                    description: this.description
                };

                this.$store.dispatch('startedLoading');
                this.$store.dispatch('addItem', item).always(() => {
                    this.$store.dispatch('updateSummary');
                    this.$store.dispatch('finishedLoading');
                });
            },
            getCopyItemConfirmation: async function () {
                if (this.hasShownCopyItemMessage)
                    return true;

                const confirmModal = this.$parent.$refs.copyItemConfirmModal;
                const hasConfirmed = await confirmModal.getConfirmation();

                return hasConfirmed;
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Item', outcome: 'Created' })
        },
    }
</script>