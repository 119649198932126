<template>
    <div>

        <input asp-for="ReturnUrl" type="hidden" />
        <p>Enter the email associated with your account and we'll send an email with instructions to reset your password.</p>
        <div class="form-group ">
            <label for="Email">Email Address:</label>
            <input id="Email" type="Email" name="email" ref="email" class="form-control" placeholder="Email" v-model="email" v-validate="'required'" autocomplete="new-email" />
        </div>


        <div class="form-group">
            <button class="btn btn-success btn-block" type="button" v-on:click.stop.prevent="loginCustomer">Reset Password</button>
            <div class="text-danger my-1" attr.id="errormessage">
                <p v-if="passworderrors.length">
                    <span v-for="error in passworderrors" v-bind:key="error">{{ error }}</span>
                </p>
            </div>
        </div>

    </div>
</template>
<script>
    const $ = window.$;

    export default {
        name: 'accountforgotpassword',
        data: function () {
            return {
                passworderrors: [],
                email: null,
                error: null
            }
        },
        methods: {
            loginCustomer: function () {
                var self = this;
                var token = "";
                var tokens = document.getElementsByName("__RequestVerificationToken");
                for (var i = 0; i < tokens.length; i++) {
                    token = tokens[i].value;
                }

                    var params = {
                        Email: this.email,
                        __RequestVerificationToken: token
                    };
                    var jqxhr = $.post('/en-gb/api/accountapi/forgotpassword', params, function (result) {
                        if (result.success) {
                            $('#registermodal').modal('hide');
                            $('#loginmodal').modal('hide');
                            $('#forgotmodal').modal('hide');
                            $("#Email").val("");

                            window.setTimeout(function () {
                                $('#resetmodal').modal('show');
                            }, 300);
                        }
                        else {
                            self.passworderrors = [];
                            result.errors.forEach(function (error) {
                                self.passworderrors.push(error.value.toString());
                            });
                            if (!self.passworderrors.length) return true;
                        }
                    });
                    jqxhr.fail(function () {
                    });
            },

        }
    }
</script>
