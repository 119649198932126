<template>
    <div v-if="hasSavedAddresses">
        <!--<validation-provider :rules="{ requiredSelection: !hasAddressTypeBeenSelected }" :custom-messages="{ requiredSelection }"
                         v-slot="{ errors }" vid="saved-address">
        <input type="hidden" :value="selectedAddressId" />
        <error-card :messages="errors"></error-card>
    </validation-provider>-->

        <horizontal-scroll>
            <div v-for="address in addresses" :key="address.id"
                 class="card card-gray card-fixed-width py-0 my-3 flex-fill" v-bind:class="{ selected: isSelected(address.id) }">
                <address-details :address="address" :header="address.line1">
                </address-details>
                <div class="form-wrapper d-flex align-items-end bottom-link">
                    <div class="form-inner-wrapper col-12 pl-4 pr-4">
                        <div class="form-group text-center">
                            <input type="radio" class="d-none" name="order-saved-addresses" :id="address.id"
                                   v-model="selectedAddressId" :value="address.id">
                            <label :for="address.id" class="btn btn-stylised px-0 py-0">Select</label>
                        </div>
                    </div>
                </div>
            </div>
        </horizontal-scroll>

        <div>
            <h2>or Add a New Address...</h2>
        </div>
    </div>
</template>

<script>
    import AddressDetails from '../../../components/address/AddressDetail.vue';
    import HorizontalScroll from '../../../components/shared/HorizontalScroll.vue';
    //import ErrorCard from '../../../components/shared/ErrorCard.vue';
    //import { ValidationProvider, extend } from 'vee-validate';
    //import { required } from 'vee-validate/dist/rules';

    //extend('requiredSelection', { ...required });

    export default {
        name: 'basket-saved-addresses',
        components: {
            AddressDetails,
            HorizontalScroll,
            //ValidationProvider,
            //ErrorCard
        },
        props: {
            value: Number,
            addresses: Array
        },
        data: function () {
            return {
                //requiredSelection: 'You must select an address or add a new one'
            }
        },
        computed: {
            selectedAddressId: {
                get: function () { return this.value; },
                set: function (value) { this.$emit('input', value); }
            },

            hasSavedAddresses: function () {
                if (this.addresses)
                    return this.addresses.length > 0;

                return false;
            },

            //hasAddressTypeBeenSelected: {
            //    get() {
            //        return this.$store.state.addresses.addressTypes
            //            && this.$store.state.addresses.activeAddress.addressTypeId !== 0
            //    }
            //}
        },
        methods: {
            isSelected(id) {
                return this.selectedAddressId === id;
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Saved Addresses', outcome: 'Created' })
        }
    }
</script>