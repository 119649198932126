 <template>
    <div class="modal fade" ref="modal" :id="id" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="resetModalLabel" v-html="title"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-if="hasCloseIcon">
                        <span aria-hidden="true"><i class="fas fa-times"></i></span>
                    </button>
                </div>

                <div class="modal-body">
                    <slot>
                        <div v-html="content" v-if="content"></div>
                        <div v-if="contentArray">
                            <div v-for="item in contentArray" v-bind:key="item" class="pb-2" v-html="item"></div>
                        </div>
                    </slot>
                </div>

                <div class="modal-footer" v-if="hasCloseButton">
                    <button type="button" class="btn btn-success" data-dismiss="modal">{{ closeButtonText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script> 
    export default {
        name: 'genericmodal',
        props: {
            id: String,
            hideCloseIcon: Boolean,
            hideCloseButton: Boolean,
            title: String,
            content: String,
            contentArray: Array,
            closeButtonText: {
                type: String,
                default: 'Close'
            }
        },
        computed: {
            hasCloseIcon: function () { return !this.hideCloseIcon; },
            hasCloseButton: function () { return !this.hideCloseButton; }
        }
    }
</script>