<template>
    <div class="card card-gray mb-3" 
         v-if="showCreditAmount">
        <div class="card-header text-center">
            <div class="h2 mb-0">Credit</div>
        </div>
        <div class="card-body row">
            <div class="custom-control custom-checkbox">
                <label>
                    <input class="custom-control-input pull-right" type="checkbox" id="essentials-usecreditamount" 
                           v-model="useCredit">
                    <label class="custom-control-label" for="essentials-usecreditamount">
                        You have {{creditFormatted}} credit, would you like to use it for this order?
                    </label>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$;
    export default {
        name: "use-credit",
        props: {
            credit: Number,
            creditFormatted: String,
        },
        computed: {
            useCredit: {
                get: function () { return this.$store.state.essentials.useCredit; },
                set: function (value) { this.$store.commit('setUseCredit', value); }
            },
            showCreditAmount: {
                get() { return this.credit > 0 }
            },
        },
        //watch: {
        //    useCreditAmount: function (newValue) {
        //    },
        //},
        created() {
            this.$successLog({ description: 'Component - Basket Essentials Credit', outcome: 'Created'})
        },
    }
</script>