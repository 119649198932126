const eagerButNotOnBlur = ({ errors }) => {
    if (errors.length) {
        return {
            on: ['input', 'change']
        };
    }

    return {
        on: ['change']
    };
}

export {
    eagerButNotOnBlur
};