export default {
    state: {
        // Payload values
        labelHolders: String,
        labels: String,
        postageMethodId: Number,
        useCollectionAddressForDelivery: Boolean,
        postageAddress: Object,
        cancellationCover: String,
        lossCover: String,
        smsTrackingNumber: Object,
        reasonForUsingUs: Number,
        university: Number,
        useCredit: Boolean,
        usePoints: Boolean,

        // Other data
        reasonsForUsingUs: [],
        universities: [],
    },

    mutations: {
        setInitialStateEssentials: function (state, payload) {
            state.labelHolders = payload.labelHolders;
            state.postageMethodId = payload.postageMethodId;
            state.useCollectionAddressForDelivery = payload.useCollectionAddressForDelivery;
            state.postageAddress = payload.postalAddress;
            state.labels = '';
            state.cancellationCover = '';

            if (payload.labels === true) {
                state.labels = 'true';
            } else if (payload.labels === false) {
                state.labels = 'false';
            }
            if (payload.cancellationCover === true) {
                state.cancellationCover = 'true';
            } else if (payload.cancellationCover === false) {
                state.cancellationCover = 'false';
            }

            state.lossCover = payload.lossCoverId;
            state.smsTrackingNumber = payload.trackingPhoneNumber;
            state.reasonForUsingUs = payload.reasonForUsingUsId;
            state.university = payload.universityId;
            state.useCredit = payload.useCreditAmount;
            state.usePoints = payload.useRewardPoints;

            state.reasonsForUsingUs = payload.reasonsForUsing;
            state.universities = payload.universityList;
        },

        setLabelHolders: function (state, payload) {
            state.labelHolders = payload;
        },
        setLabels: function (state, payload) {
            state.labels = payload;
        },
        setPostageMethodId: function (state, payload) {
            state.postageMethodId = payload;
        },
        setUseCollectionAddressForDelivery: function (state, payload) {
            state.useCollectionAddressForDelivery = payload;
        },
        setPostageAddress: function (state, payload) {
            state.postageAddress = payload;
        },
        setCancellationCover: function (state, payload) {
            state.cancellationCover = payload;
        },
        setLossCover: function (state, payload) {
            state.lossCover = payload;
        },
        setSmsTrackingNumber: function (state, payload) {
            state.smsTrackingNumber = payload;
        },
        setReasonForUsingUs: function (state, payload) {
            state.reasonForUsingUs = payload;
        },
        setUniversity: function (state, payload) {
            state.university = payload;
        },
        setUseCredit: function (state, payload) {
            state.useCredit = payload;
        },
        setUsePoints: function (state, payload) {
            state.usePoints = payload;
        }
    },

    actions: {
        async saveEssentials({ state, commit, dispatch, rootState }) {
            const hasLabelHoldersOrLabels = Number(state.labelHolders) > 0 || state.labels?.toLowerCase() === 'true';

            const postageAddress = state.useCollectionAddressForDelivery || !hasLabelHoldersOrLabels
                ? null
                : state.postageAddress;
            const trackingPhoneNumber = state.smsTrackingNumber && state.smsTrackingNumber.number ? state.smsTrackingNumber : null;
            
            const params = {
                model: {
                    labelHolders: state.labelHolders ? state.labelHolders : 0,
                    labels: state.labels ? state.labels : false,
                    postageMethodId: state.postageMethodId,
                    useCollectionAddressForDelivery: state.useCollectionAddressForDelivery,
                    postageAddress: postageAddress,
                    cancellationCover: state.cancellationCover,
                    lossCoverId: state.lossCover,
                    trackingPhoneNumber: trackingPhoneNumber,
                    reasonForUsingUs: state.reasonForUsingUs,
                    university: state.university !== '' && state.university !== '0'
                        ? state.university
                        : null,
                    useCreditAmount: state.useCredit,
                    useRewardPoints: state.usePoints,
                },
                requestId: rootState.requestId.nextStep,
            };


            var jqxhr = $.post('/en-gb/api/basketessentialsapi/saveessentials/', params, function (result) {
                let errors = result.errors ?? {};
                commit('setValidationErrors', errors);

                if (result && result.success) {
                    commit('setStepForward', window.smbData.STEPS_CONFIRM);
                }
            });

            jqxhr.fail(function () {
                dispatch('showErrorToast');
            });

            jqxhr.always(function () {
                dispatch('setIsLoading', false);
            });

        },
    }
};
