 <template>
    <select v-bind:name="name" autocomplete='off'  class="form-control" ><slot></slot></select>
</template>
<script>

    import select2Mixin from '../mixins/select-2-mixin';
    const $ = window.$;
    export default {

        mixins: [select2Mixin],
       
        mounted: function () {
            var vm = this;
            $(this.$el)
                // init select2
                .select2({
                    data: this.options,
                    placeholder: this.placeholder,
                    matcher: this.customMatcher,
                    templateResult: this.formatState,
                    dropdownAutoWidth: 'true',
                    templateSelection: this.formatStateSelection
                })
                .val(this.value)
                .trigger('change')
                // emit event on change.
                .on('change', function () {
                    vm.$emit('input', this.value);
                });
        },
        methods: {
            formatState: function (opt) {
                if (!opt.id) {
                    return $(opt.element).attr('data-country') + ' ' + $(opt.element).attr('data-code');
                }
                var optimage = $(opt.element).attr('data-tokens').toLowerCase();
                var optcountry = $(opt.element).attr('data-country');
                var optcode = $(opt.element).attr('data-code');
                if (!optimage) {
                    return optcountry + ' ' + optcode;
                } else {
                    var $opt = $(
                        '<div><span class="flag flag-' + optimage + '"></span> <strong>' + optcountry + '</strong> ' + optcode + '</div>'
                    );
                    return $opt;
                }
            },
            formatStateSelection: function (opt) {
                if (!opt.id) {
                    return $(opt.element).attr('data-code');
                }
                var optimage = $(opt.element).attr('data-tokens').toLowerCase();
              //  var optcountry = $(opt.element).attr('data-country');
                var optcode = $(opt.element).attr('data-code');
                if (!optimage) {
                    return optcode;
                } else {
                    var $opt = $(
                        '<div><span class="flag flag-' + optimage + '"></span> ' + optcode + '</div>'
                    );
                    return $opt;
                }
            }
        }
    }
</script>

 