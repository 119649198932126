export default {
    state: {
        // Payload values
        customsType: Number,
        customsDescription: String,
        customsValue: Number,
        isSomeoneElses: Boolean,

        // Other data
        isCustomsRoute: Boolean,
        customsValueMin: Number,
        customsValueMax: Number,
        customsValueWarningThreshold: Number,
        customsValueErrorPersonal: String,
        customsValueErrorOther: String
    },

    mutations: {
        setInitialStateCustoms: function (state, payload) {
            state.isCustomsRoute = payload.isCustomsRoute;
            state.customsType = payload.customsType;
            state.isSomeoneElses = payload.isSomeoneElses;
            state.customsDescription = payload.customsDescription;
            state.customsValue = payload.customsValue;
            state.customsValueMin = payload.customsValueMin;
            state.customsValueMax = payload.customsValueMax;
            state.customsValueWarningThreshold = payload.customsValueWarningThreshold;
            state.customsValueErrorPersonal = payload.customsValueErrorPersonal;
            state.customsValueErrorOther = payload.customsValueErrorOther;
        },

        setCustomsType: function (state, payload) {
            state.customsType = payload;
            if (payload !== 1) {
                state.isSomeoneElses = null;
            }
        }, 
        setSomeoneElses: function (state, payload) {
            state.isSomeoneElses = payload;
        }, 
        setCustomsDescription: function (state, payload) {
            state.customsDescription = payload;
        },
        setCustomsValue: function (state, payload) {
            state.customsValue = payload;
        },
    },

    actions: {
        async saveCustoms({ state, commit, dispatch, rootState }) {
            // If it's not a customs route then we just move on to the next step
            if (!state.isCustomsRoute) {
                dispatch('setIsLoading', false);
                commit('setStepForward', window.smbData.STEPS_ORIGINADDRESS);
                return;
            }

            var params = {
                model: {
                    customsType: state.customsType,
                    customsDescription: state.customsDescription,
                    customsValue: state.customsValue,
                    isSomeoneElses: state.isSomeoneElses
                },
                requestId: rootState.requestId.nextStep,
            };

            var jqxhr = $.post('/en-gb/api/basketcustomsapi/savecustoms/', params, function (result) {
                let errors = result.errors ?? {};
                commit('setValidationErrors', errors);

                if (result && result.success) {
                    commit('setStepForward', window.smbData.STEPS_ORIGINADDRESS);
                }
            });

            jqxhr.fail(function () {
                dispatch('showErrorToast');
            });

            jqxhr.always(function () {
                dispatch('setIsLoading', false);
            });

        },
    }
};
