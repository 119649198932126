<template>
    <div class="container">
        <slot>
            <div class="ml-3 row">
                <div class="p-1 col-12 col-lg-7 row">
                    <div class="text-center text-lg-right col-12 p-0  col-lg-5 col-xl-6 p-0">
                        <h2 class="card-header pr-1">Sending From: </h2>
                    </div>
                    <div class="pl-1 col-12 col-lg-6 col-xl-5">
                        <country-select id="headerQuoteOrigin" name="OriginIso" placeholder="e.g. United Kingdom" :countries="originCountries" :default-selected-iso="originIso" :key="originIso" :isLoading="isLoadingOrigin" :isEnabled="isEnabledOrigin" v-on:valueChanged="originIsoChanged"></country-select>
                    </div>
                </div>
                <div class="p-1 col-5 d-none d-lg-block col-xl-4">
                    <div class="row">
                        <div class="col-2">
                            <h2 class="card-header"> To: </h2>
                        </div>
                        <div class=" pl-3   col-10">
                            <country-select id="headerQuoteDestination" name="DestinationIso" placeholder="e.g. Spain" :countries="destinationCountries" :default-selected-iso="destinationIso" :isLoading="isLoadingDestination" :isEnabled="isEnabledDestinationSelect" v-on:valueChanged="destinationIsoChanged"></country-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="showPrices == true" class="mt-1 pt-3 col-12 d-none d-lg-block">
                    <button type="submit" class="btn btn-success  btn-price">Show Prices</button>
                </div>
                <div class="col-12">
                    <div v-show="routeMessage" class="alert alert-warning text-danger mt-4 " role="alert">
                        <div class="row pt-1">
                            <div class="col-12 text-center">
                                <p>
                                    <i class="fas fa-exclamation-circle fa-lg p-2"></i>
                                    At this time we do not operate a service from {{originName}} to {{destinationName}}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
    import CountrySelect from '../routing/CountrySelect.vue';
    import routeSelectMixin from '../mixins/route-select-mixin';
    export default {
        name: 'routeselectdestination',
        mixins: [routeSelectMixin],
        components: { CountrySelect },
        props: {
            defaultOriginIso: String,
            defaultDestinationIso: String,
            showPrices:Boolean
        },
        data: function () {
            return {
                originIso: this.defaultOriginIso,
                isLoadingDestination: this.defaultDestinationIso,
                isEnabledDestinationSelect: false
            };
        },
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
