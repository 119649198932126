import { extend } from 'vee-validate';

// Add extensions to the vee-validate library in here

extend('minWordCount', {
    validate(value, args) {
        return value.split(" ").filter(({ length }) => length >= 3).length >= args.min
    },
    params: ['min']
});


//extend('contactName', {
//    validate(value, args) {
//        let strippedValue = value.replaceAll('.', '');
//        return strippedValue.split(" ").filter(word => word.length >= 3).length >= args.min
//    },
//    params: ['min']
//});


extend('checkboxCustomErrorMessage', {
    validate(value, args) {
        if (value)
            return true

        return args.errorMessage
    },
    params: ['errorMessage']
})

extend('postcodeFormat', {
    validate(value, args) {
        if (!args.requiredFormats)
            return true;

        const requiredFormats = args.requiredFormats.toString().toUpperCase();
        const formatsArray = requiredFormats.split(',');
        if (formatsArray.length === 0) { return true; }

        if (value) {
            value = value.toString().toUpperCase()
                .replace(/[-\s]+/g, '').replace(/([a-zA-Z])/g, 'A').replace(/([0-9])/g, '9');
        }

        for (let i = 0; i < formatsArray.length; i++) {
            const format = formatsArray[i].replace(/\W/g, '');
            if (value === format) {
                return true;
            }
        }

        return formatsArray.length === 1
            ? `{_field_} should be in the following format: ${requiredFormats}`
            : `{_field_} should be in one of the following formats: ${requiredFormats}`;
    },
    params: ['requiredFormats']
});

extend('phoneNumber', {
    validate(value, args) {
        if (!value)
            return '{_field_} is required';

        if (!value.number || !value.number.length)
            return '{_field_} is required';

        if (!value.countryIso || !value.countryIso.length)
            return 'Country Code is required';

        if (args.min && value.number.length < args.min)
            return `{_field_} must be at least ${args.min} characters long`;

        if (args.max && value.number.length > args.max)
            return `{_field_} must be less than ${args.max} characters long`;

        return true;
    },
    params: ['min', 'max']
});
