<template>
    <div style="position:relative;">
        <div v-if="showLoadingOverlay" style="position: absolute;opacity: 0.5;width: 100%;height: 100%;top: 0;left: 0;right: 0;bottom: 0;background-color: #fff;z-index: 98;"> </div>   
        <div v-if="showLoadingOverlay" class="row d-flex justify-content-center" key="loading-spinner" style="position: absolute;left: 50%; top: 50%;z-index: 99; transform: translate(-50%, -50%);">
            <div class="reverse-spinner"></div>
        </div>

        <h1>When should we collect your items?</h1>
        <label for="date-collectiondate">Select your collection date from {{ collectionAddress.line1 }}</label>
        <basket-date-select v-model="collectionDate" :dateParams="dateParams"></basket-date-select>
        <collection-service-select v-model="selectedCollectionServiceId" :dateParams="dateParams" :collectionDate="collectionDate"></collection-service-select>
        <shipment-service-select v-model="selectedServiceId" :availableServices="availableServices"></shipment-service-select>
        <estimated-delivery-date :min="minEstimatedDate" :max="maxEstimatedDate" :isCourierResult="isCourierResult" :serviceName="serviceName" :publicHolidays="publicHolidays" :estimatedDateMessage="estimatedDateMessage"
                                 :isLoading="estimatedDateLoading"
                                 v-if="showEstimatedDate"></estimated-delivery-date>
        <time-slot-select v-model="selectedTimeSlot" :availableTimeSlots="availableTimeSlots" v-if="showTimeSlots"></time-slot-select>
    </div>
</template>
<script>
    import BasketDateSelect from './BasketDateSelect.vue'
    import ShipmentServiceSelect from './ShipmentServiceSelect.vue'
    import EstimatedDeliveryDate from './EstimatedDeliveryDate.vue'
    import TimeSlotSelect from './TimeSlotSelect.vue'
    import CollectionServiceSelect from './CollectionServiceSelect.vue'

    export default {
        name: 'date-and-service-select',
        components: {
            BasketDateSelect,
            TimeSlotSelect,
            EstimatedDeliveryDate,
            ShipmentServiceSelect,
            CollectionServiceSelect
        },
        props: {
            date: String,
            timeSlot: String,
            serviceId: Number,
            collectionServiceId: Number,
            availableServices: Array,
            estimateDateData: Object,
            estimatedDateLoading: Boolean,
            collectionAddress: Object
        },
        computed: {
            collectionDate: {
                get: function () { return this.date; },
                set: function (value) {
                    // Update our value
                    this.$emit('dateupdated', value);

                    // Also update the collection service to reset it on a date change
                    this.$emit('collectionserviceupdated', null);
                }
            },
            selectedTimeSlot: {
                get: function () { return this.timeSlot; },
                set: function (value) { this.$emit('timeslotupdated', value); }
            },
            selectedServiceId: {
                get: function () { return this.serviceId; },
                set: function (value) { this.$emit('serviceupdated', value); }
            },
            selectedCollectionServiceId: {
                get: function () { return this.collectionServiceId; },
                set: function (value) { this.$emit('collectionserviceupdated', value); }
            },

            service: function () {
                let selectedService = this.availableServices.filter(obj => {
                    return obj.serviceId === this.serviceId;
                });

                if(!selectedService)
                    return this.availableServices[0];

                return selectedService[0];
            },
            dateParams: function () {
                if (!this.service) {
                    return {
                        minDate: null,
                        maxDate: null,
                        weekendDays: [0,6],
                        noCollectionDays: [],
                        additionalCollectionServices: []
                    };
                }

                return {
                    minDate: this.service.firstAvailablePickup,
                    maxDate: this.service.lastAvailablePickup,
                    weekendDays: this.service.weekendDays,
                    noCollectionDays: this.service.noCollectionDays,
                    additionalCollectionServices: this.service.additionalCollectionServices
                };
            },
            showTimeSlots: function () {
                if (!this.collectionDate)
                    return false;

                return true;
            },
            availableTimeSlots: function () {
                if (!this.service) {
                    return [];
                }

                return this.service.availableTimeSlots;
            },
            minEstimatedDate: function () {
                return this.estimateDateData.minEstimatedDate;
            },
            maxEstimatedDate: function () {
                return this.estimateDateData.maxEstimatedDate;
            },
            isCourierResult: function () {
                return this.estimateDateData.isCourierResult;
            },
            estimatedDateMessage: function () {
                return this.estimateDateData.message;
            },
            publicHolidays: function () {
                return this.estimateDateData.publicHolidays;
            },
            serviceName: function () {
                if (!this.service) {
                    return '';
                }

                return this.service.serviceName;
            },
            showEstimatedDate: function () {
                if (!this.collectionDate)
                    return false;

                if (!this.estimateDateData || !this.estimateDateData.minEstimatedDate)
                    return false;

                return true;
            },
            showLoadingOverlay: function () {
                if (!this.collectionDate)
                    return false;

                if (this.estimateDateData && this.estimateDateData.minEstimatedDate)
                    return false;

                return true;

            }
        },
        created() {
        },
        mounted() {
            $(window).scrollTop(0);
        }
    }
</script>