<template>
    <div>
        <h1>Login to continue</h1>
        <div class="card card-gray mb-3">
            <div class="card-body row">
                <div class="col-12" v-if="isNewCustomer">
                    Already have an account? <a href="#" v-on:click.stop.prevent="toggleIsNewCustomer" class="link-stylised">Login</a>
                </div>
                <div class="col-12" v-else>
                    Don't have an account? <a href="#" v-on:click.stop.prevent="toggleIsNewCustomer" class="link-stylised">Register now</a>
                </div>
            </div>
        </div>
        <div class="card card-gray mb-3">
            <div class="card-body row">
                <div class="col-12">
                    <account-login v-if="!isNewCustomer"></account-login>
                    <account-register v-else></account-register>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$;

    export default {
        name: "basket-login",
        data: function () {
            return {
                isNewCustomer: false
            }
        },
        mounted() {
            $(window).scrollTop(0);
        },
        created() {
            this.$infoLog({ description: 'Component - Basket Login', outcome: 'Initialised' });

            this.$store.dispatch('finishedLoading');

            this.$successLog({ description: 'Component - Basket Login', outcome: 'Created' });
        },
        methods: {
            toggleIsNewCustomer: function () {
                this.isNewCustomer = !this.isNewCustomer;
            },
        }
    }
</script>