<template>
    
    <div>
        <validation-observer ref="accountRegister">
            <form v-on:submit.prevent="addCustomer">
                <input asp-for="ReturnUrl" type="hidden" />
                <div class="form-group row">
                    <div class="col-lg-6">

                        <label for="FirstName" >Your First Name:</label>
                        <div>
                            <ValidationProvider :rules="{ required: true, max: 128 }" name="First Name" v-slot="{ errors }">
                                <input attr.id="FirstName" ref="firstName" name="first name" class="form-control" placeholder="First Name" autocomplete="new-firstname" v-model="firstName" autofocus />
                                <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-lg-6">

                        <label for="LastName" class="mt-lg-0 my-2">Your Last Name:</label>
                        <div>
                            <ValidationProvider :rules="{ required: true, max: 128 }" name="Last Name" v-slot="{ errors }">
                                <input attr.id="LastName" ref="lastName" name="last name" class="form-control" placeholder="Last Name" autocomplete="new-lastname" v-model="lastName" />
                                <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">

                        <label for="Email">Email Address:</label>
                        <div>
                            <ValidationProvider :rules="{ required: true, email: true }" name="Email" v-slot="{ errors }">
                                <input attr.id="Email" type="Email" name="email" ref="email" class="form-control" placeholder="Email" v-model="email" autocomplete="new-email" />
                                <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label for="Email" class="mt-lg-0 my-2">Confirm Email Address:</label>
                        <div>
                            <ValidationProvider :rules="{ required: true, email: true, confirmed: 'Email' }" name="Confirm Email" v-slot="{ errors }">
                                <input attr.id="confirmemail" type="email" ref="email" name="email" class="form-control" placeholder="Confirm Email" v-model="confirmEmail"
                                       autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                                       ondrop="return false" onpaste="return false" />
                                <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                        </div>
                    </div>

                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label for="password">Password:</label>
                        <div>
                            <ValidationProvider :rules="{ required: true }" name="Password" v-slot="{ errors }">
                                <input attr.id="password" type="password" name="password" class="form-control" placeholder="Password" ref="password" required v-model="password" autocomplete="new-password" v-validate="'required'" />
                                <div class="text-danger">{{ errors[0] }}</div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label for="customer-register-phone-code" class="mt-lg-0 my-2">Your Phone Number:</label>
                            <div class="register-phone-number-input">
                                <div class="input-group-prepend d-inline-block w-25">
                                    <flag-select id="order-address-phone-code" v-model="customerPhoneCountryIso" :options="phoneCodes" name="DestinationIso" placeholder="Please Search"
                                                 :default-phone-country-code="phoneCountryCode" style="width:100%; min-width:7rem;" required v-on:valueChanged="isoChanged"></flag-select>
                                </div>
                                <div class="input-group-append d-inline-block w-75">
                                    <ValidationProvider :rules="{ required: true, min: 4 }" name="Number" v-slot="{ errors }">
                                        <input type="tel" class="form-control" ref="number" attr.id="customer-register-phone-number" name="phone number" placeholder="" autocomplete="new-phonenumber"
                                               v-model="customerPhoneNumber" />
                                        <div class="register-phone-number-error">{{ errors[0]}}</div>
                                    </ValidationProvider>
                                </div>
                            </div>
                    </div>

                </div>
            </form>
        </validation-observer>
        <div v-show="studentRegister" id="studentdetail">
            <div class="form-group row">
                <div class="col-lg-6">
                    <label for="customer-register-studentcard">Student Card Type:</label>
                    <select-2 attr.id="customer-register-studentcard" name="studentcard" :options="studentcards" v-model="cardTypeId" class="form-control"
                              style="width:100%;" placeholder="Please Select" data-minimum-results-for-search="Infinity"></select-2>
                </div>
                <div class="col-lg-6">
                    <label for="customer-register-studentnumber" class="mt-lg-0 my-2">Student Number:</label>
                    <div>
                        <input attr.id="customer-register-studentnumber" type="text" name="student number" class="form-control" placeholder="Student Number" ref="studentnumber" required v-model="studentNumber" autocomplete="new-studentnumber" />
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="custom-control custom-checkbox">
                <label>
                    Would you like to receive our newsletter?
                    <input class="custom-control-input pull-right" type="checkbox" :id="`${form}newsletter`" v-model="subscribetonewsletter">
                    <label class="custom-control-label" :for="`${form}newsletter`"> </label>
                </label>
            </div>
        </div>
        <div class="form-group">
            <button class="btn btn-success btn-block" type="button" v-on:click.stop.prevent="addCustomer" v-bind:disabled="isRegistering">Register</button>
            <div class="text-danger" attr.id="errormessage">
                <p v-if="registererrors.length">

                    <span v-for="error in registererrors" v-bind:key="error">{{ error }}</span>

                </p>
            </div>
        </div>
        <div class="g-recaptcha" data-sitekey="6LfzDx0UAAAAANLmumkQkk1TT-NJKaSplgLkNXz9" data-callback="onRecaptchaSubmit" data-size="invisible" data-badge="inline"></div>

    </div>
</template>

<script>
    const $ = window.$;
    import Select2 from '../select-2/Select2.vue';
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import { required, max, numeric, email, min, confirmed  } from 'vee-validate/dist/rules';

    
    extend('required', { ...required, message: '{_field_} is required' });
    extend('max', { ...max, message: 'The {_field_} must be less than {length} characters long' });
    extend('numeric', { ...numeric, message: 'The {_field_} may only contain numeric characters' });
    extend('email', { ...email, message: '{_field_} must be a valid email address' });
    extend('min', { ...min, message: 'The {_field_} must be greater than {length} digits' });
    extend('confirmed', { ...confirmed, message: 'The Email confirmation does not match' });
    
    export default {
        name: 'accountregister',
        components: {
            Select2,
            ValidationProvider,
            ValidationObserver
            },
        props: {
            defaultPhoneCountryCode: String,
            placeholder: String,
            form: String,
            studentRegister: Boolean,

        },

        data: function () {
            return {
                fullName: null,
                firstName: null,
                lastName: null,
                reasonForUsingUsId: null,
                universityId: null,
                customerPhoneCountryIso: this.defaultPhoneCountryCode,
                customerPhoneNumber: null,
                customerPhoneExtension: null,
                subscribetonewsletter: false,
                password: null,
                confirmEmail: null,
                email: null,
                studentNumber: "",
                cardTypeId: null,

                registererrors: [],
                phoneCodes: [],
                reasonForUsingUs: [],
                universities: [],
                studentcards: [],

                isRegistering: false
            }
        },
        computed: {
            phoneCountryCode() {
                if (this.defaultPhoneCountryCode) {
                    return this.defaultPhoneCountryCode.slice(0, 2);
                }
                return 'GB';
            }
        },
        created: function () {
            this.loadPhoneCodes();
            this.loadReasonForUsingUs();
            this.loadStudentcards();
        },
        mounted() {
            if (!this.defaultPhoneCountryCode) {
                this.getDefaultCountryCode();
            }
        },
        watch: {
            defaultPhoneCountryCode: function () {
                if (this.defaultPhoneCountryCode) {
                    this.customerPhoneCountryIso = this.defaultPhoneCountryCode;
                } else {
                    this.customerPhoneCountryIso = 'GB';
                }
            },
            phoneCodes: function () {
                if (this.defaultPhoneCountryCode) {
                    this.customerPhoneExtension = this.phoneCodes.data.filter(item => item.id === this.defaultPhoneCountryCode.slice(0, 2))[0].text;
                }
            }
        },
        methods: {
            loadReasonForUsingUs: function () {
                var self = this;
                self.reasonForUsingUs = [];
                $.getJSON('/en-gb/api/accountapi/getreasonforusingusjsonasync', function (data) {
                    data.forEach(element => {
                        self.reasonForUsingUs.push({
                            id: element.id, text: element.text,
                            element: { icon: element.icon, uni: element.universities != null ? "UNI" : "noUNI" }
                        });
                        if (element.universities != null) {
                            self.universities = element.universities;
                        }
                    });
                });
            },

            isoChanged: function (iso) {
                this.customerPhoneCountryIso = iso;
                this.customerPhoneExtension = this.phoneCodes.data.filter(item => item.id === this.customerPhoneCountryIso.slice(0, 2))[0].text;
            },

            loadStudentcards: function () {
                var self = this;
                $.getJSON('/en-gb/api/accountapi/getstudentcardsjsonasync', function (data) {
                    self.studentcards = data;
                });
            },
            loadPhoneCodes: function () {
                var self = this;
                $.getJSON('/en-gb/api/addressapi/getphonecodesjsonasync', function (data) {
                    self.phoneCodes = data;
                });
            },
            getDefaultCountryCode: function () {
                var self = this;
                $.getJSON('/en-gb/api/addressapi/getdefaultcountrycode', function (data) {
                    self.defaultPhoneCountryCode = data.countryCode;
                    if (self.phoneCodes.data && self.defaultPhoneCountryCode) {
                        self.customerPhoneExtension = self.phoneCodes.data.filter(item => item.id === self.defaultPhoneCountryCode.slice(0, 2))[0].text;
                    }
                });
            },
            addCustomer: function () {
                // Break early if the form is already being submitted
                if (this.isRegistering) {
                    return;
                }

                this.isRegistering = true;

                var self = this;
                var token = "";
                var tokens = document.getElementsByName("__RequestVerificationToken");
                for (var i = 0; i < tokens.length; i++) {
                    token = tokens[i].value;
                }
                
                self.$refs.accountRegister.validate().then((result) => {
                    if (result) {
                        // Run recaptcha code
                        grecaptcha.ready(function () {
                            grecaptcha.execute('6LctJNslAAAAAAArbIX7aKb9YzqnQ1Xr-vvCSg98', { action: 'Register' }).then(function (recaptchaToken) {
                                var phone = {
                                    Number: self.customerPhoneNumber,
                                    CountryCode: self.customerPhoneCountryIso,
                                    CountryExtension: self.customerPhoneExtension
                                }

                                if (self.studentNumber != "") {
                                    var studentparams = {
                                        Email: self.email,
                                        ConfirmEmail: self.confirmEmail,
                                        Name: self.firstName + ' ' + self.lastName,
                                        CustomerGroupId: 1,
                                        Phone: phone,
                                        NewPassword: self.password,
                                        Purpose1Id: self.reasonForUsingUsId,
                                        SubscribeToNewsletter: self.subscribetonewsletter,
                                        UniversityId: self.universityId,
                                        StudentCardType: self.cardTypeId,
                                        StudentNumber: self.studentNumber,
                                        __RequestVerificationToken: token,
                                        RecaptchaToken: recaptchaToken
                                    };
                                    $.post('/en-gb/api/accountapi/registerstudent', studentparams, function (result) {
                                        if (result.success) {
                                            window.location.reload();
                                        }
                                        else {
                                            self.isRegistering = false;

                                            self.registererrors = [];
                                            result.errors.forEach(function (error) {
                                                self.registererrors.push(error.value.toString());
                                            });
                                            if (!self.registererrors.length) return true;
                                        }
                                    });
                                }
                                else {
                                    var params = {
                                        Email: self.email,
                                        ConfirmEmail: self.confirmEmail,
                                        Name: self.firstName + ' ' + self.lastName,
                                        CustomerGroupId: 1,
                                        Phone: phone,
                                        NewPassword: self.password,
                                        Purpose1Id: self.reasonForUsingUsId,
                                        SubscribeToNewsletter: self.subscribetonewsletter,
                                        UniversityId: self.universityId,
                                        __RequestVerificationToken: token,
                                        RecaptchaToken: recaptchaToken
                                    };
                                    $.post('/en-gb/api/accountapi/registercustomer', params, function (result) {
                                        if (result.success) {
                                            window.location.href = '';
                                        }
                                        else {
                                            self.isRegistering = false;

                                            self.registererrors = [];
                                            result.errors.forEach(function (error) {
                                                self.registererrors.push(error.value.toString());
                                            });
                                            if (!self.registererrors.length) return true;
                                        }
                                    });
                                }
                            });
                        });
                    } else {
                        self.isRegistering = false;
                    }
                });
            }
        },     

    }
</script>
