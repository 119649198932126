<template>
    <validation-observer ref="loginform" tag="div" disabled>
        <form v-on:submit.prevent="loginCustomer">
            <input asp-for="ReturnUrl" type="hidden" />
            <div class="form-group">
                <text-input v-model.trim="email" :rules="{ required: true, email: true }" type="email"
                            name="email" id="loginemail"
                            label="Email Address:" placeholder="someone@example.com" autocomplete="email"></text-input>
            </div>
            <div class="form-group ">
                <text-input v-model.trim="password" :rules="{ required: true }" type="password"
                            name="current-password" id="loginpassword"
                            label="Password:" autocomplete="current-password"></text-input>
            </div>

            <div class="form-group">
                <button class="btn btn-success btn-block" type="submit">Log In</button>
                <div class="text-danger" attr.id="errormessage">
                    <p v-if="saveerrors.length">
                        <span v-for="error in saveerrors" v-bind:key="error">{{ error }}</span>
                    </p>
                </div>
                <div class="row">
                    <div class="col-12 text-right pt-2 px-4">
                        <a class='text-black-50 call-forgot-modal' href="#">Forgot Password</a>
                    </div>
                </div>
            </div>
        </form>
    </validation-observer>
</template>
<script>
    const $ = window.$;
    import { ValidationObserver, extend, setInteractionMode } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';

    setInteractionMode('eager');
    extend('required', { ...required, message: '{_field_} is required' });
    extend('email', { ...email, message: '{_field_} must be a valid email address' });

    export default {
        name: 'accountlogin',
        components: {
            ValidationObserver
        },
        data: function () {
            return {
                password: null,
                email: null,
                saveerrors: []
            }
        },
        methods: {
            callforgotmodal: function () {
                // e.preventDefault();
                $('#registermodal').modal('hide');
                $('#loginmodal').modal('hide');

                window.setTimeout(function () {
                    $('#forgotmodal').modal('show');
                }, 300);
            },

            loginCustomer: function () {
                var self = this;
                self.$refs.loginform.validate().then((isValid) => {
                    if (isValid) {
                        var token = $('input[name="__RequestVerificationToken"]').val();
                        var params = {
                            Email: this.email,
                            Password: this.password,
                            __RequestVerificationToken: token
                        };
                        var jqxhr = $.post('/en-gb/api/accountapi/login', params, function (result) {
                            if (result.success) {
                                window.location.reload();
                            }
                            else {
                                self.saveerrors = [];
                                result.errors.forEach(function (error) {
                                    self.saveerrors.push(error.value.toString());
                                });
                                if (!self.saveerrors.length) return true;
                            }
                        });
                        jqxhr.fail(function () {

                        });
                        self.$refs.loginform.reset();
                    } else {
                        // scroll to first error
                        const errors = Object.entries(this.$refs.loginform.refs)
                            .map(([key, value]) => ({
                                key,
                                value
                            }))
                            .filter(error => {
                                if (!error || !error.value || !error.value.failedRules) return false;
                                return Object.keys(error.value.failedRules).length > 0;
                            });

                        if (errors && errors.length > 0) {
                            this.$refs.loginform.refs[errors[0]['key']].$el.scrollIntoView();
                        }
                    }
                });
            },
        }
    }
</script>