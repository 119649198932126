<template>
    <div>
        <div v-if="hascancellationcover === false">
            <div id="no-cancellation-cover">

                <div class="col-sm-14">
                    <div @scroll="onScroll" id="cancellationAgreementForm" class="cancellation-agreement-form">
                        <div id="content">
                            <p class="cancel-order-agreement-form-margin">Declaration: </p>
                            <p class="cancel-order-agreement-form-margin">- Please confirm that you wish to cancel this order.</p>
                            <p class="cancel-order-agreement-form-margin">- As your order does not have Cancellation & Change cover, the cancellation of this order will result in {{currency + refundamount}} being credited to your Send My Bag account for future use.</p>
                            <p class="cancel-order-agreement-form-margin">- If you wish to proceed, please sign the box below and click confirm cancellation</p>
                            <p class="cancel-order-agreement-form-margin">- If you wish to proceed, please sign the box below and click confirm cancellation</p>
                            <p class="cancel-order-agreement-end-margin">© SendMyBag (NI) Ltd</p>
                        </div>
                    </div>
                </div>

                <div v-if="displaySignature">
                    <div class="font-italic my-2">Customers Signature*:</div>
                    <div class="cancel-order-signature-pad">
                        <SignaturePad ref="signaturePad" :senders-name="sendersName" class="signature-pad" v-on:blur.stop.prevent="savesign" />
                    </div>

                    <div class="text-right my-3">
                        <button type="button" 
                                class="btn btn-stylised fa fa-eraser fa_custom_r fa-custom-r-hover-effect fa-5x px-0 py-0 shadow-none"
                                title="Clear"
                                v-on:click.stop.prevent="clearSignature">
                        </button>
                    </div>
                </div>


                <div class="row cancel-order">
                    <div id="btnconfirmNoCancellationCover" v-on:click="confirmCancelOrderNoCover" class="col-sm-12 col-md-5 col-xl-9 mobile-button-text-center"><button type="submit" id="confirmCancellationBtnNoCover" disabled="disabled" class="btn btn-success btn-block px-1">Confirm Cancellation</button></div>
                    <div id="btncloseNoCancellationCover" v-on:click="clearSignature" class="col-sm-12 col-md-5 col-xl-3 col-lg-5 mobile-button-text-center"><button type="button" class="btn btn-outline-success button" data-dismiss="modal">Close</button></div>
                </div>

                <div class="error-container validation-summary-errors mx-sm-3" id="CancelOrderSignaturePadErrorMessage"></div>
            </div>
        </div>
        <div v-if="hascancellationcover === true">
            <div id="has-cancellation-cover">
                <div class="row cancel-order">
                    <div id="btnconfirmHasCancellationCover" v-on:click="confirmCancelOrderHasCover" class="col-sm-12 col-md-5 col-xl-9 mobile-button-text-center"><button type="submit" id="confirmCancellationBtnHasCover" class="btn btn-success btn-block px-1">Confirm Cancellation</button></div>
                    <div id="btncloseHasCancellationCover" class="col-sm-12 col-md-5 col-xl-3 col-lg-5 mobile-button-text-center"><button type="button" class="btn btn-outline-success button" data-dismiss="modal">Close</button></div>
                </div>
                <div class="error-container validation-summary-errors mx-sm-3" id="CancelOrderHasCoverErrorMessage"></div>
            </div>
        </div>
    </div>
</template>

<script>

    const $ = window.$;

    import SignaturePad from '../signature/SignaturePad.vue';
    
    export default {
        name: 'cancel-order',
        components: { SignaturePad },
        props: {
            orderid: Number,
            orderjourneyid: Number,
            hascancellationcover: Boolean,
            refundamount: Number,
            currency: String
        },
        data: function () {
            return {
                sendersName: '',
                signature: '',
                signaturePad: null,
                displaySignature: false
            };
        },
        methods: {
            onScroll: function (el) {
                if ((el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight) {
                    this.displaySignature = true;
                    this.resizeDialogCanvas();
                    $("#confirmCancellationBtnNoCover").prop("disabled", false);
                } else {
                    this.displaySignature = false;
                    $("#confirmCancellationBtnNoCover").prop("disabled", true);
                }
            },
            resizeDialogCanvas() {
                this.$nextTick(() => {
                    this.$refs.signaturePad.resizeCanvas();
                    this.clearSignature();
                })
            },
            clearSignature() {
                this.$refs.signaturePad.clearSignature();
            },
            savesign: function () {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                this.sendersName = data;
                if (isEmpty) {
                    $('#CancelOrderSignaturePadErrorMessage').show();
                    $('#CancelOrderSignaturePadErrorMessage').html('Please provide a signature');
                    //  resolve('fail!');
                    return isEmpty;
                }
                else {
                    //  resolve('Success!');
                    return isEmpty;
                }
            },
            confirmCancelOrderNoCover() {
                var self = this;

                var signed = Promise.resolve(self.savesign());

                var proceed = signed.then(function (value) {
                    if (value) {
                        return value;
                    }

                    var params = {
                        id: self.orderid,
                        sendersName: self.sendersName,
                        orderJourneyId: self.orderjourneyid,
                        hasCancellationCover: self.hascancellationcover
                    }
                    $.post('/en-gb/api/account/cancelorder', params, function (result) {
                        if (result.success) {
                            window.location.replace('/account');
                        } else {
                            $('#CancelOrderSignaturePadErrorMessage').html(result.errors);
                        }
                    });
                });

            },
            confirmCancelOrderHasCover() {
                var self = this;

                    var params = {
                        id: self.orderid,
                        orderJourneyId: self.orderjourneyid,
                        hasCancellationCover: self.hascancellationcover
                    }
                    $.post('/en-gb/api/account/cancelorder', params, function (result) {
                        if (result.success) {
                            window.location.replace('/account');
                        } else {
                            $('#CancelOrderHasCoverErrorMessage').html(result.errors);
                        }
                    });
            },
            mounted() {

            }
        }
    }


</script>