<template>
    <flat-pickr v-model="date"
                :config="generalConfig?configGeneral:config"
                class="form-control"
                placeholder="Select Date"
                :name="name">
    </flat-pickr>

</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    const $ = window.$;
    export default {
        name: 'datepicker',
        components: { flatPickr },
        props: {
            name: String,
            isDisabled: Boolean,
            minDate: Date,
            maxDate: Date,
            noCollection: Array,
            weekendDays: Array,
            value: Date,
            dateFormat: {
                type: String,
                default: 'Y-m-d'
            }
        },
        data: function () {
            return {
                date: this.value,
                config: {
                    wrap: false, // set wrap to true only when using 'input-group'
                    altFormat: 'D, J F Y',
                    altInput: true,
                    dateFormat: this.dateFormat,
                    allowInput: true,
                    minDate: this.minDate,
                    maxDate: this.maxDate,
                    disable: [],
                    monthSelectorType: "static",
                    onOpen: function (selectedDates, dateStr, instance) {
                        $(instance.altInput).prop('readonly', true);
                        $(".flatpickr-month").css("pointer-events", "none");
                        $(".numInput.cur-year").addClass("flatpickr-year-readonly");
                    },
                    onClose: function (selectedDates, dateStr, instance) {
                        $(instance.altInput).prop('readonly', false);
                        $(instance.altInput).blur();
                    }
                }
            }
        },
        methods: {
            updateFlatpickrConfig() {
                this.config.minDate = this.minDate;
                this.config.maxDate = this.maxDate;
                if (!this.noCollection) {
                    this.noCollection = [];
                }
                if (!this.weekendDays) {
                    this.weekendDays = [];
                }

                let _this = this;
                this.config.disable = this.noCollection.concat([
                    function (date) {
                        // return true to disable
                        if (_this.weekendDays.map(Number).includes(date.getDay())) {
                            return date;
                        }
                    }
                ]);
            }
        },

        watch: {
            $props: {
                handler() {
                    this.updateFlatpickrConfig();
                },
                deep: true,
                immediate: true,
            },
        }
    }
</script>

