<template>
    <div
        class="selectable-card mb-3 px-4 md-px-0"
        v-bind:id="elementId"
        v-bind:class="{ active: isActive }"
        v-on:click="$emit('input', customstype)"
    >
        <div class="card-body row">
            <div
                class="col-2 text-center d-flex align-items-center justify-content-center"
            >
                <i class="fa-fw fa-3x text-primary" v-bind:class="icon"></i>
            </div>
            <div class="col-10">
                <input
                    type="radio"
                    name="customs-customstype"
                    v-model.number="value"
                    :value="customstype"
                />
                <div>
                    <strong>{{ title }}</strong>
                </div>
                <div class="py-2"><slot></slot></div>
                <div>
                    <a
                        href="#"
                        v-on:click.stop.prevent="$emit('moreinfo', customstype)"
                        >More Info</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'basket-customs-type',
    props: {
        value: Number,
        customstype: Number,
        title: String,
        icon: String,
    },
    computed: {
        //----- Basic getters from store values
        isActive: function () {
            return this.value === this.customstype
        },
        elementId: function () {
            return 'customsType' + this.customstype
        },
    },
    created() {
        this.$successLog({
            description: 'Component - Basket Customs Type',
            outcome: 'Created',
        })
    },
}
</script>
