export default {
    state: {
        items: [],
        itemTypes: [],
        isMetric: Boolean,
        maxLength: Number,
        maxWeight: Number,
        maxVolumetricWeight: Number,
        maxLengthAndGirth: Number,
        oversizeWarningMax: Number,
        oversizeWarningMin: Number,
        oversizeWarningMessage: String,

        hasShownCopyItemMessage: false
    },

    mutations: {
        setInitialStateItems: function (state, payload) {
            state.items = payload.items;
            state.itemTypes = payload.itemTypes;
            state.isMetric = payload.isMetric;
            state.maxLength = payload.maxItemLength;
            state.maxWeight = payload.maxItemWeight;
            state.maxVolumetricWeight = payload.maxItemVolumetricWeight;
            state.maxLengthAndGirth = payload.maxItemLengthAndGirth;
            state.oversizeWarningMax = payload.oversizeWarningMax;
            state.oversizeWarningMin = payload.oversizeWarningMin;
            state.oversizeWarningMessage = payload.oversizeWarningMessage;
            //console.log(payload);
        },

        setIsMetric: function (state, payload) {
            state.isMetric = payload;
        },

        setItems: function (state, payload) {
            state.items = payload;
        },

        setHasShownCopyItemMessage: function (state, payload) {
            state.hasShownCopyItemMessage = payload;
        },

        setOversizeValues: function (state, payload) {
            state.oversizeWarningMin = payload.oversizeWarningMin;
            state.oversizeWarningMax = payload.oversizeWarningMax;
            state.oversizeWarningMessage = payload.oversizeWarningMessage;
        }
    },

    getters: {
        lengthUnitText: state => {
            return state.isMetric ? window.smbI18n.checkout.cm : window.smbI18n.checkout['inches-abbreviated'];
        },
        weightUnitText: state => {
            return state.isMetric ? window.smbI18n.checkout.kg : window.smbI18n.checkout.lb;
        },
        hasItems: state => {
            return state.items ? state.items.length > 0 : false;
        }
    },

    actions: {
        addItem: function ({ commit, rootState, dispatch }, payload) {
            commit('incrementRequest', 'addItem');

            var params = {
                model: payload,
                requestId: rootState.requestId.addItem,
            };
            var jqxhr = $.post('/en-gb/api/basketitemsapi/additem/', params, function (result) {
                let errors = result.errors ?? {}
                commit('setValidationErrors', errors);

                if (errors.length !== 0)
                    return

                dispatch('itemsChanged', { data: result });
            });

            jqxhr.fail(function (jqXHR, textStatus, errorThrown) {
                dispatch('showErrorToast');
            });

            return jqxhr;
        },

        updateOversizeValues: function ({ commit }, payload) {
            if (!payload)
                return;

            commit('setOversizeValues', payload);
        },

        removeItem: function ({ commit, rootState, dispatch }, index) {
            commit('incrementRequest', 'addItem');

            var params = {
                index: index,
                requestId: rootState.requestId.addItem,
            };
            var jqxhr = $.post('/en-gb/api/basketitemsapi/removeitem/', params, function (result) {
                dispatch('itemsChanged', { data: result });
            });

            jqxhr.fail(function (jqXHR, textStatus, errorThrown) {
                dispatch('showErrorToast');
            });

            return jqxhr;
        },
        itemsChanged: ({ commit, rootState, dispatch }, result) => {
            // Ensure this is the latest call that is being dealt with, we can ignore earlier calls
            if (result.data.requestId.toString() === rootState.requestId.addItem.toString()) {
                if (result.data.success && result.data.data) {
                    commit('setItems', result.data.data);
                    //dispatch('updateSurchargeExtraItems', { data: result.data.summarySurchargeItems }, { root: true })
                    //commit('SET_SUMMARY_ITEMS', result.data.summaryItems, { root: true })            
                    //dispatch('setTotalCost', null, { root: true })
                }
            }
        }
    }
};
