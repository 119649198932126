<template>
    <transition name="slide-in-out">
        <div v-if="shouldShow" class="side-overlay-window next-steps-widget">
            <div class="side-overlay-header">
                <h3>{{ metaValues.nextSteps }}</h3>
                <div class="close-next-steps-widget" @click="closeWidget">
                    <i class="fa fa-times-circle"></i>
                </div>
            </div>

            <div class="side-overlay-content next-steps-overlay-content">
                <div v-for="(journey, index) in nextStepsModel" :key="index" class="side-overlay-content-group">
                    <div>
                        {{ journey.originCountryName }} <i class="fa fa-arrow-right"></i> {{ journey.destinationCountryName }}
                    </div>
                    <div class="small"> {{ journey.collectionDateString }}</div>

                    <ul class="mt-2">
                        <li>{{ journey.currentStep }}</li>
                    </ul>

                    <div>
                        <a class="btn btn-success bg-white text-success w-100" :href="`${metaValues.url}?id=${journey.orderReference}`">
                            {{ metaValues.completeNow }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    const $ = window.$;

    export default {
        name: 'next-steps-widget',
        data: function () {
            return {
                shouldShow: false,
                nextShowAccessor: 'nextStepsWidgetNoShowDelay',
                nextStepsModel: [],
                metaValues: {}
            };
        },
        methods: {
            getNextSteps: function () {
                const self = this;

                $.getJSON('/en-gb/api/accountapi/nextsteps', result => {
                    if (result.success) {
                        self.metaValues = result.data.metaValues;
                        self.nextStepsModel = result.data.journeys;
                        self.shouldShow = result.data.showNextStepsBanner;
                    }
                });
            },
            closeWidget: function () {
                this.shouldShow = false;
                this.delayNextShow();
            },
            delayNextShow: function () {
                // Delay next display for 10 mins
                const nextShowTime = Date.now() + (10 * 60 * 1000);
                localStorage.setItem(this.nextShowAccessor, nextShowTime);
            }
        },
        created() {
            let nextShowTime = localStorage.getItem(this.nextShowAccessor);

            if (nextShowTime) {
                nextShowTime = JSON.parse(nextShowTime);

                if (nextShowTime > Date.now())
                    return;
                
                localStorage.removeItem(this.nextShowAccessor);
            }

            this.getNextSteps();
        }
    }
</script>