<template>
    <div class="card card-gray mb-3">
        <div class="card-header text-center">
            <div class="h2 mb-0">Cover</div>
            <div class="small">Add cover to your order to protect against unexpected loss</div>
        </div>
        <div class="card-body">
            <div class="form-group mb-2">
                <label for="essentials-losscover">Select the level of cover (per bag) you require</label>
                <validation-provider :rules="{ required: true }" v-slot="{ errors }" vid="essentials-losscover" name="Loss cover">
                    <select-2 id="essentials-losscover" class="form-control" style="width:100%;" placeholder="Please Select" data-minimum-results-for-search="Infinity"
                              :options="options"
                              :default-value-on-options-update="false"
                              v-model="lossCover">
                    </select-2>
                    <div class="text-danger">{{ errors[0] }}</div>
                </validation-provider>
            </div>
            <div class="small text-center" v-html="smbData.CMS.Essentials.LostCoverWarning"></div>
        </div>
    </div>
</template>
<script>
    import { ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', { ...required, message: 'The {_field_} is required' });

    export default {
        name: "loss-cover",
        components: {
            ValidationProvider
        },
        props: {
            options: Array
        },
        computed: {
            lossCover: {
                get: function () { return this.$store.state.essentials.lossCover; },
                set: function (value) { this.$store.commit('setLossCover', value); }
            },
        },
        watch: {
        //    lossCover: function (newValue) {
        //        if (newValue !== '') {
        //            let selectedOption = this.$store.getters.getSelectedLossCoverOption
        //            if (selectedOption !== undefined) {

        //                let description = selectedOption.text.substring(0, selectedOption.text.indexOf('(')).trim()
        //                this.$store.dispatch('addExtrasItem', {
        //                    productId: Number(newValue),
        //                    description: description,
        //                    extraPrice: selectedOption.priceIncTax,
        //                    extraPriceString: selectedOption.priceString,
        //                    oneTimeCharge: false,
        //                    extraType: 'LossCover'
        //                })
        //            }
        //        }                  
        //    },
        },
        created() {
            this.$successLog({ description: 'Component - Basket Essentials Loss Cover', outcome: 'Created'})
        },
    }
</script>