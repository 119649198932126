<template>
    <div v-show="hasAdditionalCollectionService">
        <h2>Collection Service</h2>
        <validation-provider :rules="{ required: hasAdditionalCollectionService }" mode="eager" v-slot="{ errors, classes }" name="Collection Service" v-id="collectionservice">
            <error-card v-if="errors && errors.length" :messages="['You must select a collection service']"></error-card>

            <div class="selectable-card-container row" v-bind:class="classes">
                <basket-collection-service v-model="collectionServiceId"
                                           :collection-service="0"
                                           :title="'Standard Collection (FREE)'"
                                           :bullet-points="[['9am - 6pm collection','No collection time can be offered and a driver will arrive at collection address between the hours of 9am and 6pm'],['Ground floor collection','']]"
                                           :disabled="!hasStandardCollectionService">
                </basket-collection-service>

                <basket-collection-service v-for="(service, index) in availableAdditionalServices" v-bind:key="service.vehicleScheduleDayId"
                                           v-model="collectionServiceId"
                                           :collection-service="service.vehicleScheduleDayId"
                                           :title="service.title + ' (' + service.priceIncTaxFormatted + ')'"
                                           :recommended="index === 0 ? 1 : 2"
                                           :bullet-points="service.bulletPoints">
                </basket-collection-service>
            </div>

            <input type="hidden" v-model="collectionServiceId" />
        </validation-provider>
    </div> 
</template>

<script>
    import { ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';
    import BasketCollectionService from './BasketCollectionService.vue';
    import ErrorCard from '../../../components/shared/ErrorCard.vue';
    import moment from 'moment';

    extend('required', { ...required, message: 'The {_field_} is required' });

    export default {
        name: 'collection-service-select',
        components: {
            ValidationProvider,
            BasketCollectionService,
            ErrorCard
        },
        props: {
            value: String,
            collectionDate: String,
            dateParams: {
                weekendDays: [],
                noCollectionDays: [],
                additionalCollectionServices: []
            },
        },
        computed: {
            collectionServiceId: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            collectionDateDay: function () {
                return moment(this.collectionDate, 'YYYY-MM-DD', true).toDate().getDay();
            },

            availableAdditionalServices: function () {
                if (!this.hasAdditionalCollectionService)
                    return [];

                return this.dateParams.additionalCollectionServices.filter((serviceVal) => serviceVal.date === this.collectionDate);
            },

            hasStandardCollectionService: function () {
                // Check for no collections dates
                if (this.dateParams.noCollectionDays.includes(this.collectionDate)) {
                    return false;
                }

                // Check weekend dates
                if (this.dateParams.weekendDays.includes(this.collectionDateDay)) {
                    return false;
                }

                // not blocked so standard collection service is available
                return true;
            },
            hasAdditionalCollectionService: function () {
                // Check for additional collection services
                if (this.dateParams.additionalCollectionServices.some((serviceVal) => serviceVal.date === this.collectionDate)) {
                    return true;
                }

                return false;
            }
        },
        created() {
            this.$successLog({ description: 'Component - collection service select Component', outcome: 'Created' })
        }
    }
</script>