 <template>
    <select v-bind:name="name"  autocomplete='off' class="form-control"><slot></slot></select>
</template>
<script>

    import  select2Mixin  from '../mixins/select-2-mixin'
    const $ = window.$;
    export default { 
            mixins: [select2Mixin],
          //  template: '<select v-bind:name="name" class="form-control"><slot></slot></select>',
            mounted: function () {
                var vm = this;
                $(this.$el)
                    // init select2
                    .select2({
                        data: this.options,
                        placeholder: this.placeholder,
                        matcher: this.customMatcher
                    })
                    .val(this.value)
                    .trigger('change')
                    // emit event on change.
                    .on('change', function () {
                        vm.$emit('input', this.value);
                    });
            },
            methods: {
                optionsWatcher: function (options) {
                    // update options
                    $(this.$el).empty().select2({
                        data: options,
                        placeholder: this.placeholder,
                        matcher: this.customMatcher
                    });

                    $(this.$el)
                        .val(this.value)
                        .trigger('change');
                },

                customMatcher: function (params, data) {
                    // If there are no search terms, return all of the data           

                    if ($.trim(params.term) === '') {
                        return data;
                    }

                    // Skip if there is no 'children' property
                    if (typeof data.children === 'undefined') {
                        return null;
                    }

                    // Get the filtered list of children
                    var filteredChildren = data.children.filter(function (value) {
                        var alt = '';
                        if (window.smbData && window.smbData.countries && window.smbData.countries[value.id] && window.smbData.countries[value.id].Alt) {
                            alt = window.smbData.countries[value.id].Alt;
                        }

                        return value.text.toUpperCase().indexOf(this.term.toUpperCase()) >= 0
                            || value.id.toUpperCase().indexOf(this.term.toUpperCase()) >= 0
                            || alt.toUpperCase().indexOf(this.term.toUpperCase()) >= 0;
                    }, params);

                    // If we matched any of the timezone group's children, then set the matched children on the group
                    // and return the group object
                    if (filteredChildren.length) {
                        var modifiedData = $.extend({}, data, true);
                        modifiedData.children = filteredChildren;

                        // You can return modified objects from here
                        // This includes matching the `children` how you want in nested data sets
                        return modifiedData;
                    }

                    // Return `null` if the term should not be displayed
                    return null;
                }
            } 

    }
</script>

 