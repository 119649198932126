<template>
    <div class="card card-gray mb-3">
        <div class="card-header text-center">
            <div class="h2 mb-0">Tracking</div>
            <div class="small">
                {{smbData.CMS.Essentials.Tracking}}
            </div>
        </div>
        <div class="card-body">
            <phone-number v-model="smsTrackingNumber" :label="labelText" :countries="phoneNumberCountries" :hideDriverMessage="true"></phone-number>
        </div>
    </div>
</template>
<script>
    import PhoneNumber from '../addresses/PhoneNumber.vue';

    export default {
        name: "sms-tracking",
        components: {
            PhoneNumber
        },
        props: {
            phoneNumberCountries: Array,
            priceFormatted: String,
        },
        computed: {
            smsTrackingNumber: {
                get: function () { return this.$store.state.essentials.smsTrackingNumber; },
                set: function (value) { this.$store.commit('setSmsTrackingNumber', value); }
            },
            labelText: function () {
                return 'Phone Number ' + this.priceFormatted + ' (optional)';
            }
        },
        watch: {
        //    trackingPhoneNumber: _.debounce(function (newValue) {
        //        if (newValue !== '')
        //            this.$store.dispatch('addExtrasItem', {
        //                description: 'SMS Tracking',
        //                extraPrice: this.$store.state.essentials.trackingCost,
        //                extraPriceString: this.$store.state.essentials.trackingCostString,
        //                oneTimeCharge: true,
        //                extraType: 'SMSTracking'
        //            })
        //        else
        //            this.$store.dispatch('removeExtrasItem', { extraType: 'SMSTracking' })
        //    }, 300)
        },
        created() {
            this.$successLog({ description: 'Component - Basket Essentials Tracking', outcome: 'Created' })
        },
    }
</script>