<template>
    <div class="card card-gray mb-3">
        <div class="card-header text-center">
            <div class="h2 mb-0">Confirm</div>
        </div>
        <div class="card-body">
            <div class="form-group">
                <div class="custom-control px-0 custom-checkbox mb-3"
                     v-for="(term) in terms"
                     :key="term">
                    <validation-provider :rules="{ checkboxCustomErrorMessage: term.errorText }" v-slot="{ errors }">
                        <input class="custom-control-input pull-right" type="checkbox"
                               v-bind:id="'term-' + term.id"
                               v-bind:value="term.id"
                               v-model="term.confirmed">
                        <label class="custom-control-label"
                               v-bind:for="'term-' + term.id"
                               v-html="term.text">
                        </label>
                        <route-alert v-if="term.subText"
                                     :default-alert-messages="term.subText"
                                     :default-destination-name="destination"
                                     :confirm-order-alerts="true">
                        </route-alert>
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        name: 'basket-confirmation-terms-and-conditions',
        components: {
            ValidationProvider,
        },
        data: function () {
            return {
                terms: [],
                isLoading: true
            }
        },
        computed: {
            isValid: function () {
                // If still loading then it is not valid
                if (this.isLoading)
                    return false;

                // If no terms then automatically valid
                if (!this.terms)
                    return true;

                // Do we have any that are not confirmed?
                if (this.terms.some(e => !e.confirmed)) {
                    return false;
                }

                return true;
            },

        //    terms: {
        //        get() { return this.$store.state.confirmation.terms ?? [] }
        //    },
        },
        watch: {
            isValid: function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$emit('isvalidchanged', newValue);
                }
            }
        },
        methods: {
            loadTerms: function () {
                this.$store.dispatch('infoLog', { description: 'Action - loadTerms', outcome: 'Initialised' });

                const _this = this;
                _this.isLoading = true;

                var jqxhr = $.getJSON('/en-gb/api/basketapi/gettermsjsonasync/', function (result) {
                    _this.terms = result.data;
                });
                jqxhr.fail(function (errors) {
                    _this.$store.dispatch('errorLog', { description: 'Action - loadEssentialsData failed with errors', outcome: 'Error' });
                    _this.$store.dispatch('showErrorToast');
                });
                jqxhr.always(() => {
                    _this.isLoading = false;
                });
            },
        },
        created() {
            this.$successLog({ description: 'Component - Basket Confirmation Terms and Conditions', outcome: 'Created' });
            this.loadTerms();
        },
    }
</script>