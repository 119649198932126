 <template>
     <div v-if="messagesToDisplay && messagesToDisplay.length" class="alert alert-danger" role="alert">
         <div v-if="showTitle" class="col-12 col-md-11 text-center text-md-left">
             <h3>
                 {{ title }}
             </h3>
         </div>
         <div class="row p-2" v-for="message in messagesToDisplay" v-bind:key="message">
             <div class="col-12 col-md-1 text-center text-md-right p-0"><i class="fas fa-exclamation-triangle fa-lg pt-1"></i></div>
             <div class="col-12 col-md-11 text-center text-md-left" v-html="message"></div>
         </div>
     </div>
</template>
<script> 
    export default {
        name: 'errorcard',
        props: {
            messages: [],
            title: {
                required: false,
                default: undefined
            }
        },
        computed: {
            messagesToDisplay: function () {
                if (!(this.messages && this.messages.length)) {
                    return null;
                }

                return this.messages.filter(function (el) {
                    return el && el.length;
                });
            },
            showTitle: function () {
                return this.title !== undefined;
            }
        }
    }
</script>