<template>
    <div>
        <h2>Your Order</h2>
        <div v-if="isCustomsRoute">
            <div class="mb-3">Please select how you would like your shipment declared to customs:</div>

            <validation-provider rules="required" v-slot="{ errors, classes }" name="Customs Type" v-id="customs-type">
                <error-card v-if="errors && errors.length" :messages="['You must select how you would like you shipment to be declared to customs']"></error-card>

                <div class="selectable-card-container" v-bind:class="classes">
                    <basket-customs-type v-model="customsTypeSelection" :customstype="1" :title="smbData.CMS.Items.PersonalEffects.Header" icon="fad fa-suitcase" v-on:moreinfo="showPersonalEffectsModal('personaleffectsmodal', true)">{{smbData.CMS.Items.PersonalEffects.Intro}}</basket-customs-type>
                    <basket-customs-type v-model="customsTypeSelection" :customstype="100" :title="smbData.CMS.Items.PersonalEffectsOther.Header" icon="fad fa-user-friends" v-on:moreinfo="showPersonalEffectsModal('personaleffectsmodal', false)">{{smbData.CMS.Items.PersonalEffectsOther.Intro}}</basket-customs-type>
                    <warning-card v-show="isSomeoneElses" :messages="[smbData.CMS.Items.PersonalEffects.ExtraContent]"></warning-card>
                    <basket-customs-type v-model="customsTypeSelection" :customstype="2" :title="smbData.CMS.Items.GiftDetail.Header" icon="fad fa-gifts" v-on:moreinfo="showModel('giftdetailmodal')">{{smbData.CMS.Items.GiftDetail.Intro}}</basket-customs-type>
                    <basket-customs-type v-model="customsTypeSelection" :customstype="3" :title="smbData.CMS.Items.CommercialItems.Header" icon="fad fa-shipping-fast" v-on:moreinfo="showModel('commercialitemsmodal')">{{smbData.CMS.Items.CommercialItems.Intro}}</basket-customs-type>
                </div>

                <input type="hidden" v-model="customsType" />
            </validation-provider>


            <div v-if="isCustomsRoute" class="mt-3">
                <h2>Customs</h2>

                <div v-if="customsType >= 1">
                    <div class="row half-gutters">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="customs-description" v-show="customsType === 1">Enter a description of what you are sending (e.g. 'Books, baby clothes & kitchen utensils', you do not need to list everything but do not over generalise e.g. 'personal effects' would not be accepted)</label>
                                <label for="customs-description" v-show="customsType !== 1">Enter a description of what you are sending (e.g. 'Remote control car, children's books & baby clothes', do not over generalise e.g. entering only 'Gifts' or 'Toys' would not be accepted)</label>
                                <validation-provider :rules="{ required: true, max: customsDescriptionMaxLength }" v-slot="{ errors, classes }" name="Description" v-id="customs-description">
                                    <input type="text" class="form-control mt-2" v-bind:class="classes" id="customs-description" v-model="customsDescription" placeholder="Books, baby clothes & kitchen utensils" />
                                    <div class="text-danger">{{ errors[0] }}</div>
                                </validation-provider>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-12">
                        <validation-provider :rules="{ required: true, between: [customsValueMin, customsValueMax] }" v-slot="{ errors, classes }" :name="smbData.CMS.Items.CustomsValue.Header" v-id="customs-value">
                            <label for="customs-customsvalue">{{ smbData.CMS.Items.CustomsValue.Header }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend" v-bind:class="classes">
                                    <span class="input-group-text">{{ currencySymbol }}</span>
                                </div>
                                <input type="number" inputmode="decimal" class="form-control" v-bind:class="classes" id="customs-customsvalue" v-model.number="customsValue" min="0" step="any" placeholder="0.00" />
                            </div>
                            <div><a href="#" v-on:click.stop.prevent="showModel('customsvaluemodal')">{{ smbData.CMS.Items.CustomsValue.LinkText }}</a></div>
                            <div class="text-danger" v-if="errors && errors.length">
                                <span v-if="customsType == 1">{{ customsValueErrorPersonal }}</span>
                                <span v-else>{{ customsValueErrorOther }}</span>
                            </div>
                        </validation-provider>
                    </div>
                </div>
            </div>

            <generic-modal id="personaleffectsmodal" :title="personalEffectsModalTitle">
                <div v-html="smbData.CMS.Items.PersonalEffects.Content"></div>
                <h6 v-show="smbData.CMS.Items.PersonalEffects.CountryHeader">{{smbData.CMS.Items.PersonalEffects.CountryHeader}}</h6>
                <div v-html="smbData.CMS.Items.PersonalEffects.CountryContent"></div>
            </generic-modal>
            <generic-modal id="commercialitemsmodal" :title="smbData.CMS.Items.CommercialItems.Header">
                <div v-html="smbData.CMS.Items.CommercialItems.Content"></div>
                <h6 v-show="smbData.CMS.Items.CommercialItems.CountryHeader">{{smbData.CMS.Items.CommercialItems.CountryHeader}}</h6>
                <div v-html="smbData.CMS.Items.CommercialItems.CountryContent"></div>
            </generic-modal>
            <generic-modal id="giftdetailmodal" :title="smbData.CMS.Items.GiftDetail.Header">
                <div v-html="smbData.CMS.Items.GiftDetail.Content"></div>
                <h6 v-show="smbData.CMS.Items.GiftDetail.CountryHeader">{{smbData.CMS.Items.GiftDetail.CountryHeader}}</h6>
                <div v-html="smbData.CMS.Items.GiftDetail.CountryContent"></div>
            </generic-modal>
            <generic-modal id="customsvaluemodal" :title="smbData.CMS.Items.CustomsValue.ModalHeader" :content="smbData.CMS.Items.CustomsValue.Content"></generic-modal>
            <generic-modal id="highcustomsvaluemodal" :title="smbData.CMS.Items.CustomsValue.HighCustomsValue.Header" :content="smbData.CMS.Items.CustomsValue.HighCustomsValue.Content" :hideCloseIcon="true" :closeButtonText="smbI18n.checkout.IAgree"></generic-modal>
        </div>
        <div v-else class="row half-gutters">
            <div class="col-12">
                <div class="form-group">
                    <label for="customs-description">Enter a description of what you are sending (e.g. 'Books, baby clothes & kitchen utensils', you do not need to list everything but do not over generalise e.g. 'personal effects' or 'gifts' would not be accepted)</label>
                    <validation-provider :rules="{ required: true, max: customsDescriptionMaxLength }" v-slot="{ errors, classes }" name="Description" v-id="customs-description">
                        <input type="text" class="form-control mt-2" v-bind:class="classes" id="customs-description" v-model="customsDescription" placeholder="Books, baby clothes & kitchen utensils" />
                        <div class="text-danger">{{ errors[0] }}</div>
                    </validation-provider>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$;
    import BasketCustomsType from './BasketCustomsType.vue';
    import WarningCard from '../../../components/shared/WarningCard.vue';
    import ErrorCard from '../../../components/shared/ErrorCard.vue';
    import { ValidationProvider, extend, setInteractionMode } from 'vee-validate';
    import { required, max, between } from 'vee-validate/dist/rules';

    setInteractionMode('eager');
    extend('required', { ...required, message: '{_field_} is required' });
    extend('max', { ...max, message: '{_field_} must be less than {length} characters long' });
    extend('between', { ...between, message: '{_field_} must be between {min} and {max}' });

    export default {
        name: 'basket-customs',
        components: {
            ValidationProvider,
            ErrorCard,
            WarningCard,
            BasketCustomsType
        },
        data: function () {
            return {
                personalEffectsModalTitle: ''
            };
        },
        computed: {
            //----- Basic getters from store values
            isCustomsRoute: function () { return this.$store.state.customs.isCustomsRoute; },
            customsType: function () { return this.$store.state.customs.customsType; },
            currencySymbol: function () { return this.$store.state.currencySymbol; },
            customsValueErrorPersonal: function () { return this.$store.state.customs.customsValueErrorPersonal; },
            customsValueErrorOther: function () { return this.$store.state.customs.customsValueErrorOther; },
            customsValueMin: function () { return this.$store.state.customs.customsValueMin; },
            customsValueMax: function () {
                // Don't want to have a max customs value for non personal effects
                if (this.customsType > 1)
                    return 9999000;

                return this.$store.state.customs.customsValueMax;
            },
            customsDescriptionMaxLength: function () {
                // Description length varies because of prepended text based on the customs type
                switch (this.customsType) {
                    case 2:
                        return 83;
                    case 3:
                        return 90;
                    default:
                        return 57;
                }
            },
            isSomeoneElses: function () {
                return this.$store.state.customs.isSomeoneElses ?? false;
            },

            //----- Two way binding of store values
            customsTypeSelection: {
                get: function () {
                    if (this.$store.state.customs.customsType === 1 && this.$store.state.customs.isSomeoneElses) {
                        return 100;
                    }

                    return this.$store.state.customs.customsType
                },
                set: function (value) {
                    if (value === 100) {
                        this.$store.commit('setSomeoneElses', true)
                        this.$store.commit('setCustomsType', 1)
                    } else {
                        this.$store.commit('setSomeoneElses', false)
                        this.$store.commit('setCustomsType', value)
                    }
                }
            },
            customsDescription: {
                get: function () { return this.$store.state.customs.customsDescription },
                set: function (value) { this.$store.commit('setCustomsDescription', value) }
            },
            customsValue: {
                get: function () { return this.$store.state.customs.customsValue },
                set: function (value) { this.$store.commit('setCustomsValue', value) }
            },
        },
        watch: {
            customsValue: function () {
                this.checkForValueWarningThreshold();
            },
            customsType: function () {
                this.checkForValueWarningThreshold();
            }
        },
        methods: {
            showModel: function (model) {
                $('#' + model).modal('show');
            },
            showModelAgree: function (model) {
                $('#' + model).modal({ backdrop: 'static', keyboard: false });
            },
            showPersonalEffectsModal: function (model, isOwn) {
                let title = smbData.CMS.Items.PersonalEffects.Header;

                if (isOwn === false)
                    title = smbData.CMS.Items.PersonalEffectsOther.Header;

                this.personalEffectsModalTitle = title;
                this.showModel(model);
            },
            checkForValueWarningThreshold: function () {
                if (this.customsType === 1 && this.$store.state.customs.customsValueWarningThreshold > 0 && this.customsValue >= this.$store.state.customs.customsValueWarningThreshold) {
                    this.showModelAgree('highcustomsvaluemodal');
                }
            }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Customs', outcome: 'Created' })
        },
    }
</script>

