<template>
    <div>
        <slot>
            <div class="form-group form-row mb-0 my-md-3">
                <label for="headerQuoteOrigin" v-bind:class="[quickQuote ? ' col-12 ' : ' col-md-3 col-lg-3 col-xl-2 col-form-label'] ">From:</label>
                <div v-bind:class="[quickQuote ? ' col-12 ' : ' col-md-9 col-lg-9 col-xl-10 '] ">
                    <country-select id="headerQuoteOrigin" 
                                    name="OriginIso" 
                                    placeholder="e.g. United Kingdom" 
                                    :countries="originCountries" 
                                    :default-selected-iso="originIso" 
                                    :key="originIso" 
                                    :isLoading="isLoadingOrigin" 
                                    :isEnabled="isEnabledOrigin" 
                                    v-on:valueChanged="originIsoChanged"></country-select>
                </div>
            </div>
            <div class="form-group form-row">
                <label for="headerQuoteDestination" v-bind:class="[quickQuote ? ' col-lg-12  col-xl-2 col-form-label ' : ' col-md-3 col-lg-3  col-xl-2 col-form-label ']">To:</label>
                <div v-bind:class="[quickQuote ? ' col-12 ' : ' col-md-9 col-lg-9 col-xl-10 '] ">
                    <country-select id="headerQuoteDestination" 
                                    name="DestinationIso" 
                                    placeholder="e.g. Spain" 
                                    :countries="destinationCountries" 
                                    :default-selected-iso="destinationIso" 
                                    :isLoading="isLoadingDestination" 
                                    :isEnabled="isEnabledDestination">
                    </country-select>
                    
                    <input v-if="!isEnabledDestination && isForDestination" type="hidden" name="DestinationIso" :value="destinationIso" />
                </div>
            </div>
            <div class="form-row">
                <div v-bind:class="[quickQuote ? ' col-12   ' : ' col-md-3 col-lg-3  col-xl-2'] "></div>
                <div v-bind:class="[quickQuote ? ' col-12 text-center ' : ' col-md-9 col-lg-9  col-xl-10 ']">
                    <button id="route-select-submit" type="submit" v-bind:class="[quickQuote ? 'btn btn-success  btn-price' :  'btn btn-success w-100 btn-price']">Show Prices</button>
                </div>
            </div>
            <div class="form-row">
                <div v-bind:class="[quickQuote ? ' col-12   ' : ' col-md-3 col-lg-3  col-xl-2'] "></div>
                <div v-show="routeMessage" v-bind:class="[quickQuote ? ' col-12 text-center ' : ' col-md-9 col-lg-9  col-xl-10 ']" class="alert alert-warning text-danger mt-4 " role="alert">
                    <div class="row pt-1">
                        <div class="col-12 text-center pb-2">
                            <i class="fas fa-exclamation-circle fa-lg"></i>
                        </div>
                        <div class="col-12 text-center">
                            <div class="mb-2">
                                <p>
                                    At this time we do not operate a service from {{originName}} to {{destinationName}}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
    import CountrySelect from '../routing/CountrySelect.vue';
    import routeSelectMixin from '../mixins/route-select-mixin';
    export default {
        name: 'routeselect',
        mixins: [routeSelectMixin],
        components: { CountrySelect },
        props: {
            defaultOriginIso: String,
            defaultDestinationIso: String,
            quickQuote: Boolean
        },
        data: function () {
            return {
                originIso: this.defaultOriginIso,
                isLoadingDestination: this.defaultDestinationIso
            };
        },
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
