<template>
    <div class="card-body" v-if="discountAmount">
        <div class="font-weight-bold">Discount</div>
        <div class="row">
            <div class="col-8">{{ discountDescription }}</div>
            <div class="col-4 text-right text-nowrap">-{{ discountAmount }}</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'basket-summary-discount',
        computed: {
            discountAmount: function () {
                return this.$store.state.summary.data.discountAmount;
            },
            discountDescription: function () {
                return this.$store.state.summary.data.discountDescription;
            },
        },
        created() {
            this.$successLog({ description: 'Component - Basket Summary Discount', outcome: 'Created' });
        }
    }
</script>
