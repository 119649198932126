 <template>
    <div>
        <button type="button" class="btn btn-stylised px-0 py-0 mt-2" id="btnUpdateLossCover" data-target="#Smb__upgradelosscover-modal" data-toggle="modal" v-on:click="getLossCover()">Upgrade Loss Cover</button>
        <div id="Smb__upgradelosscover-modal" class="modal fade" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content card card-body">
                        <div class="modal-body">
                            <div class="row">
                                <h2>Upgrade Loss Cover</h2>
                            </div>
                            <div class="row mt-2">
                                <p>You may upgrade to a higher level of loss cover before your items are collected, your payment will be taken on the next page.</p>
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-5 px-0">
                                    <select id="ddlUpgradeLossCover" class="form-control">
                                        <option value="0" selected>Please Select</option>
                                        <option v-for="option in availableOptions" :value="option.value" :key="option"> {{ option.text }} </option>
                                    </select>
                                </div>
                            </div>
                            <div id="UpgradeLossCoverErrorMsg" class="text-danger mt-2 row"></div>
                            <div class="row mt-4">
                                <div class="col-6 col-lg-8 col-md-6 col-sm-2 col-xl-7 text-right py-0"><button id="cancelUpgradeLossCover" type="button" class="btn btn-outline-success" data-dismiss="modal">Cancel</button></div>
                                <div class="col-6 col-lg-1 col-md-6 col-sm-2 col-xl-5 px-0"><button id="btnUpgradeLossCover" type="button" class="btn btn-success" :disabled="buttonDisabled" v-on:click="upgradeLossCover()">Confirm</button></div>
                            </div>
                        </div> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    const $ = window.$;
    export default {
        name: "cust-upgradelosscover",
        props: {
            journeyId: String,
            orderReference: String
        },
        data: function () {
            return {
                availableOptions: [],
                postOrderAvailableOptions: [{

                }],
                postOrderPrice: Boolean,
                selectedOption: null,
                buttonDisabled: false
            };
        },
      //  template: `#component--upgradelosscover`,
        methods: {
            getLossCover: function () {
                var self = this;
                var params = {
                    orderId: this.journeyId
                }
                 $.getJSON('/en-gb/api/account/upgradelosscoverdialog', params, function (data) {
                    self.availableOptions = data.data.availableOptions;
                    self.postOrderAvailableOptions = data.data.postOrderAvailableOptions;
                    self.postOrderPrice = data.data.postOrderPrice;
                });
            },
            upgradeLossCover: function () {
                if ($('#ddlUpgradeLossCover').val() == 0) {
                    $('#UpgradeLossCoverErrorMsg').html('Please select Loss Cover');
                }
                else {
                    this.buttonDisabled = true;
                    var self = this;
                    var params = {
                        OrderId: this.journeyId,
                        LossCoverProductId: $("#ddlUpgradeLossCover").val(),
                        PostOrderPrice: false
                    }
                    var token = document.getElementById("frmUpgradeLossCover").elements.namedItem("__RequestVerificationToken").value;
                    var model = {
                        __RequestVerificationToken: token, model: params
                    }
                     $.post('/en-gb/api/account/upgradelosscover', model, function (response) {
                        if (response.success) {
                            window.location.replace('/account/orderdetails/?Id=' + self.orderReference);
                        }
                        else {
                            $('#UpgradeLossCoverErrorMsg').html(response.errors);
                         }
                         self.buttonDisabled = false;
                    });
                }
               
            }
        }
    }
</script>

 