<template>
    <div class="horizontal-scroll">
        <div class="scroll-left" v-bind:class="{ disabled: isAtStart }"
             @click="!isAtStart && clickToScroll(-100)" v-show="hasOverflow">
            &#10094;
        </div>
        <div class="horizontal-scroll-card-section" v-bind:class="{ overflow: hasOverflow }"
             ref="scrollContainer" @mousedown="onMouseDown" @scroll="hasOverflow && onScroll()">
            <slot></slot>
        </div>
        <div class="scroll-right" v-bind:class="{ disabled: isAtEnd }"
             @click="!isAtEnd && clickToScroll(100)" v-show="hasOverflow">
            &#10095;
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'horizontal-scroll',
        data() {
            return {
                resizeObserver: {},
                drag: {
                    clickedXPosition: 0,
                    originLeft: 0
                },
                scroll: {
                    width: 0,
                    offsetWidth: 0,
                    left: 0
                }
            }
        },
        computed: {
            hasOverflow() {
                return this.scroll.offsetWidth < this.scroll.width;
            },
            isAtStart() {
                return this.scroll.left === 0;
            },
            isAtEnd() {
                return Math.round(this.scroll.left) === this.scroll.width - this.scroll.offsetWidth;
            }
        },
        methods: {
            onMouseDown(e) {
                if (this.hasOverflow) {
                    const scrollContainer = this.$refs.scrollContainer;
                    this.drag.originLeft = scrollContainer.scrollLeft;
                    this.drag.clickedXPosition = e.clientX;
                    scrollContainer.classList.add('dragging');

                    window.addEventListener("mousemove", this.onMouseMove);
                    window.addEventListener("mouseup", this.onMouseUp);
                }
            },
            onMouseUp() {
                const scrollContainer = this.$refs.scrollContainer;
                scrollContainer.classList.remove('dragging');

                window.removeEventListener("mouseup", this.onMouseUp);
                window.removeEventListener("mousemove", this.onMouseMove);
            },
            onMouseMove(e) {
                const scrollContainer = this.$refs.scrollContainer;
                const currentMouseXPosition = e.clientX;

                const xDraggedDistance = currentMouseXPosition - this.drag.clickedXPosition;
                scrollContainer.scrollLeft = this.drag.originLeft - xDraggedDistance;
            },
            clickToScroll(amount) {
                const el = this.$refs.scrollContainer;
                el.scrollLeft += amount;
            },
            onScroll: _.debounce(function () {
                this.updateScrollValues();
            }, 100, { 'leading': true }),
            updateScrollValues() {
                const el = this.$refs.scrollContainer;

                this.scroll.offsetWidth = el.offsetWidth;
                this.scroll.width = el.scrollWidth;
                this.scroll.left = el.scrollLeft;
            },
            observeWidth(element) {
                this.resizeObserver = new ResizeObserver(this.updateScrollValues);
                this.resizeObserver.observe(element);
            }
        },
        mounted() {
            this.observeWidth(this.$refs.scrollContainer);
        },
        beforeDestroy() {
            this.resizeObserver.unobserve(this.$refs.scrollContainer);
        }
    };
</script>