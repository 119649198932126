<template>
    <div>
        <button type="button" class="btn btn-stylised px-0 py-0 mb-3"
                v-on:click.stop.prevent="previousStep" 
                v-bind:disabled="isLoading">
            <i class="fa fa-angle-left text-body" aria-hidden="true"></i> Back
        </button>
    </div>
</template>
<script>
    const $ = window.$;
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'basket-back-button',
        computed: {
            ...mapState(['step']),
        },
        methods: {
            ...mapActions({ previousStep: 'stepBack' })
        },
        created() {
            this.$successLog({ description: 'Component - Basket Back Button Component', outcome: 'Created' })
        },

    }
</script>