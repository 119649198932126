<template>
    <div>
        <h1>Confirm your Order</h1>
        <validation-observer v-slot="{ handleSubmit }">
            <basket-confirmation-terms-and-conditions v-on:isvalidchanged="isValidChanged"></basket-confirmation-terms-and-conditions>
            <div class="card card-gray mb-3">
                <div class="card-body" v-if="isSummaryLoaded">
                    <payment :cid="cid"
                             :unique-id="uid"
                             :basket-id="bid"
                             :culture-code="cultureCode"
                             :submitting-payment="paymentFormReady"
                             :show-button="true"
                             :new-order="true"
                             :button-validation-handler="handleSubmit"
                             :total-to-pay="totalToPay"
                             :currency-code="currencyCode"
                             :form-valid="isTermsValid">
                    </payment>
                </div>
            </div>
        </validation-observer>
    </div>
</template>
<script>
    import BasketConfirmationTermsAndConditions from './BasketConfirmationTermsAndConditions.vue';

    export default {
        name: "basket-confirmation",
        components: {
            BasketConfirmationTermsAndConditions,
        },
        data: function () {
            return {
                isTermsValid: false,
                totalToPay: 0.0,
                currencyCode: 'GBP',
                isSummaryLoaded: false
            }
        },
        computed: {
            originAddress: {
                get() { return this.$store.state.addresses.origin.line1 }
            },
            useCollectionAddressForBilling: {
                get() { return this.$store.state.confirmation.useCollectionAddressForBilling },
                set(value) { this.$store.commit('setUseCollectionAddressForBilling', value) }
            },
            showBillingAddressForm: {
                get() { return this.useCollectionAddressForBilling === false }
            },
            bid: function () { return this.$store.state.confirmation.bid; },
            cid: function () { return this.$store.state.confirmation.cid; },
            uid: function () { return this.$store.state.confirmation.uid; },
            cultureCode: function () { return this.$store.state.cultureCode; },
            paymentFormReady: function () { return this.$store.state.paymentFormReady; }
        },
        methods: {
            isValidChanged: function (value) {
                this.isTermsValid = value;
            },
        },
        created() {
            this.$infoLog({ description: 'Component - Basket Confirmation', outcome: 'Initialised' });

            // Need to load the basket summary to get the price and currency code
            const _this = this;
            var jqxhr = $.getJSON('/en-gb/api/basketsummaryapi/getsummary/', function (result) {
                if (result.success) {
                    _this.totalToPay = result.summary.totalToPayValue;
                    _this.currencyCode = result.summary.currencyCode;
                    _this.isSummaryLoaded = true;
                }
            });
        },
    }
</script>