<template>
    <div>
        <h1>{{smbData.CMS.Addresses.Headings.DestinationAddressHeading}}</h1>
        <saved-addresses v-model="destinationId" :addresses="destinationSavedAddresses"></saved-addresses>
        <journey-address v-model="destinationAddress" 
                         :addressFieldFlags="addressFieldFlags" 
                         :addressTypes="addressTypes" 
                         :phoneNumberCountries="phoneNumberCountries" 
                         :countryDetails="destinationCountry"
                         :has-selected-saved-address="hasSelectedSavedAddress"
                         @selected-saved-address="savedAddressSelected"
                         ref="journeyAddress"></journey-address>
    </div>
</template>
<script>
    import JourneyAddress from './addresses/JourneyAddress.vue';
    import SavedAddresses from './addresses/SavedAddresses.vue';
    import { mapState } from 'vuex';

    export default {
        name: 'basket-step3',
        components: {
            JourneyAddress,
            SavedAddresses,
        },
        data: function () {
            return {
                hasSelectedSavedAddress: false
            };
        },
        computed: {
            ...mapState({
                addressFieldFlags: state => state.addresses.addressFieldFlags,
                addressTypes: state => state.addresses.addressTypes,
                phoneNumberCountries: state => state.addresses.phoneNumberCountries,
                destinationCountry: state => state.addresses.destinationCountry,
                destinationSavedAddresses: state => state.addresses.destinationSavedAddresses,
            }),

            destinationAddress: {
                get: function () { return this.$store.state.addresses.destination },
                set: function (value) { this.$store.commit('setDestination', value) }
            },

            destinationId: {
                get: function () { return this.$store.state.addresses.destination.id },
                set: function (value) {
                    let address = this.destinationSavedAddresses.filter(obj => {
                        return obj.id === value;
                    });

                    if (address) {
                        this.hasSelectedSavedAddress = true;
                        this.$store.commit('setDestination', address[0]);
                    }
                }
            }
        },
        methods: {
            setGetDestinationVisibleFieldValues: function () {
                const value = this.$refs.journeyAddress.getShownAddressFieldValues;
                this.$store.commit('setGetDestinationVisibleFieldValues', value);
            },
            savedAddressSelected: function () {
                this.hasSelectedSavedAddress = false;
            }
        },
        metaInfo() {
            return {
                title: window.smbData.CMS.Addresses.Meta.Title
            };
        },
        created() {
            this.$successLog({ description: 'Component - Basket Step 3', outcome: 'Created' });
            this.$store.dispatch('updateSummary');
        },
        mounted() {
            this.setGetDestinationVisibleFieldValues();
            $(window).scrollTop(0);
        }
    }
</script>
