<template>
    <div class="card-body" v-if="products && products.length > 0">
        <div class="font-weight-bold">Extras</div>
        <div class="row half-gutters my-1"
             v-for="(product) in products"  
             :key="product.Id">
            <!--<div class="col-2 text-center">
                <i class="fa-fw text-primary fa-lg" 
                   v-bind:class="extrasIcon"></i>
            </div>-->
            <div class="col-8">
                <div>
                    <span v-html="product.name"></span>&nbsp;
                    <a v-if="product.link" :href="product.link" target="_blank"><i class="fas fa-external-link"></i></a>
                </div>
            </div>
            <div class="col-4 text-right text-nowrap">
                <div v-text="product.price"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'basket-summary-products',
        computed: {
            products: {
                get() { return this.$store.state.summary.data.products }
            },
        //    extrasIcon: {
        //        get() { return this.$store.getters.getIconStylesByName('Extras') }
        //    }
        },
        created() {
            this.$successLog({ description: 'Component - Basket Summary Extras', outcome: 'Created' });
        }
    }
</script>
