<template>
    <div>
        <label for="address-contactphonenumber">{{ label }}</label>
        <div class="input-group">
            <div class="input-group-prepend address-phone-country">
                <select-2-phone-country id="address-contactphonecountryiso" :options="countries" v-model="phoneNumber.countryIso" class="form-control form-control-lg" style="width: 100%; min-width: 7rem;" placeholder="Please Select" v-on:input="$emit('input', phoneNumber)"></select-2-phone-country>
            </div>
            <input type="tel" class="form-control" id="address-contactphonenumber" name="phone number" placeholder="Enter local number" maxlength="25"
                    v-model="phoneNumber.number"
                    v-number-only-input
                    v-on:input="$emit('input', phoneNumber)" />
        </div>
        <div class="small" v-if="!hideDriverMessage">Unfortunately drivers cannot telephone upon arrival. Please ensure your address is fully accessible e.g. door bell working</div>
    </div>
</template>
<script>
    import Select2PhoneCountry from '../../../components/select-2/Select2PhoneCountry.vue';

    export default {
        name: "phone-number",
        components: {
            Select2PhoneCountry,
        },
        props: {
            value: {
                countryIso: String,
                number: String,
            },
            label: String,
            countries: Array,
            hideDriverMessage: Boolean
        },
        data: function () {
            return {
                phoneNumber: this.value
            }
        },
        watch: {
            value: {
                handler() {
                    this.phoneNumber = { ...this.value };
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>