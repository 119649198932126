import VWave from 'v-wave';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import BasketBackButton from './basket/components/BasketBackButton.vue';
import BasketStep1 from './basket/components/BasketStep1.items.vue';
import BasketStep2 from './basket/components/BasketStep2.addresses.origin.vue';
import BasketStep3 from './basket/components/BasketStep3.addresses.destination.vue';
import BasketStep4 from './basket/components/BasketStep4.addresses.confirm.vue';
import BasketStep5 from './basket/components/BasketStep5.dates.outbound.vue';
import BasketStep6 from './basket/components/BasketStep6.dates.inbound.vue';
import BasketStep7 from './basket/components/BasketStep7.essentials.vue';
import BasketStep8 from './basket/components/BasketStep8.confirm.vue';

//import BasketStepConfirmation from './basket/components/BasketStepConfirmation.vue';
//import BasketPaymentProcessor from './basket/components/BasketPaymentProcessor.vue'
import BasketProgress from './basket/components/BasketProgress.vue';
import BasketSummary from './basket/components/BasketSummary.vue';
import basketstore from './basket/store/index.js';

import Meta from "vue-meta";
import { numberOnlyInput } from './directives.js';
import ValidationRules from './validation/validationRules.js';
/*import { component } from 'vue/types/umd';*/
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(Meta);
Vue.use(VueToast);
Vue.use(VWave)

//configure({
//    classes: {
//        valid: 'is-valid',
//        invalid: 'is-invalid'
//    }
//});

Vue.prototype.smbData = window.smbData;
Vue.prototype.smbI18n = window.smbI18n;
Vue.config.devtools = true;

Vue.directive(
    'number-only-input', numberOnlyInput
);

//SMB3-222
Vue.prototype.$infoLog = function (logModel) {
//    console.log(
//        `%c ${logModel.description} %c ${logModel.outcome} %c`,
//        'padding: 4px; color: #2B2B2B',
//        'background:#f8fcfe ; padding: 4px; border-radius: 3px;  color: #29ABE2; border: 1px solid #29ABE2',
//        'background:transparent'
//    );
};
Vue.prototype.$successLog = function (logModel) {
//    console.log(
//        `%c ${logModel.description} %c ${logModel.outcome} %c`,
//        'padding: 4px; color: #2B2B2B',
//        'background:#f4fcf7 ; padding: 4px; border-radius: 3px;  color: #2DBE60; border: 1px solid #2DBE60',
//        'background:transparent'
//    );
};
Vue.prototype.$errorLog = function (logModel) {
//    console.log(
//        `%c ${logModel.description} %c ${logModel.outcome} %c`,
//        'padding: 4px; color: #2B2B2B',
//        'background:#fce3e5 ; padding: 4px; border-radius: 3px;  color: #F07077; border: 1px solid #F07077',
//        'background:transparent'
//    );
};

Vue.prototype.$toastinfo = function () {
    this.$toast.open({
        message: this.splitPaymentStatusString[1],
        type: this.splitPaymentStatusString[0],
        duration: 5000,
        dismissible: true
    });
}
Vue.prototype.$showErrorToast = function(message) {
    if (!message)
        message = 'We encountered an error, please try again later or contact us if this error persists';

    this.$toast.open({
        message,
        type: 'error',
        position: 'bottom-right',
        duration: 5000,
        queue: true,
        dismissable: true
    });
}

//SMB3-222
Vue.config.errorHandler = function (err, vm, info) {
    //this.$errorLog({ description: err.toString(), outcome: 'Error' })
    //this.$errorLog({ description: info, outcome: 'Error Info' })
    console.log(`Error: ${err.toString()}\nInfo: ${info}`);
}

const $ = window.$;
Vue.component('cust-upgradelosscover', require('./components/customeraccount/UpgradeLossCover.vue').default);
Vue.component('preview-fileupload', require('./components/customeraccount/PreviewFileUpload.vue').default);
Vue.component('custacc-packinglist', require('./components/customeraccount/PackingListItems.vue').default);
Vue.component('cancel-order', require('./components/customeraccount/CancelOrder.vue').default);
Vue.component('main-date-picker', require('./components/datecontrol/DatePicker.vue').default);
Vue.component('select-date-picker', require('./components/datecontrol/DatePickerSelect.vue').default);
Vue.component('custom-forms-date-select', require('./components/datecontrol/CustomFormsDatePicker.vue').default);
Vue.component('account-login', require('./components/account/AccountLogin.vue').default);
Vue.component('facebook-login', require('./components/account/FacebookLogin.vue').default);
Vue.component('account-register', require('./components/account/AccountRegister.vue').default);
Vue.component('account-forgot-password', require('./components/account/AccountForgotPassword.vue').default);
Vue.component('route-select', require('./components/routing/RouteSelect.vue').default);
Vue.component('route-select-quote', require('./components/routing/RouteSelectQuote.vue').default);
Vue.component('route-select-destination', require('./components/routing/RouteSelectDestination.vue').default);
Vue.component('route-alert', require('./components/routing/RouteAlert.vue').default);
Vue.component('flag-select', require('./components/routing/FlagSelect.vue').default);
Vue.component('quote-result-card', require('./components/quote/QuoteResultCard.vue').default);
Vue.component('review-list', require('./components/reviews/ReviewList.vue').default);
Vue.component('select-2', require('./components/select-2/Select2.vue').default);
Vue.component('payment', require('./components/payment/Payment.vue').default);
Vue.component('signature-pad', require('./components/signature/SignaturePad.vue').default);
Vue.component('generic-modal', require('./components/shared/Modal.vue').default);
Vue.component('radio-button', require('./components/forms/RadioButton.vue').default);
Vue.component('text-input', require('./components/forms/TextInput.vue').default);
Vue.component('update-journey-address', require('./components/customeraccount/UpdateJourneyAddress.vue').default);
Vue.component('previous-orders', require('./components/customeraccount/PreviousOrders.vue').default);
Vue.component('next-steps-widget', require('./components/customeraccount/NextStepsWidget.vue').default);
Vue.component('packing-list-supplementary-text', require('../js/components/account/PackingListSupplementaryText.vue').default);
Vue.component('account-referrals', require('./components/account/Referrals.vue').default);
Vue.component('validation-observer', ValidationObserver);

// Check the dom element exists before creating the vue instance
if (document.querySelector('#smbapp')) {
    new Vue({
        data: function () {
            return {
                alertMessages: [],
                destinationName: "",
                country: "",
                quoteModel: null,
                quoteResults: null,
                alerts: [],
                isReturn: false,
                paymentStatus: "",
                splitPaymentStatusString: "",
                isQuoteResultLoading: false,
                showLoadingIconTimeoutComplete: false
            }
        },
        computed: {
            showResults: {
                get() { return this.quoteResults !== null }
            },
            shouldShowLoadingIcon: function () {
                return this.isQuoteResultLoading || !this.showLoadingIconTimeoutComplete;
            }
        },
        methods: {
            updatealert: function (alertMessages, destinationName, country) {
                this.alertMessages = alertMessages;
                this.destinationName = destinationName;
                this.country = country;
                this.$emit('updateorigin', country);
                this.$emit('displayalert', alertMessages, destinationName);
            },
            updateQuote: function (data, alert, destinationName, model) {
                //console.log('updateQuote', data);
                this.quoteResults = data;
                this.showResults = data !== null
                //    this.quoteResults = data;
                //    this.alerts = alert;
                //    this.quoteModel = model;
                //    this.$emit('displayalert', alert, destinationName);
            },
            setIsQuoteResultLoading: function (isLoading) {
                this.isQuoteResultLoading = isLoading;

                if (isLoading) {
                    this.showLoadingIconTimeoutComplete = false;

                    setTimeout(() => {
                        this.showLoadingIconTimeoutComplete = true;
                    }, 800);
                }
            }
        },
        mounted() {
        }
    }).$mount('#smbapp');
}

// Check the order-container dom element exists before creating the vue instance
if (document.querySelector('#order-container')) {

    new Vue({
        store: basketstore,
        components: {
            ValidationObserver,
            ValidationRules,
            BasketStep1,
            BasketStep2,
            BasketStep3,
            BasketStep4,
            BasketStep5,
            BasketStep6,
            BasketStep7,
            BasketStep8,
//            BasketStep9,
            BasketBackButton,
//            BasketPaymentProcessor,
            BasketProgress,
            BasketSummary,
//            LoadingIndicator
        },
//        data: function () {
//            return {
//                alerts: [],
//                paymentStatus: "",
//                splitPaymentStatusString: ""
//            }
//        },
//        comments: {},
        computed: {
            step: function () {
                return this.$store.state.step;
            },
            isLoading: function () {
                return this.$store.state.isLoading;
            },
            initialLoadComplete: function () {
                return this.$store.state.initialLoadComplete;
            },
            validationErrors: function() {
                return this.$store.state.validationErrors ?? {};
            }
        },
        methods: {
            nextStep: function () {
                this.$infoLog({ description: `Next Step: ${this.step}`, outcome: 'Initiated' })
//                //TODO fix this hack
//                // Is this needed now?
//                if (this.$store.state.addresses.blockSubmit) {
//                    this.$store.dispatch('setSubmitBlock', false);
//                    return;
//                }
                    
                this.$refs.stepform.validate().then((isValid) => {
                    this.$infoLog({ description: `Validating: ${isValid}`, outcome: 'Initiated' })
                    if (isValid) {
                        this.$store.dispatch('setIsLoading', true);

                        switch (this.step) {
                            case window.smbData.STEPS_ADDITEMS:
                                this.$infoLog({ description: `dispatch: saveCustoms`, outcome: 'Initiated' })
                                this.$store.dispatch('saveCustoms');
                                break;
                            case window.smbData.STEPS_ORIGINADDRESS:
                                this.$infoLog({ description: `dispatch: saveOrigin`, outcome: 'Initiated' })
                                this.$store.dispatch('saveOrigin');
                                break;
                            case window.smbData.STEPS_DESTINATIONADDRESS:
                                this.$infoLog({ description: `dispatch: saveDestination`, outcome: 'Initiated' })
                                this.$store.dispatch('saveDestination');
                                break;
                            case window.smbData.STEPS_ADDRESSCONFIRM:
                                this.$infoLog({ description: `dispatch: saveIsReturn`, outcome: 'Initiated' })
                                this.$store.dispatch('saveIsReturn');
                                break;
                            case window.smbData.STEPS_OUTBOUNDDATE:
                                this.$infoLog({ description: `dispatch: saveOutboundCollectionDate`, outcome: 'Initiated' })
                                this.$store.dispatch('saveOutboundCollectionDate');
                                break;
                            case window.smbData.STEPS_INBOUNDDATE:
                                this.$infoLog({ description: `dispatch: saveInboundCollectionDate`, outcome: 'Initiated' })
                                this.$store.dispatch('saveInboundCollectionDate');
                                break;
                            case window.smbData.STEPS_ESSENTIALS:
                                this.$infoLog({ description: `dispatch: saveEssentials`, outcome: 'Initiated' })
                                this.$store.dispatch('saveEssentials');
                                break;
                            case window.smbData.STEPS_CONFIRM:
                                //console.log('go');
                                this.$store.commit('setPaymentFormReady', true);
                                //this.$store.dispatch('processPayment');
//                                this.$store.commit('setStepForward', window.smbData.STEPS_PAYMENT);
                                break;
                        }


//                        switch (this.step) {
//                            case window.smbData.STEPS_ADDITEMS:
//                            case window.smbData.STEPS_CUSTOMS:
//                                this.$infoLog({ description: 'Method - nextStep: Step 1 process', outcome: 'Initiated' });
//                                this.$store.dispatch('saveCustoms');
//                                break;
//                            case window.smbData.STEPS_ORIGINADDRESS:
//                                this.$infoLog({ description: 'Method - nextStep: Step 2 Origin process', outcome: 'Initiated' });
//                                this.$store.dispatch('saveAddress');
//                                break;
//                            case window.smbData.STEPS_DESTINATIONADDRESS:
//                                this.$infoLog({ description: 'Method - nextStep: Step 2 Destination process', outcome: 'Initiated' });
//                                this.$store.dispatch('saveAddress');
//                                break;
//                            case window.smbData.STEPS_ADDRESSCONFIRM:
//                                this.$infoLog({ description: 'Method - nextStep: Step 2 Confirmation process', outcome: 'Initiated' });
//                                this.$store.dispatch('saveReturnJourneyStatusToBasket');
//                                break;
//                            case window.smbData.STEPS_OUTBOUNDDATE:
//                                this.$infoLog({ description: 'Method - nextStep: Step 3 Outbound process', outcome: 'Initiated' });
//                                this.$store.dispatch('saveDateDetails');
//                                break;
//                            case window.smbData.STEPS_INBOUNDDATE:
//                                this.$infoLog({ description: 'Method - nextStep: Step 3 Inbound process', outcome: 'Initiated' });
//                                this.$store.dispatch('saveDateDetails');
//                                break;
//                            case window.smbData.STEPS_ESSENTIALS:
//                                this.$infoLog({ description: 'Method - nextStep: Step 4 process', outcome: 'Initiated' });
//                                this.$store.dispatch('saveEssentialsToBasket');
//                                break;
//                            case window.smbData.STEPS_CONFIRM:
//                                this.$infoLog({ description: 'Method - nextStep: Step confirmation process', outcome: 'Initiated' });
//                                this.$store.dispatch('saveBillingAddressToBasket');
//                                break;
//                        }
                    } else {
                        // scroll to first error
                        const errors = Object.entries(this.$refs.stepform.refs)
                            .map(([key, value]) => ({
                                key,
                                value
                            }))
                            .filter(error => {
                                if (!error || !error.value || !error.value.failedRules) return false;
                                return Object.keys(error.value.failedRules).length > 0;
                            });
                        if (errors && errors.length > 0) {
                            this.$refs.stepform.refs[errors[0]['key']].$el.scrollIntoView();
                        }
                    }
                });
            },
        },
        watch: {
            validationErrors: function (newValue, originalValue) {
                if (!newValue || newValue.length > 0) {
                    this.$refs.stepform.reset();
                } else {
                    this.$refs.stepform.setErrors(newValue);
                }
            },
        },
        created: function () {
            this.$store.dispatch('loadBasket');

            //console.log('history', window.history);
            // Go back to the step we were on if the user refreshed the page
            if (window.history.state && window.history.state.step) {
                this.$store.commit('setStepNoHistory', window.history.state.step);
            }

            // Listen for back/forward events to move between steps
            const _this = this;
            window.addEventListener('popstate', function (e) {
                //console.log(
                //    `%c History Pop ${window.history.length} %c`,
                //    'background:#2DBE60; padding: 4px 20px; border-radius: 3px;  color: #000; border: 1px solid #f4fcf7;',
                //    'background:transparent',
                //    window.history.state
                //);
                if (window.history.state) {
                    _this.$store.commit('setStepNoHistory', window.history.state.step);
                }
            });

/*            this.$store.dispatch('setSummary');*/
        },
//        beforeUpdate() {
//            if (sessionStorage.getItem("tempval")) {
//                this.paymentStatus = sessionStorage.getItem("tempval");
//                this.splitPaymentStatusString = this.paymentStatus.split('-');
//                this.$toastinfo();
//                sessionStorage.removeItem("tempval");
//                console.log("order-container-toastr");
//            }
//        }
    }).$mount('#order-container');
}
