 <template>
     <flat-pickr v-model="date" :config="config" class="form-control" placeholder="Select date" :name="name"></flat-pickr>
</template>
<script> 

    import flatPickr from 'vue-flatpickr-component';
    import moment from 'moment'; 
    import 'flatpickr/dist/flatpickr.css';
    const $ = window.$;
    export default {
        name: 'datepickerselect', 
        components: { flatPickr},
        props: {
            codename: String,
            name: String,
            isDisabled: Boolean,
            minDate: Date,
            maxDate: Date,
            noCollection: Array,
            weekendDays: Array,
            value: Date
        },
        data: function () {
            return {
                date: this.value,
                config: {
                    defaultDate: this.value,
                    wrap: false, // set wrap to true only when using 'input-group'
                    altFormat: 'D, J F Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    allowInput: true,
                    minDate: this.minDate,
                    maxDate: this.maxDate,
                    disable: [],
                    monthSelectorType: "static",
                    onChange: function (newDate, dateStr, instance) {
                        var oldDate = new Date(instance.config.defaultDate).toISOString().slice(0, 10);
                        newDate = new Date(newDate).toISOString().slice(0, 10);
                        const journeyType = instance.element.dataset.journeyType;
                        const journeyid = document.getElementById(`JourneyId_${journeyType}`).value;
                        let selectedDate = new Date(newDate).toISOString().slice(0, 10);

                        if (oldDate === newDate) {
                            $('#btnUcdConfirm_' + journeyType).attr("disabled", true);
                        } else {
                            $('#btnUcdConfirm_' + journeyType).attr("disabled", false);
                        }

                        const model = {
                            CollectionDate: selectedDate,
                            JourneyId: journeyid
                        };
                        $.getJSON('/en-gb/api/quote/getestimateddateforupdate', model, function (response) {
                            if (response.success) {
                                if (response.data.publicHolidays != null && response.data.publicHolidays.length > 0) {
                                    var publicHolidaysHtml = "<i class=\"icon-warning-sign\"></i>   Please be aware that the following dates are public holidays and could potentially affect your transit times:<ul>";

                                    response.data.publicHolidays.forEach(function (holiday) {
                                        publicHolidaysHtml += "<li>" + moment(holiday.key).format('Do MMMM YYYY') + " - " + holiday.value + "</li>";
                                    });

                                    publicHolidaysHtml += "</ul>"
                                    document.getElementById("publicHolidaysDisplay_" + journeyType).style.display = "block";
                                    $("#publicHolidaysDisplay_" + journeyType).html(publicHolidaysHtml);
                                }
                                else {
                                    document.getElementById("publicHolidaysDisplay_" + journeyType).style.display = "none";
                                    $("#publicHolidaysDisplay_" + journeyType).empty();
                                }

                                if (response.data.Information && response.data.Information.length) {
                                    //var contentHtml = response.data.Information + "<div class=\"btn-standard close-popup-collection-date-update\">I Agree</div>";
                                    $('#routeAlertDisplay_' + journeyType).html(response.data.Information);
                                    document.getElementById("routeAlertDisplay_" + journeyType).style.display = "block";
                                }
                            }
                            else {
                                $('#ucdValidation_' + journeyType).html('Sorry it looks like there was an error occured while updating collection date, please try again or contact us.');
                                $('#btnUcdConfirm_' + journeyType).attr("disabled", true);
                            }
                        });
                    },
                    onOpen: function (selectedDates, dateStr, instance) {
                        $(instance.altInput).prop('readonly', true);
                        $(".flatpickr-month").css("pointer-events", "none");
                        $(".numInput.cur-year").addClass("flatpickr-year-readonly");
                    },
                    onClose: function (selectedDates, dateStr, instance) {
                        $(instance.altInput).prop('readonly', false);
                        $(instance.altInput).blur();
                    }
                }
            };
        },

        methods: {
            updateFlatpickrConfig() {
                this.config.minDate = this.minDate;
                this.config.maxDate = this.maxDate;
                let _this = this;
                this.config.disable = this.noCollection.concat([
                    function (date) {
                        // return true to disable
                        if (_this.weekendDays.map(Number).includes(date.getDay())) {
                            return date;
                        } 
                    }
                ]);
            }
        },
        watch: {
            $props: {
                handler() {
                    this.updateFlatpickrConfig();
                },
                deep: true,
                immediate: true,
            },
        }
    }
</script>

