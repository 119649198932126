<template>
    <div>
        <h1>Essentials</h1>
        <labels-and-label-holders v-bind="labelsProps"></labels-and-label-holders>
        <cancellation-cover :priceFormatted="data.cancellationCoverCostFormatted"></cancellation-cover>
        <loss-cover :options="data.lossCoverOptions"></loss-cover>
        <sms-tracking :priceFormatted="data.smsCostString" :phoneNumberCountries="labelsProps.phoneNumberCountries"></sms-tracking>
        <reason-for-use></reason-for-use>
        <use-credit :credit="data.creditAmount" :creditFormatted="data.creditFormatted"></use-credit>
        <use-reward-points :points="data.points" :pointsValue="data.pointsValue" :pointsFormatted="data.pointsFormatted"></use-reward-points>
    </div>
</template>
<script>
    import LabelsAndLabelHolders from './essentials/LabelsAndLabelHolders.vue'
    import CancellationCover from './essentials/CancellationCover.vue'
    import LossCover from './essentials/LossCover.vue'
    import SmsTracking from './essentials/SmsTracking.vue'
    import ReasonForUse from './essentials/ReasonForUse.vue'
    import UseCredit from './essentials/UseCredit.vue'
    import UseRewardPoints from './essentials/UseRewardPoints.vue'

    export default {
        name: 'basket-step7',
        components: {
            LabelsAndLabelHolders,
            CancellationCover,
            LossCover,
            SmsTracking,
            ReasonForUse,
            UseCredit,
            UseRewardPoints
        },
        data: function () {
            return {
                data: {},
            }
        },
        computed: {
            labelsProps: function () {
                return {
                    canBuyLabels: this.data.canBuyLabels,
                    labelHolderOptions: this.data.labelHolderOptions,
                    postageMethods: this.data.postageMethods,
                    labelCostFormatted: this.data.labelCostString,

                    addressFieldFlags: this.$store.state.addresses.addressFieldFlags,
                    addressTypes: this.$store.state.addresses.addressTypes,
                    phoneNumberCountries: this.$store.state.addresses.phoneNumberCountries,
                    originCountry: this.$store.state.addresses.originCountry,
                    originAddress: this.$store.state.addresses.origin
                };
            },
        },
        methods: {
            loadEssentialsData: function () {
                this.$store.dispatch('startedLoading');
                let _this = this;
                var jqxhr = $.getJSON('/en-gb/api/basketessentialsapi/getessentialsdatajsonasync/', function (result) {
                    _this.data = result.data;
                });

                jqxhr.fail(function (errors) {
                    _this.$store.dispatch('errorLog', { description: 'Action - loadEssentialsData failed with errors', outcome: 'Error' });
                    _this.$store.dispatch('showErrorToast');
                });

                jqxhr.always(() => {
                    _this.$store.dispatch('finishedLoading');
                });
            },
        },
        metaInfo() {
            return {
                title: window.smbData.CMS.Essentials.Meta.Title
            };
        },
        created() {
            this.$successLog({ description: 'Component - Basket Step 7', outcome: 'Created' });
            this.$store.dispatch('updateSummary');
            this.loadEssentialsData();
        },
        mounted() {
            $(window).scrollTop(0);
        }
    }
</script>
