 <template>
    <validation-provider :rules="rules" v-slot="{ errors, classes }" :name="name">
        <label :for="id">{{ label }}</label>
        <input v-bind:type="type" v-model="value" class="form-control" v-bind:class="classes" :id="id" :placeholder="placeholder" v-on:input="$emit('input', $event.target.value)" :autocomplete="autocomplete" />
        <div class="text-danger">{{ errors[0] }}</div>
    </validation-provider>
</template>
<script> 
    import { ValidationProvider } from 'vee-validate';
    export default {
        name: 'text-input',
        components: {
            ValidationProvider
        },
        props: {
            value: [String, Number],
            id: String,
            rules: [String, Object],
            name: String,
            type: String,
            label: String,
            placeholder: String,
            autocomplete: {
                type: String,
                default: 'off'
            }
        }
    }
</script>