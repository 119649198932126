
import 'es6-promise/auto';
import Vuex from 'vuex';
import Vue from 'vue';

import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';

import items from './modules/items.js';
import customs from './modules/customs.js';
import addresses from './modules/addresses.js';
import dates from './modules/dates.js';
import essentials from './modules/essentials.js';
import confirmation from './modules/confirmation.js'
import summary from './modules/summary.js';

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
    modules: {
        items: items,
        customs: customs,
        addresses: addresses,
        dates: dates,
        essentials: essentials,
        confirmation: confirmation,
        summary: summary,
    },

    actions,
    mutations,
    getters,

    state: {
        initialLoadComplete: false,
        step: 1,
        isLoading: true,
        paymentFormReady: false,
        requestId: {
            addItem: 0,
        },
        currencySymbol: '£',
        cultureCode: 'en-gb',
        isUserSignedIn: false,
        validationErrors: {},
        route: {
            notSuitableForHoliday: false,
            notSuitableForHolidayOutbound: false,
            isDoorToPort: false,
            isDoorToPortOutbound: false,
        }
    }
});
