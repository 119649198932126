export default {
    state: {
        // Payload values
        outboundCollectionDate: String,
        outboundTimeSlot: String,
        outboundServiceId: Number,
        outboundCollectionServiceId: Number,
        inboundCollectionDate: String,
        inboundTimeSlot: String,
        inboundServiceId: Number,
        inboundCollectionServiceId: Number,

        // Other data
    },

    mutations: {
        setInitialStateDates: function (state, payload) {
            state.outboundCollectionDate = payload.outboundCollectionDate;
            state.outboundTimeSlot = payload.outboundTimeslot;
            state.outboundServiceId = payload.outboundRouteServiceId;
            state.outboundCollectionServiceId = payload.outboundCollectionServiceId;
            state.inboundCollectionDate = payload.inboundCollectionDate;
            state.inboundTimeSlot = payload.inboundTimeslot;
            state.inboundServiceId = payload.inboundRouteServiceId;
            state.inboundCollectionServiceId = payload.inboundCollectionServiceId;
        },

        setOutboundCollectionDate: function (state, payload) {
            state.outboundCollectionDate = payload;
        },
        setOutboundTimeSlot: function (state, payload) {
            state.outboundTimeSlot = payload;
        },
        setOutboundServiceId: function (state, payload) {
            state.outboundServiceId = payload;
        },
        setOutboundCollectionServiceId: function (state, payload) {
            state.outboundCollectionServiceId = payload;
        },
        setInboundCollectionDate: function (state, payload) {
            state.inboundCollectionDate = payload;
        },
        setInboundTimeSlot: function (state, payload) {
            state.inboundTimeSlot = payload;
        },
        setInboundServiceId: function (state, payload) {
            state.inboundServiceId = payload;
        },
        setInboundCollectionServiceId: function (state, payload) {
            state.inboundCollectionServiceId = payload;
        },

    },

    actions: {
        async saveOutboundCollectionDate({ state, commit, dispatch, rootState }) {
            var params = {
                model: {
                    routeServiceId: state.outboundServiceId,
                    collectionDate: state.outboundCollectionDate,
                    timeslot: state.outboundTimeSlot,
                    collectionServiceId: state.outboundCollectionServiceId
                }
            };

            var jqxhr = $.post('/en-gb/api/basketdateapi/saveoutboundcollectiondateandservice/', params, function (result) {
                let errors = result.errors ?? {};
                commit('setValidationErrors', errors);

                if (result && result.success) {
                    let nextStep = rootState.addresses.isReturn ? window.smbData.STEPS_INBOUNDDATE : window.smbData.STEPS_ESSENTIALS;
                    commit('setStepForward', nextStep);
                }
            });

            jqxhr.fail(function () {
                dispatch('showErrorToast');
            });

            jqxhr.always(function () {
                dispatch('setIsLoading', false);
            });
        },

        async saveInboundCollectionDate({ state, commit, dispatch }) {
            var params = {
                model: {
                    routeServiceId: state.inboundServiceId,
                    collectionDate: state.inboundCollectionDate,
                    timeslot: state.inboundTimeSlot,
                    collectionServiceId: state.inboundCollectionServiceId
                }
            };

            var jqxhr = $.post('/en-gb/api/basketdateapi/saveinboundcollectiondateandservice/', params, function (result) {
                let errors = result.errors ?? {};
                commit('setValidationErrors', errors);

                if (result && result.success) {
                    commit('setStepForward', window.smbData.STEPS_ESSENTIALS);
                }
            });

            jqxhr.fail(function () {
                dispatch('showErrorToast');
            });

            jqxhr.always(function () {
                dispatch('setIsLoading', false);
            });
        },
    }
};
