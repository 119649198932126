<template>
    <div>
        <div v-if="previousOrders.length && previousOrders != null">
            <div v-for="order in previousOrders" :key="order.reference">
                <div v-for="journey in order.journeys" :key="journey.id">
                    <div class="card card-gray py-3 my-3">
                        <div class="card-body">
                            <div class="row previous-orders">
                                <div class="col-xl-8">
                                    <div>
                                        <div class="row mb-1">
                                            <div
                                                class="col-4 font-weight-bold px-1"
                                            >
                                                Route
                                            </div>
                                            <div class="col-8 text-left">
                                                {{ journey.originCountryName }}
                                                to
                                                {{
                                                    journey.destinationCountryName
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div
                                                class="col-4 font-weight-bold px-1"
                                            >
                                                Order Reference
                                            </div>
                                            <div class="col-8 text-left">
                                                {{ order.reference }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div
                                                class="col-4 font-weight-bold px-1"
                                            >
                                                Date Booked
                                            </div>
                                            <div class="col-8 text-left">
                                                {{
                                                    order.createdOn | formatDate
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mt-5">
                                            <div
                                                class="col-4 font-weight-bold px-1"
                                            >
                                                Collection
                                            </div>
                                            <div class="col-8 text-left">
                                                {{
                                                    journey.collectionDate
                                                        | formatDate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 mt-xl-0 mt-4">
                                    <div
                                        v-if="
                                            !order.receipts.length ||
                                            order.receipts.isDeleted == true
                                        "
                                    >
                                        <div>
                                            <button
                                                type="button"
                                                class="btn btn-success btn-block mb-3"
                                                disabled
                                            >
                                                Download Receipt
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        v-for="receipt in order.receipts"
                                        :key="receipt"
                                    >
                                        <div
                                            v-if="
                                                order.receipts != null &&
                                                receipt.isDeleted == false
                                            "
                                        >
                                            <div>
                                                <a
                                                    v-bind:href="
                                                        order.awsPath +
                                                        'files/' +
                                                        receipt.file
                                                    "
                                                    target="_blank"
                                                    class="btn btn-success col-md-12"
                                                    >Download Receipt</a
                                                >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-5">
                                        <button
                                            type="submit"
                                            v-on:click="viewOrder(order)"
                                            class="btn btn-white-success btn-block"
                                        >
                                            View Order
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="previousOrders.length > 0 && loadmoreOrders == true">
                    <button
                        id="loadMore"
                        @click="loadMore"
                        class="btn btn-success load-more-previous-orders"
                    >
                        Load More..
                    </button>
                </div>
                <div v-if="loading">
                    <div class="loading-icon loading-icon-previous-orders">
                        <div class="reverse-spinner"></div>
                    </div>
                </div>
                <div v-if="errorResponse == 'No More Previous Orders!'">
                    <div class="alert text-center text-underline">
                        <strong>{{ errorResponse }}</strong>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div>You don't have any completed orders.</div>
        </div>
    </div>
</template>

<script>
const $ = window.$
import moment from 'moment'
export default {
    name: 'previousorders',

    data: function () {
        return {
            previousOrders: '',
            pageNumber: 1,
            loadmoreOrders: false,
            loading: false,
            errorResponse: '',
        }
    },

    mounted() {
        var self = this
        self.loading = true

        $.getJSON('/en-gb/api/accountapi/getawspath', function (result) {
            var params = {
                aWSpath: result.data,
                pageNumber: self.pageNumber,
            }
            $.getJSON(
                '/en-gb/api/accountapi/getpreviousorders',
                params,
                function (result) {
                    if (result.data.length > 0) {
                        self.previousOrders = result.data
                        self.loadmoreOrders = true
                        self.loading = false
                    } else {
                        self.loading = false
                    }
                }
            )
        })
    },

    filters: {
        formatDate: function (value) {
            if (value) {
                return moment(String(value)).format('dddd, Do MMM YYYY')
            }
        },
    },

    methods: {
        viewOrder: function (order) {
            var orderDetailsURL =
                '/en-gb/account/orderdetails/' + '?id=' + order.reference
            window.location.href = orderDetailsURL
        },

        loadMore: function () {
            var self = this
            self.pageNumber++
            self.loading = true
            self.errorResponse = ''

            $.getJSON('/en-gb/api/accountapi/getawspath', function (result) {
                var params = {
                    aWSpath: result.data,
                    pageNumber: self.pageNumber,
                }
                $.getJSON(
                    '/en-gb/api/accountapi/getpreviousorders',
                    params,
                    function (result) {
                        if (result.data.length > 0) {
                            self.previousOrders = self.previousOrders.concat(
                                result.data
                            )
                            self.loadmoreOrders = true
                            self.loading = false
                        } else {
                            self.loadmoreOrders = false
                            self.loading = false
                            self.errorResponse = 'No More Previous Orders!'
                        }
                    }
                )
            })
        },
    },
}
</script>
