<template>
    <select v-bind:name="name" autocomplete='off' class="form-control select2 "><slot></slot></select>
</template>
<script>

    import select2Mixin from '../mixins/select-2-mixin'
    const $ = window.$;
    export default {
        mixins: [select2Mixin],
        methods: {
        },
        mounted: function () {
            var calls = [];
            var vm = this;
            $(this.$el)
                // init select2
                .select2({
                    data: this.options,
                    placeholder: this.placeholder,
                    matcher: this.customMatcher,
                })
                .val(this.value)
                .trigger('change')
                .on('change', function () {

                    vm.$emit('input', this.value);
                });

            $("body").on('keydown', ".select2,.select2-dropdown", function (e) {
                var KEYS = { UP: 38, DOWN: 40 };
                const keysList = Object.values(KEYS);

                if (!keysList.includes(e.keyCode))
                    return;

                var $sel2 = $(this).closest(".select2");
                if ($sel2.length == 0) {
                    $sel2 = $(".select2.select2-container--open");
                }

                // make sure we found the <select> el
                var $sel = $sel2.data("element")
                if ($sel.length) {

                    if (!$sel.select2('isOpen')) {
                        e.preventDefault();
                        $sel.select2('open');
                    }
                }
            });
        }
    }
</script>

