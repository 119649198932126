<template>
    <div class="row">
        <div class="col-sm-12 col-md-6  d-flex">
            <div class="modal fade" ref="modal" id="rasmodal" tabindex="-1" role="dialog" aria-labelledby="rasModalLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog  modal-dialog-centered  modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalLabel">{{modalHeader}}</h5>
                        </div>
                        <div class="modal-body supplementary-text">
                            <slot name="body">
                                <span v-html="modalContent">
                                </span>
                                <div class="col-12 px-0 mt-2 text-center">
                                    <button type="button" class="btn btn-success btn-delete-section text-center" data-dismiss="modal" 
                                            v-on:click.stop.prevent="hideModel('rasmodal')">
                                        I Agree
                                    </button>
                                </div>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$
    export default {
        name: 'basket-ras-charge-modal',
        computed: {
            modalContent: {
                get() { return window.smbData.CMS.Addresses.RemoteArea.Content }
            },
            modalHeader: {
                get() { return window.smbData.CMS.Addresses.RemoteArea.Header }
            },
            hasRasCharge: {
                get() { return this.$store.state.addresses.hasRasCharge }
            },
        },
        methods: {
            showModel: function (modal) {
                $('#' + modal).modal('show')
            },
            hideModel: function (modal) {
                $('#' + modal).modal('hide')
            },
        },
        mounted() {
            if (this.hasRasCharge)
                this.showModel('rasmodal')
        },
        created() {
            this.$successLog({ description: 'Component - Basket Address Has Ras Charge Modal', outcome: 'Created' })
        },
    }
</script>

