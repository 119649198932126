<template>
    <div>
        <div class="form-group row">
            <validation-provider :rules="{ required: true }" vid="billing-address-id" v-slot="{ classes, errors }" name="Billing Address" slim>
                <div class="col-12" v-bind:class="classes">
                    <select-2 id="customer-billingAddress" v-model="billingAddressId" class="form-control" style="width:100%;" placeholder="Select billing address" data-minimum-results-for-search="Infinity" name="BillingAddress.Id">
                        <option hidden></option>
                        <option value="0">Add New Billing Address</option>
                        <option v-for="(billingAddress) in billingAddresses" v-bind:key="billingAddress" :value="billingAddress.id">{{ billingAddress.line1 }}, {{ billingAddress.postcode }}, {{ billingAddress.countryName }}</option>
                    </select-2>
                    <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                </div>
            </validation-provider>
        </div>
        <div v-if="billingAddressId == 0">
            <div class="form-group row">
                <validation-provider :rules="{ required: true, max: 35 }" vid="billing-address-line1" v-slot="{ classes, errors }" :name="smbI18n.billingaddress.Line1" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="billing-address-line1">{{ smbI18n.billingaddress.Line1 }}</label>
                        <input type="text" class="form-control disable-autofill-highlight" id="billing-address-line1" v-model="line1" placeholder=""
                               data-val-required="Address Line 1" name="{{ smbI18n.billingaddress.Line1 }}" maxlength="35" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row">
                <validation-provider :rules="{ max: 35 }" vid="billing-address-line2" v-slot="{ classes, errors }" :name="smbI18n.billingaddress.Line2" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="billing-address-line2">{{ smbI18n.billingaddress.Line2 }}<small class="text-muted"> (optional)</small></label>
                        <input type="text" class="form-control disable-autofill-highlight" id="billing-address-line2" v-model="line2" placeholder=""
                               name="{{ smbI18n.billingaddress.Line1 }}" maxlength="35" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row">
                <validation-provider :rules="{ required: true, max: 64 }" vid="billing-address-city" v-slot="{ classes, errors }" :name="smbI18n.billingaddress.City" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="billing-address-city">{{ smbI18n.billingaddress.City }}</label>
                        <input type="text" class="form-control disable-autofill-highlight" id="billing-address-city" v-model="cityName"
                               maxlength="64" placeholder="" name="{{ smbI18n.billingaddress.City }}" />
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row">
                <validation-provider :rules="{ required: true }" vid="billing-address-country" v-slot="{ classes, errors }" :name="smbI18n.billingaddress.Country" tag="div" class="w-100">
                    <div class="col-12" v-bind:class="classes">
                        <label for="billing-address-country">Country</label>
                        <div class="input-group">
                            <country-select id="billing-address-country" v-model="countryIso" :default-selected-iso="countryIso" placeholder="Please Select" :countries="countries" :isLoading="loadingCountries" v-on:valueChanged="billingIsoChanged" name="BillingAddress.CountryIso"></country-select>
                        </div>
                        <div class="text-danger" id="m-billing-address-country" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>

            <div class="form-group row" v-if="countryUsesPostcode">
                <validation-provider :rules="{ required: true, max: 16 }" vid="billing-address-postcode" v-slot="{ classes, errors }" :name="smbI18n.billingaddress.Postcode" slim>
                    <div class="col-12" v-bind:class="classes">
                        <label for="billing-address-country">{{ smbI18n.billingaddress.Postcode }}</label>
                        <div class="input-group">
                            <input type="text" class="form-control disable-autofill-highlight" id="billing-address-postcode" name="{{ smbI18n.billingaddress.Postcode }}"
                                   v-model="postcode" placeholder="" maxlength="16" />
                        </div>
                        <div class="text-danger" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </div>
        </div>
    </div>
</template>
<script>
    const $ = window.$;
    import { ValidationProvider, extend } from 'vee-validate';
    import { required, max, min } from 'vee-validate/dist/rules';
    import CountrySelect from '../routing/CountrySelect.vue';
    import _ from 'lodash'

    extend('required', { ...required, message: '{_field_} is required' });
    extend('max', { ...max, message: '{_field_} must be a maximum of {length} characters long' });
    extend('min', { ...min, message: '{_field_} must be at least {length} characters long' });

    export default {
        name: 'billingaddress',
        components: {
            ValidationProvider,
            CountrySelect
        },
        props: {
            customerId: Number,
            billingAddressId: {
                type: Number,
                default: null,
            },
            billingAddress: Object
        },
        data: function () {
            return {
                postcode: null,
                line1: null,
                line2: null,
                cityName: null,
                countryIso: null,
                country: null,
                countries: [],
                countryUsesPostcode: Boolean,
                loadingCountries: true,
                billingAddresses: {},
                //billingAddress: {},
                billingAddressDetail: {}
            };
        },
        loaded: function () {

        },
        computed: {
        },
        created: function () {
            this.loadCountries();
        },
        watch: {
            postcode: function (value) {
                this.billingAddressDetail.postcode = value;
                this.billingAddress = this.billingAddressDetail;
                this.$emit('valueChanged', this.billingAddress);
            },
            line1: function (value) {
                this.billingAddressDetail.line1 = value;
                this.billingAddress = this.billingAddressDetail;
                this.$emit('valueChanged', this.billingAddress);
            },
            line2: function (value) {
                this.billingAddressDetail.line2 = value;
                this.billingAddress = this.billingAddressDetail;
                this.$emit('valueChanged', this.billingAddress);
            },
            cityName: function (value) {
                this.billingAddressDetail.cityName = value;
                this.billingAddress = this.billingAddressDetail;
                this.$emit('valueChanged', this.billingAddress);
            },
            countryIso: function (value) {
                this.billingAddressDetail.countryIso = value;
                this.billingAddress = this.billingAddressDetail;
                this.$emit('valueChanged', this.billingAddress);
            },
            billingAddressId: function (value) {
                this.billingAddressDetail.id = value;
                this.billingAddress = this.billingAddressDetail;
                this.$emit('valueChanged', this.billingAddress);
            },
            //billingAddress: function (value) {
            //    this.$emit('valueChanged', value);
            //}

        },
        mounted: function () {
            if (this.customerId > 0) {
                this.loadBillingAddresses();
            }
        },
        methods: {
            billingIsoChanged: function (value) {
                this.countryIso = value;
            },
            loadCountries: function () {
                var _this = this;
                _this.loadingCountries = true;
                var jqxhr = $.post('/en-gb/api/addressapi/getbillingcountriesjsonasync', function (data) {
                    _this.countries = data;
                });

                jqxhr.fail(function () {
                    //console.log(jqxhr.responseJSON);
                });

                jqxhr.always(function () {
                    _this.loadingCountries = false;
                    //context.commit('setBasketLoading', false);
                });
            },
            loadBillingAddresses: function () {
                var _this = this;

                var jqxhr = $.post('/en-gb/api/addressapi/getbillingaddressesasync', function (data) {
                    //context.commit('setTerms', data.data);
                    _this.billingAddresses = _.uniqBy(data.data, v => [v.line1, v.countryName, v.postcode].join());
                });

                jqxhr.fail(function () {
                    //console.log(jqxhr.responseJSON);
                });

                jqxhr.always(function () {
                    //context.commit('setBasketLoading', false);
                });
            },

        },
    }
</script>