<template>
    <div class="mb-4 text-center card card-gray">
        <div class="col-12 col-sm-auto mb-2">
            <div class="form-check form-check-inline">
                <div class="form-check-label mt-2 flex-grow-1"><h5>You selected:</h5></div>
            </div>
            </div>
            <div class="col-12 col-sm-auto">
                <label class="form-check form-check-inline radiobutton" for="order-returnjourney-false">
                    One-Way Journey
                    <input class="form-check-input" type="radio" name="isReturn" id="order-returnjourney-false"
                           v-model="isReturn"
                           v-bind:value="false" />
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="col-12 col-sm-auto">
                <label class="form-check form-check-inline radiobutton" for="order-returnjourney-true">
                    Return Journey
                    <input class="form-check-input" type="radio" name="isReturn" id="order-returnjourney-true"
                           v-model="isReturn"
                           v-bind:value="true"
                           v-bind:disabled="!hasRouteGotReturn" />
                    <span class="checkmark"></span>
                </label>
                <div class="text-danger" id="m-order-returnjourney"></div>
            </div>
        </div>
</template>
<script>
    const $ = window.$;

    export default {
        name: 'basket-switch-oneway-or-return-journey',
        data: function () {
            return {
                hasRouteGotReturn: false
            }
        },
        computed: {
            isReturn: {
                get: function () { return this.$store.state.addresses.isReturn },
                set: function (value) {
                    this.$store.commit('setIsReturn', value);
                    this.$store.dispatch('updateSummaryWithOptions', { isReturn: value });
                }
            },
        },
        methods: {
            checkRouteHasReturn: function () {
                var self = this;
                var params = {
                    originIso: this.$store.state.addresses.origin.countryIso,
                    destinationIso: this.$store.state.addresses.destination.countryIso
                };
                var jqxhr = $.getJSON('/en-gb/api/addressapi/checkroutehasreturn', params, function (result) {
                    self.hasRouteGotReturn = result.data
                });

                jqxhr.fail(function () {
                    this.$errorLog({ description: 'Method - checkRouteHasReturn failed with errors', outcome: 'Error' })
                    dispatch('showErrorToast');
                });
            }
        },
        created: function () {
            this.checkRouteHasReturn();
        }
    }
</script>

