<template>
    <div class="container p-0">
        <div v-bind:class="[confirmOrderAlerts ? '' : '']">
            <slot>
                <div
                    v-if="
                        defaultAlertMessages != undefined &&
                        defaultAlertMessages.length > 0
                    "
                >
                    <div
                        v-if="defaultDestinationName !== ''"
                        v-bind:class="[
                            confirmOrderAlerts
                                ? 'alert alert-warning text-danger my-5 text-center text-md-left'
                                : 'alert alert-warning text-danger text-center text-md-left',
                        ]"
                        role="alert"
                    >
                        <div id="notes" class="row">
                            <div
                                class="col-12 col-md-1 text-center text-md-right p-0"
                            ></div>
                            <div
                                class="col-12 col-md-11 text-center text-md-left"
                            >
                                <h2>
                                    {{ defaultDestinationName }} Route Alerts
                                </h2>
                            </div>
                            <div
                                id="notes"
                                class="col-12 col-md-12 text-center text-md-left"
                                v-for="alert in defaultAlertMessages"
                                v-bind:key="alert"
                            >
                                <div id="notes" class="row pb-3 icon-container">
                                    <div
                                        class="col-1 col-md-1 text-center text-md-right p-0 centre-icon"
                                    >
                                        <i
                                            class="fas fa-exclamation-circle fa-lg my-3 md-my-0"
                                        ></i>
                                    </div>
                                    <div
                                        class="col-11 col-md-11 text-center text-md-left"
                                        v-html="alert"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'routealert',
    props: {
        defaultAlertMessages: Object,
        defaultDestinationName: String,
        confirmOrderAlerts: Boolean,
    },
    data: function () {
        return {
            alertMessages: this.defaultAlertMessages,
            destinationName: this.defaultDestinationName,
        }
    },
    methods: {
        displayalert: function (alertMessages, destinationName) {
            //if (originalAlertMessages == null) {
            this.alertMessages = alertMessages
            this.destinationName = destinationName
            //}
        },
    },
    mounted: function () {
        this.$root.$on('displayalert', (alertMessages, destinationName) => {
            // your code goes here
            this.alertMessages = alertMessages
            this.destinationName = destinationName
            this.displayalert(alertMessages, destinationName)
        })
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
