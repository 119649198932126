export default {
    setInitialState: function (state, payload) {
        state.isUserSignedIn = payload.isUserSignedIn;

        state.currencySymbol = payload.data.currencySymbol;
        state.cultureCode = payload.data.cultureCode;

        state.route.notSuitableForHoliday = payload.data.notSuitableForHoliday;
        state.route.notSuitableForHolidayOutbound = payload.data.notSuitableForHolidayOutbound;
        state.route.isDoorToPort = payload.data.isDoorToPort;
        state.route.isDoorToPortOutbound = payload.data.isDoorToPortOutbound;
    },
    setInitialLoadComplete: function (state, payload) {
        state.initialLoadComplete = payload;
    },

    incrementRequest: function (state, requestType) {
        if (!state.requestId[requestType]) {
            state.requestId[requestType] = 0;
        }

        state.requestId[requestType]++;
    },

    setIsLoading: (state, payload) => {
        state.isLoading = payload;
    },

    // Use this if progressing forward through the checkout so that the history is preserved
    setStepForward: function (state, payload) {
        window.history.pushState({ step: payload }, '');
        //console.log(
        //    `%c History Push ${window.history.length} %c`,
        //    'background:#2DBE60; padding: 4px 20px; border-radius: 3px;  color: #000; border: 1px solid #f4fcf7;',
        //    'background:transparent',
        //    window.history.state
        //);
        state.step = payload;
    },
    // Use this when moving back or on page reload
    setStepNoHistory: function (state, payload) {
        state.step = payload;
    },

    setValidationErrors: (state, payload) => {
        state.validationErrors = payload;
    },
    setPaymentFormReady: (state, payload) => {
        //console.log('set ready', payload);
        state.paymentFormReady = payload;
    }
}