<template>
    <select-2-flag style="width:100%;
                   min-width:7rem;"
                   placeholder="Please Select"
                   defaultPhoneCountryCode = "defaultPhoneCountryCode"
                   v-model="selectedPhoneCode"
                   class="form-control"
                   :search="search"
                   :name="name">
        <option v-for="item in phoneCodes"
                :value="item.id"
                :key="item"
                :data-country="item.country"
                :data-code="item.text"
                :data-alt="item.alt"
                :data-tokens="item.id"
                class="option-with-flag"
                :selected="item.id === defaultPhoneCountryCode">
            {{item.country}}
        </option>
    </select-2-flag>
</template>

<script>
    import Select2Flag from '../select-2/Select2Flag.vue';
    const $ = window.$;

    export default {
        name: 'flagselect',
        components: { Select2Flag },
        props: {
            defaultPhoneCountryCode: String,
            placeholder: String,
            name: String,
            value: String
        },
        data: function () {
            return {
                selectedPhoneCode: this.value,//this.value?.startsWith("GB") ? 'GB' : this.value,
                phoneCodes: [],
                search: true,
            };
        },
        computed: {
            placeholderText: function () {
                if (this.isLoading) {
                    return 'Loading...';
                }

                return this.placeholder;
            },

            isDisabled: function () {
                // Disabled if loading
                if (this.isLoading) {
                    return true;
                }

                // Disabled if no countries
                if (!(this.phoneCodes && this.phoneCodes.length > 0)) {
                    return true;
                }

                return false;
            }
        },
        methods: {

            loadPhoneCodes: function () {
                var self = this;
                var jqxhr = $.getJSON('/en-gb/api/addressapi/getphonecodesjsonasync', function (data) {
                    self.phoneCodes = data.data;
                });
                jqxhr.fail(function () {
                });
            },

        },
        watch: {
            selectedPhoneCode: function () {
                this.$emit('valueChanged', this.selectedPhoneCode);
            },
            //defaultPhoneCountryCode: function () {
            //    this.selectedPhoneCode = this.defaultPhoneCountryCode.startsWith("GB") ? 'GB' : this.defaultPhoneCountryCode;
            //},

        },
        created: function () {
            this.loadPhoneCodes();
        }
    }
</script>
