<template>
    <div id="quoteFormWrapper">
        <slot>
            <!--The Modal-->
            <div
                id="emailQuoteModal"
                class="modal fade"
                ref="modal"
                tabindex="-1"
                role="dialog"
                aria-hidden="true"
                data-keyboard="false"
                data-backdrop="static"
            >
                <!--Modal content-->
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <form
                            id="emailQuoteForm"
                            class="email-modal-title-margin"
                        >
                            <div class="modal-header">
                                <div class="modal-title">
                                    <h2>
                                        Email yourself details of this quote
                                    </h2>
                                </div>
                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    v-on:click.stop.prevent="
                                        hideModel('emailQuoteModal')
                                    "
                                >
                                    &times;
                                </button>
                            </div>
                            <fieldset id="quoteEmails">
                                <table style="width: 100%">
                                    <tr>
                                        <td>
                                            <input
                                                type="email"
                                                class="w-100 emailQuoteInput mx-md-3"
                                                name="Email"
                                                id="quoteEmail"
                                                v-model="userEmail"
                                                placeholder="Your email address"
                                                data-val="true"
                                                data-val-required="Your email address"
                                                data-val-email="Please enter a valid email address."
                                            />
                                        </td>
                                        <td style="width: 1em">
                                            <button
                                                @click="emailQuoteCall"
                                                id="quoteSubmit"
                                                class="quote-send-email-button mx-md-3"
                                            >
                                                Email Me
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="quoteEmailValidation"
                                                class="field-validation-error"
                                                data-valmsg-for="Email"
                                                data-valmsg-replace="true"
                                            ></div>
                                        </td>
                                        <td></td>
                                    </tr>
                                </table>
                            </fieldset>
                        </form>
                        <div class="modal-body m-1">
                            <p>
                                <i
                                    class="fa fa-fw fa-check-circle"
                                    style="color: #2dbe60"
                                >
                                </i>
                                Best price guaranteed. Found a better price?
                                <a href="/support/faqs/question/136"
                                    >Let us beat it</a
                                >.
                            </p>
                            <p>
                                While we'll
                                <strong>never be beaten on price</strong> it's
                                our service level that sets us apart:
                            </p>
                            <p>
                                <i
                                    class="fa fa-fw fa-check-circle"
                                    style="color: #2dbe60"
                                ></i>
                                The fastest luggage shipping transit times
                                worldwide.
                            </p>
                            <p>
                                <i
                                    class="fa fa-fw fa-check-circle"
                                    style="color: #2dbe60"
                                ></i>
                                Our support team monitors your shipment and is
                                pro-actively working for you 24hrs / day.
                            </p>
                            <p>
                                <i
                                    class="fa fa-fw fa-check-circle"
                                    style="color: #2dbe60"
                                ></i>
                                Our customer service team is available to you 7
                                days / week & 24hrs on weekdays.
                            </p>
                            <p>
                                <i
                                    class="fa fa-fw fa-check-circle"
                                    style="color: #2dbe60"
                                ></i>
                                Free heavy duty label holders included with your
                                order.
                            </p>
                            <p>
                                <i
                                    class="fa fa-fw fa-check-circle"
                                    style="color: #2dbe60"
                                ></i>
                                One click tracking and free cover upgrade with
                                our App.
                            </p>
                            <p>
                                <i
                                    class="fa fa-fw fa-check-circle"
                                    style="color: #2dbe60"
                                ></i>
                                <a href="/reviews"
                                    >Over {{ reviewTotal }} independent
                                    reviews.</a
                                >
                            </p>
                        </div>
                        <div id="quoteEmailResponse" class="px-2">
                            <div id="quoteEmailSuccess">
                                <h2>Thank you</h2>
                                <p>
                                    We have emailed your quote to
                                    <strong>{{ userEmail }}</strong>
                                </p>
                            </div>
                            <div id="quoteEmailFail">
                                <h2>Error</h2>
                                <p>
                                    Unfortunately there was a problem sending
                                    you your quote. Please try again!
                                </p>
                                <p>
                                    <a
                                        href="https://www.sendmybag.com/support/contact-us"
                                        target="_blank"
                                        >Contact us</a
                                    >
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ValidationObserver ref="quoteForm">
                <form>
                    <div class="form-group row">
                        <div
                            class="col-lg-5 offset-lg-1 col-xl-4 offset-xl-2 col-md-6 px-3 px-md-2"
                        >
                            <label for="quoteOrigin">From</label>
                            <!-- Country select -->
                            <country-select
                                id="quoteOrigin"
                                placeholder="e.g. United Kingdom"
                                :countries="originCountries"
                                :default-selected-iso="originIso"
                                :isLoading="isLoadingOrigin"
                                :isEnabled="isEnabledOrigin"
                                v-model="originIso"
                            >
                            </country-select>
                            <!-- Postcode -->
                            <div
                                class="form-group row quotePostcode pt-3"
                                style="display: contents"
                                v-if="showPostcodeInput"
                            >
                                <div id="collectionPostcode" class="mt-3">
                                    <label class="d-none d-md-block">
                                        {{ collectionPostcodeText }}
                                        <span v-if="!postcodesRequired" class="optional">{{ ifAvailableInBrackets }}</span>
                                        <span v-else class="optional">(required)</span>
                                    </label>
                                    <validation-provider
                                        :rules="{
                                            max: 16,
                                            postcodeFormat:
                                                validOriginPostcodeFormat,
                                        }"
                                        mode="eager"
                                        vid="origin-postcode"
                                        v-slot="{ errors, classes }"
                                    >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-bind:class="classes"
                                            id="collectionPostcodeInput"
                                            v-bind:name="collectionPostcodeText"
                                            :placeholder="postcodeText"
                                            v-model="originPostcode"
                                        />
                                        <div class="text-danger">
                                            {{ errors[0] }}
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>
                            <!-- Region -->
                            <div
                                class="form-group quoteRegion pt-3"
                                style="display: contents"
                                v-if="showRegionInput"
                            >
                                <div id="originRegion" class="mt-3">
                                    <label
                                        for="originRegion"
                                        class="d-none d-md-block"
                                        >State</label
                                    >
                                    <country-select
                                        id="quoteOriginRegions"
                                        placeholder="Please Select State"
                                        :countries="regions"
                                        :default-selected-region="
                                            originRegionCode
                                        "
                                        v-model="originRegionCode"
                                    >
                                    </country-select>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-lg-5 col-xl-4 col-md-6 px-3 px-md-2 mt-4 mt-md-0"
                        >
                            <label for="quoteDestination">To</label>
                            <!-- Country select -->
                            <country-select
                                id="quoteDestination"
                                placeholder="e.g. Spain"
                                :countries="destinationCountries"
                                :default-selected-iso="destinationIso"
                                :isLoading="isLoadingDestination"
                                :isEnabled="isEnabledDestination"
                                v-model="destinationIso"
                                :default-value-on-options-update="false"
                            >
                            </country-select>
                            <!-- Postcode -->
                            <div
                                class="form-group row quotePostcode pt-3"
                                style="display: contents"
                                v-if="showPostcodeInput"
                            >
                                <div id="destinationPostcode" class="mt-3">
                                    <label class="d-none d-md-block">
                                        {{ destinationPostcodeText }}
                                        <span v-if="!postcodesRequired" class="optional">{{ ifAvailableInBrackets }}</span>
                                        <span v-else class="optional">(required)</span>
                                    </label>
                                    <validation-provider
                                        :rules="{
                                            max: 16,
                                            postcodeFormat:
                                                validDestinationPostcodeFormat,
                                        }"
                                        mode="eager"
                                        vid="destination-postcode"
                                        v-slot="{ errors, classes }"
                                    >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-bind:class="classes"
                                            id="destinationPostcodeInput"
                                            v-bind:name="
                                                destinationPostcodeText
                                            "
                                            :placeholder="postcodeText"
                                            v-model="destinationPostcode"
                                        />
                                        <div class="text-danger">
                                            {{ errors[0] }}
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>
                            <!-- Regions -->
                            <div
                                class="form-group quoteRegion pt-3"
                                style="display: contents"
                                v-if="showRegionInput"
                            >
                                <div id="destinationRegion" class="mt-3">
                                    <label
                                        for="destinationRegion"
                                        class="d-none d-md-block"
                                        >State</label
                                    >
                                    <country-select
                                        id="quoteDestinationRegions"
                                        placeholder="Please Select State"
                                        :countries="regions"
                                        :default-selected-region="
                                            destinationRegionCode
                                        "
                                        v-model="destinationRegionCode"
                                    >
                                    </country-select>
                                </div>
                            </div>
                        </div>
                        <!-- Postcode supplementary text -->
                        <div v-if="showPostcodeInput" class="col-12 supplementary-text text-center mt-1 d-block">
                            <span v-if="!postcodesRequired">
                                Enter postcodes for a more accurate quote
                            </span>
                            <span v-else>
                                To get your quote please enter the collection and destination postcodes
                            </span>
                        </div>
                        <!-- Regions supplementary text -->
                        <div
                            class="col-12 supplementary-text text-center mt-1 d-block"
                            v-if="showRegionInput"
                        >
                            To get your quote please select the collection and
                            destination states
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </slot>
    </div>
</template>

<script>
import _ from 'lodash'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules'
import routeSelectMixin from '../mixins/route-select-mixin'
import CountrySelect from '../routing/CountrySelect.vue'

extend('max', {
    ...max,
    message: 'The {_field_} must be less than {length} characters long',
})

const $ = window.$
export default {
    name: 'routeselectquote',
    mixins: [routeSelectMixin],
    components: {
        CountrySelect,
        ValidationProvider,
        ValidationObserver,
    },
    data: function () {
        return {
            isLoadingCountryParamaters: false,
            isLoadingQuoteResults: false,
            latestQuoteResultRequestId: 0,
            alerts: [],
            originCountryParameters: {},
            destinationCountryParameters: {},
            originPostcode: '',
            destinationPostcode: '',
            isActive: false,
            isReturnRouteAvailable: true,
            userEmail: '',
            testing: '',
            modalCheck: false,
            reviewTotal: '',
        }
    },
    computed: {
        validOriginPostcodeFormat: {
            get() {
                return this.originCountryParameters.postcodeFormat
            },
        },
        validDestinationPostcodeFormat: {
            get() {
                return this.destinationCountryParameters.postcodeFormat
            },
        },
        showPostcodeInput: {
            get() {
                let originUsesPostcodes =
                    this.originCountryParameters.usesPostcode !== undefined
                        ? this.originCountryParameters.usesPostcode
                        : false

                let destinationUsesPostcodes =
                    this.destinationCountryParameters.usesPostcode !== undefined
                        ? this.destinationCountryParameters.usesPostcode
                        : false

                return (
                    (originUsesPostcodes || destinationUsesPostcodes) &&
                    !this.showRegionInput
                )
            },
        },
        postcodesRequired: {
            get() {
                if (!this.showPostcodeInput)
                    return false;

                // Postcodes are required for AU domestic
                if (this.originIso === 'AU' && this.destinationIso === 'AU')
                    return true;

                return false;
            }
        },
        showRegionInput: {
            get() {
                if (this.originIso !== 'US' || this.destinationIso !== 'US')
                    return false

                let originUsesRegions =
                    this.originCountryParameters.usesRegions !== undefined
                        ? this.originCountryParameters.usesRegions
                        : false

                let destinationUsesRegions =
                    this.destinationCountryParameters.usesRegions !== undefined
                        ? this.destinationCountryParameters.usesRegions
                        : false

                return originUsesRegions && destinationUsesRegions
            },
        },
        formComplete: function () {
            // Form needs to have origin and destination iso to be complete
            if (!this.originIso || this.originIso.length <= 0) {
                return false
            }
            if (!this.destinationIso || this.destinationIso.length <= 0) {
                return false
            }

            // Also for US domestic it requires regions to be selected as well (this should probably work off data rather than hardcoded countries)
            if (this.originIso === 'US' && this.destinationIso === 'US') {
                if (
                    !this.originRegionCode ||
                    !this.originRegionCode.length ||
                    !this.destinationRegionCode ||
                    !this.destinationRegionCode.length
                ) {
                    return false
                }
            }

            // For AU domestic (or other postcode required routes) we must check that the postcodes have been entered
            if (this.postcodesRequired) {
                if (
                    !this.originPostcode ||
                    !this.originPostcode.length ||
                    !this.destinationPostcode ||
                    !this.destinationPostcode.length
                ) {
                    return false
                }
            }

            return true
        },
        isLoading: function () {
            return this.isLoadingCountryParamaters || this.isLoadingQuoteResults
        },
    },
    watch: {
        originIso: function (newValue) {
            if (!this.isActive || newValue === '') return

            this.resetAdditionalInputValues()
            this.getCountryDetails(newValue, true)
            this.updateOnOriginChange().then(() => {
                this.quoteResultUpdate()
            })
        },
        originPostcode: _.debounce(function () {
            if (!this.isActive) return

            this.quoteResultUpdate()
        }, 700),
        originRegionCode: function (newValue) {
            if (!this.isActive || newValue === '') return

            this.quoteResultUpdate()
        },
        destinationIso: function (newValue) {
            if (!this.isActive || newValue === '') return

            this.resetAdditionalInputValues()
            this.getCountryDetails(newValue, false)
            this.updateOnDestinationChange()
            this.quoteResultUpdate()
        },
        destinationPostcode: _.debounce(function () {
            if (!this.isActive) return

            this.quoteResultUpdate()
        }, 700),
        destinationRegionCode: function (newValue) {
            if (!this.isActive || newValue === '') return

            this.quoteResultUpdate()
        },
        isReturn: function (newValue) {
            this.quoteResultUpdate()
        },
        isLoading: function () {
            this.$emit('quote-results-loading', this.isLoading)
        },
    },
    methods: {
        resetAdditionalInputValues: function () {
            this.isActive = false

            if (this.originPostcode !== '') this.originPostcode = ''

            if (this.destinationPostcode !== '') this.destinationPostcode = ''

            if (this.originRegionCode !== '') this.originRegionCode = ''

            if (this.destinationRegionCode !== '')
                this.destinationRegionCode = ''

            this.isActive = true
        },
        getCountryDetails: function (iso, isOrigin) {
            let self = this

            if (iso === undefined || iso === '') return

            self.isLoadingCountryParamaters = true
            let params = {
                iso: iso,
            }

            $.getJSON(
                '/en-gb/api/addressapi/getcountryparameters',
                params,
                function (result) {
                    if (isOrigin) self.originCountryParameters = result.data
                    else self.destinationCountryParameters = result.data
                }
            ).always(function () {
                self.isLoadingCountryParamaters = false
            })
        },
        updateQuoteOnLoad: function () {
            if (
                (this.originIso !== undefined || this.originIso !== '') &&
                this.destinationIso !== undefined &&
                this.destinationIso !== ''
            )
                this.quoteResultUpdate()

            if (
                this.originIso !== undefined &&
                this.originIso === 'US' &&
                this.destinationIso !== undefined &&
                this.destinationIso === 'US'
            )
                this.loadRegions(this.originIso)

            this.isActive = true
        },
        quoteResultUpdate: function () {
            var self = this
            this.$refs.quoteForm.validate({ silent: true }).then((isValid) => {
                if (isValid && self.formComplete) {
                    self.$emit('update-quote', null)
                    self.isLoadingQuoteResults = true
                    self.latestQuoteResultRequestId++

                    var params = {
                        requestId: self.latestQuoteResultRequestId,
                        originIso: self.originIso,
                        destinationIso: self.destinationIso,
                        isReturn: self.isReturn,
                        originPostcode: self.originPostcode,
                        destinationPostcode: self.destinationPostcode,
                        originRegionId: self.originRegionId,
                        destinationRegionId: self.destinationRegionId,
                        originRegionCode: self.originRegionCode,
                        destinationRegionCode: self.destinationRegionCode,
                    }
                    var jqxhr = $.getJSON(
                        '/en-gb/api/quoteapi/getquotejsonasync/',
                        $.param(params, true),
                        function (result) {
                            //console.log('Quote Result', result);
                            if (result.data === '' || result.requestId.toString() !== self.latestQuoteResultRequestId.toString()) return

                            self.isLoadingQuoteResults = false;

                            // result.data is the JSON string
                            // Ensure this is the latest request so that we know we aren't populating the list incorrectly
                            if (result.data && result.data.services.length > 0) {
                                self.testing = result.data;
                                self.alertMessages = result.data.alerts;
                                self.$emit(
                                    'update-quote',
                                    result.data,
                                    result.data.alerts,
                                    result.destinationName,
                                    result.model
                                )
                                self.$emit('update-alert', result.data.alerts)
                                document
                                    .getElementById('quote-page-heading')
                                    .scrollIntoView({
                                        behavior: 'smooth',
                                    })
                                // Check to see that both a originIso & destinationIso have been selected
                                if (
                                    self.originIso != '' &&
                                    self.destinationIso != ''
                                ) {
                                    self.modalCheck = true
                                    self.emailQuoteCallMethod()
                                }
                            } else {
                                self.$emit('update-quote', null)
                                self.$emit('update-alert', null)
                            }
                        }
                    )
                    jqxhr.fail(function () {
                        self.isLoadingQuoteResults = false

                        self.$emit('update-quote', null)
                        self.$emit('update-alert', null)
                    })
                } else {
                    self.$emit('update-quote', null)
                    self.$emit('update-alert', null)
                }
            })
        },

        getReviewStats: function () {
            var self = this

            var jqxhr = $.getJSON(
                '/en-gb/api/reviews/getreviewstats',
                function (result) {
                    self.reviewTotal =
                        result.totalCombinedReviews.toLocaleString()
                }
            )
        },

        emailQuoteCall: function (e) {
            var self = this
            e.preventDefault ? e.preventDefault() : (e.returnValue = false)
            e.stopPropagation()

            var params = {
                originIso: self.originIso,
                destinationIso: self.destinationIso,
                isReturn: self.isReturn,
                originPostcode: self.originPostcode,
                destinationPostcode: self.destinationPostcode,
                originRegionId: self.originRegionId,
                destinationRegionId: self.destinationRegionId,
                originRegionCode: self.originRegionCode,
                destinationRegionCode: self.destinationRegionCode,
                weightUnit: self.testing.weightUnit,
                emailQuoteAddress: self.userEmail,
            }

            var jqxhr = $.post(
                '/en-gb/api/quoteapi/getemailquotejsonasync/',
                $.param(params, true),
                function (result) {
                    if (result.success) {
                        $('#quoteEmailFail').css('display', 'none')
                        $('#quoteForm').css('display', 'none')
                        $('#quoteEmailSuccess').css('display', 'block')
                        $('#quoteSubmit').css('display', 'none')
                    } else if (result.error) {
                        $('#quoteForm').css('display', 'none')
                        $('#quoteEmailFail').css('display', 'block')
                    }
                }
            )
        },

        emailQuoteCallMethod: function () {
            var timeOut = false
            function quoteTimeout() {
                timeOut = true
            }
            setTimeout(quoteTimeout, 12000)

            var mouseY = 0
            var topValue = 100
            var showedDialog = false

            if (this.modalCheck == true) {
                window.addEventListener(
                    'mouseout',
                    function (e) {
                        mouseY = e.clientY
                        if (
                            mouseY < topValue &&
                            showedDialog == false &&
                            timeOut == true
                        ) {
                            $('#quoteEmailSuccess').css('display', 'none')
                            $('#quoteEmailFail').css('display', 'none')
                            $('#quoteSubmit').css('display', 'block')
                            $('#emailQuoteModal').modal('show')
                            showedDialog = true
                        }
                    },
                    false
                )
            }
        },

        hideModel: function (modal) {
            $('#' + modal).modal('hide')
        },
    },
    mounted() {
        this.getReviewStats()
        this.getCountryDetails(this.originIso, true)
        this.getCountryDetails(this.destinationIso, false)
        this.updateQuoteOnLoad()
    },
    created() {
        this.$successLog({
            description: 'Component - Route Select Quote',
            outcome: 'Created',
        })
    },
}
</script>
