<template>
    <div>

        <button id="scrollToFilter" type="button" @click="scrollToTop" class="scroll-to-filters fad fa-angle-double-up"></button>

        <div class="flex-column">

            <!--Filter Reviews Collapsable-->
            <div v-if="loading!=true">
                <div class="reset-filter-button">
                    <button id="hideShowFilters" class="collapse-filters" type="button" data-toggle="collapse" data-target="#filter-panel" aria-expanded="true" aria-label="Hide/Show Filters">
                        <i class="far fa-bars text-blue"></i>
                    </button>
                </div>
            </div>
            <div v-else>
                <div class="reset-filter-button">
                    <button id="hideShowFilters" class="collapse-filters" type="button" disabled data-toggle="collapse" data-target="#filter-panel" aria-expanded="true" aria-label="Hide/Show Filters">
                        <i class="far fa-bars text-blue"></i>
                    </button>
                </div>
            </div>


            <div class="review-sticky-filter">
                <div id="filter-panel" class="collapse show">
                    <div class="panel panel-default">
                        <div class="panel-body">
                            <form method="post" class="form-inline" role="form">

                                <div class="review-form-group">
                                    <div :class="filterOptionsClass">
                                        <!--From Country-->
                                        <label class="float-left filter-col mr-0" for="headerQuoteOrigin"><strong>From:</strong></label>
                                        <country-select id="headerQuoteOrigin" name="OriginIso" placeholder="e.g. United Kingdom" :countries="originCountries" :default-selected-iso="originIso" :key="originIso" :disabled=false :isLoading="isLoadingOrigin" :isEnabled="isEnabledOrigin" v-on:valueChanged="originIsoChanged"></country-select>
                                    </div>

                                    <div :class="filterOptionsClass">
                                        <!--To Country-->
                                        <label class="float-left filter-col mr-0" for="headerQuoteDestination"><strong>To:</strong></label>
                                        <country-select id="headerQuoteDestination" name="DestinationIso" placeholder="e.g. Spain" :countries="destinationCountries" :default-selected-iso="destinationIso" :key="destinationIso" :isLoading="isLoadingDestination" :isEnabled="isEnabledDestination" :disabled=false v-on:valueChanged="destinationIsoChanged"></country-select>
                                    </div>

                                    <div v-if="showPurposeFilterOption" :class="filterOptionsClass">
                                        <!--Purpose-->
                                        <label class="float-left filter-col mr-0" for="orderPurpose"><strong>Purpose:</strong></label>
                                        <select-2 id="orderPurpose" :options="purposes" v-model="selectedPurpose" :key="purposes" style="width:100%;" placeholder="e.g. To University"></select-2>
                                    </div>

                                    <div class="col-sm-12 mt-4 d-flex flex-column flex-md-row justify-content-md-between">
                                        <!--Reset Filters Button-->
                                        <button type="button" 
                                                title="Reset filter options" 
                                                class="btn btn-success bg-white text-success my-1"
                                                @click="resetFilters(); getCountriesJsonAsync();">
                                            <i class="fa fa-undo fa-xs"></i>&nbsp;&nbsp;Reset Filters
                                        </button>

                                        <!--Search Specified Filter-->
                                        <button type="button" 
                                                id="filter-reviews" 
                                                class="btn btn-success my-1" 
                                                title="Search" 
                                                :disabled="filterbuttonDisabled" 
                                                @click="onFilterChange($event)">
                                            Search
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!--Reviews List-->
            <div id="Review" class="row review-summary-widget">
                <div v-for="review in reviewsData" :key="review.Id" class="col-lg-6">

                    <div class="align-items-center mb-3">
                        <div class="card card-gray flex-grow-1">
                            <!--<div v-if="review.replies.length > 0">
                                <div class="far fa-envelope-square fa-2x review-reply-indicator"></div>
                            </div>-->
                            <div class="card-body py-4">
                                <div class="row">
                                    <div class="col-mb-12 col-lg-4">
                                        <div class="review-heading-name-title"><strong>{{review.displayName}}</strong></div>
                                        <div class="pb-1 small">{{review.formattedCreatedOn}}</div>
                                        <div class="py-1" v-if="review.overallRating > 0">
                                            <img src="https://media.sendmybag.com/assets/601a2c51-a312-49d1-92d5-26cb337cbf20/ReviewStar.svg" class="review-star-scaled" />
                                            <img src="https://media.sendmybag.com/assets/601a2c51-a312-49d1-92d5-26cb337cbf20/ReviewStar.svg" class="review-star-scaled" v-if="review.overallRating > 1" />
                                            <img src="https://media.sendmybag.com/assets/601a2c51-a312-49d1-92d5-26cb337cbf20/ReviewStar.svg" class="review-star-scaled" v-if="review.overallRating > 2" />
                                            <img src="https://media.sendmybag.com/assets/601a2c51-a312-49d1-92d5-26cb337cbf20/ReviewStar.svg" class="review-star-scaled" v-if="review.overallRating > 3" />
                                            <img src="https://media.sendmybag.com/assets/601a2c51-a312-49d1-92d5-26cb337cbf20/ReviewStar.svg" class="review-star-scaled" v-if="review.overallRating > 4" />
                                        </div>
                                        <div class="row">
                                            <div class="col-12 pt-1 small">{{review.originCountryName}} to {{review.destinationCountryName}}</div>
                                            <div class="col-6 col-lg-12 pt-1 small">{{review.orderPurpose.name}}</div>
                                            <div class="col-6 col-lg-12 pt-1 small">{{review.lifetimeBagsSent}} Lifetime {{review.bagsDescription}} Sent</div>
                                        </div>
                                    </div>

                                    <div class="col-mb-12 col-lg-8 text-md-left mt-2 mt-lg-0">
                                        <div class="review-heading-name-title"><strong>{{review.title}}</strong></div>

                                        <!--If review DOES NOT have reply from SMB-->
                                        <div v-if="!review.replies.length">
                                            <!--If review description larger than 200 characters show read more and truncate-->
                                            <div v-if="review.description.length>=200 && truncate==false">
                                                <button class="review-truncate" v-on:click="readMoreReview(review)">
                                                    <div v-bind:class="[{ 'overflow-off' : readMore.includes(review) }, 'material-icons']" class="review-overflow">
                                                        <p v-html="review.description"></p>
                                                    </div>
                                                    <div class="mt-2 review-read-more"><div><strong><u>{{review.readMoreIndicator}}</u></strong></div></div>
                                                </button>
                                            </div>
                                            <!--Otherwise just show decription in card-->
                                            <div v-else><p v-html="review.description"></p></div>
                                        </div>

                                        <!--Review DOES have reply from SMB-->
                                        <div v-else>
                                            <div v-for="replies in review.replies" :key="replies.Id">
                                                <!--If review description and reply description larger than 200 characters show read more and truncate-->
                                                <div v-if="review.description.length + replies.description.length >=200 && truncate==false">
                                                    <button class="review-truncate" v-on:click="readMoreReview(review)">
                                                        <div v-bind:class="[{ 'overflow-off' : readMore.includes(review) }, 'material-icons']" class="review-overflow">
                                                            <p v-html="review.description"></p>
                                                            <div class="review-reply-container">
                                                                <div class="d-flex"><i class="fad fa-user-check mx-1"></i><p><b>Reply from Send My Bag:</b></p></div>
                                                                <p v-html="replies.description"></p>
                                                            </div>
                                                        </div>
                                                        <div class="mt-2 review-read-more"><div><strong><u>{{review.readMoreIndicator}}</u></strong></div></div>
                                                    </button>
                                                </div>
                                                <!--Otherwise just show review and reply description in card-->
                                                <div v-else>
                                                    <p v-html="review.description"></p>
                                                    <div class="review-reply-container">
                                                        <div class="d-flex"><i class="fad fa-user-check mx-1"></i><p><b>Reply from Send My Bag:</b></p></div>
                                                        <p v-html="replies.description"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <div v-if="reviewsData.length > 0 && loadmoreFilteredRecords == true"><button id="loadMore" class="btn btn-success" @click="loadMore">Load More..</button></div>
                <div v-if="loading"><div class="loading-icon"><div class="reverse-spinner"></div></div></div>
                <div v-if="errorResponse == 'No Reviews Found For Specified Filter'"><div class="alert"><strong>{{errorResponse}}</strong> - Please try again!</div> <img src="https://www.pngkit.com/png/full/263-2638075_doubt-png-question-and-answers-transparent.png" width="25%" id="notFound" /></div>
                <div v-if="errorResponse == 'No More Reviews Found For Specified Filter'"><div class="alert"><strong>{{errorResponse}}</strong> - Please try again!</div></div>
            </div>
        </div>
        </div>
</template>

<script>

    const $ = window.$;
    import CountrySelect from '../routing/CountrySelect.vue';
    import routeSelectMixin from '../mixins/route-select-mixin';
    export default {
        name: 'reviewlist',
        mixins: [routeSelectMixin],
        components: { CountrySelect },
        props: {
            defaultOriginIso: String,
            defaultDestinationIso: String
        },
        data: function () {
            return {
                originIso: "blank",
                destinationIso: "blank",
                reviewsData: [],
                selectedPurpose: "",
                selectedIsoDestination: "",
                pageNumber: 1,
                errorResponse: "",
                loading: false,
                loadmoreFilteredRecords: false,
                purposes: [],
                readMore: [],
                truncate: false,

                showPurposeFilterOption: false                
            }
        },

        beforeCreate: function () {
            var self = this;
            self.pageNumber = 1;

            if (self.originIso === "blank") {
                self.originIso = "";
            }
                
             if (self.destinationIso === "blank") {
                self.destinationIso = "";
            }
            
            var params = {
                page: self.pageNumber
            };
            $.getJSON('/en-gb/api/reviews/getfilterreviews', params, function (result) {
                self.reviewsData = result;
                self.loading = false;
                self.loadmoreFilteredRecords = true;
                self.pageNumber = 1;

            });

            if (this.showPurposeFilterOption) {
                $.getJSON('/en-gb/api/reviews/getpurposelist', params, function (data) {
                    self.purposes = data;
                });
            }
        },

        methods: {
            readMoreReview(review) {
                const index = this.readMore.indexOf(review)
            
                if (index >= 0) {
                    this.readMore.splice(index, 1);
                    review.readMoreIndicator = "Read More";
                } else {
                    this.readMore.push(review)
                    this.truncate = true;
                    review.readMoreIndicator = "Read Less";
                }
                this.truncate = false;
            },

            loadMore() {
                var self = this;
                self.loading = true;
                self.errorResponse = "";
                self.pageNumber++;
                self.selectedPurpose;
                self.destinationIso;
                self.originIso;

                if (self.originIso === "blank") {
                    self.originIso = "";
                }
                if (self.destinationIso === "blank") {
                    self.destinationIso = "";
                }

                var params = {
                    page: self.pageNumber,
                    purpose: self.selectedPurpose,
                    destinationTo: self.destinationIso,
                    originFrom: self.originIso
                };
                $.getJSON('/en-gb/api/reviews/getfilterreviews', params, function (result) {
                    if (result.length > 0) {
                        self.reviewsData = self.reviewsData.concat(result);
                        self.pageNumber++;
                        self.loading = false;
                        self.loadmoreFilteredRecords = true;
                    } else {
                        self.errorResponse = "No More Reviews Found For Specified Filter";
                        self.loading = false;
                        self.loadmoreFilteredRecords = false;
                    }
                });
            },

            resetFilters() {
                var self = this;
                self.originIso = "";
                self.destinationIso = "";
                self.selectedPurpose = "";
                self.pageNumber = 1;
                var params = {
                    page: self.pageNumber
                };
                $.getJSON('/en-gb/api/reviews/getfilterreviews', params, function (result) {
                    self.reviewsData = result;
                    self.errorResponse = "";
                    self.loadmoreFilteredRecords = true;
                    document.getElementById("loadMore").disabled = false;
                });
            },

            onFilterChange(event) {
                var self = this;
                self.loading = true;
                self.pageNumber = 1;
                self.errorResponse = "";
                self.selectedPurpose;
                self.destinationIso;
                self.originIso;

                if (self.originIso === "blank") {
                    self.originIso = "";
                }

                if (self.destinationIso === "blank") {
                    self.destinationIso = "";
                }

                var params = {
                    page: self.pageNumber,
                    purpose: self.selectedPurpose,
                    destinationTo: self.destinationIso,
                    originFrom: self.originIso
                };
                self.reviewsData = [];
                $.getJSON('/en-gb/api/reviews/getfilterreviews', params, function (result) {
                    if (result.length > 0) {
                        self.reviewsData = self.reviewsData.concat(result);
                        self.loading = false;
                        self.loadmoreFilteredRecords = true;
                        self.pageNumber = 1;
                    }
                    else {
                        self.errorResponse = "No Reviews Found For Specified Filter";
                        self.loading = false;
                        self.pageNumber = 1;
                    }
                });
            },
            scrollToTop() {
                document.getElementById('hideShowFilters').scrollIntoView();
            }
        },

        computed: {

            selectedIso: {
                get() { return this.selectedIsoDestination },
                set(Iso) { this.selectedIsoDestination = Iso }
            },

            filterbuttonDisabled: function () {
                if (this.originIso === "" && this.destinationIso === "" && this.selectedPurpose === "") {
                    return this. true;
                } else return false;
            },

            filterOptionsClass: function () {
                return this.showPurposeFilterOption
                    ? 'col-md-4'
                    : 'col-md-6';
            }
        }
    }

    $(document).scroll(function () {
        var y = $(this).scrollTop();
        if (y > 1800 && $(window).width() < 960) {
            $("#scrollToFilter").fadeIn();
        } else {
            $("#scrollToFilter").fadeOut();
        }
    });
</script>
