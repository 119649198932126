<template>
    <div>
        <date-and-service-select :date="outboundCollectionDate" 
                                 :serviceId="outboundServiceId" 
                                 :collectionServiceId="outboundCollectionServiceId" 
                                 :timeSlot="outboundTimeSlot" 
                                 :estimateDateData="estimateDateData"
                                 :estimatedDateLoading="estimatedDateLoading" 
                                 :collectionAddress="collectionAddress"
                                 :availableServices="availableServices"
                                 v-on:dateupdated="dateUpdated" 
                                 v-on:timeslotupdated="timeSlotUpdated" 
                                 v-on:serviceupdated="serviceUpdated" 
                                 v-on:collectionserviceupdated="collectionServiceUpdated"></date-and-service-select>
        <generic-modal id="outboundCollectionDateAlerts" title="Important Information" :contentArray="collectionDateAlerts"></generic-modal>
    </div>
</template>
<script>
    import DateAndServiceSelect from './dates/DateAndServiceSelect.vue'

    export default {
        name: 'basket-step5',
        components: {
            DateAndServiceSelect
        },
        data: function () {
            return {
                availableServices: [],
                estimateDateData: {},
                estimatedDateLoading: false,
                servicesLoading: false
            }
        },

        computed: {
            outboundCollectionDate: {
                get: function () { return this.$store.state.dates.outboundCollectionDate; },
                set: function (value) { this.$store.commit('setOutboundCollectionDate', value); }
            },
            outboundTimeSlot: {
                get: function () { return this.$store.state.dates.outboundTimeSlot; },
                set: function (value) { this.$store.commit('setOutboundTimeSlot', value); }
            },
            outboundServiceId: {
                get: function () { return this.$store.state.dates.outboundServiceId; },
                set: function (value) {
                    this.$store.commit('setOutboundServiceId', value);
                    this.$store.dispatch('updateSummaryWithOptions', { outboundRouteServiceId: value, outboundCollectionServiceId: this.outboundCollectionServiceId });
                }
            },
            outboundCollectionServiceId: {
                get: function () { return this.$store.state.dates.outboundCollectionServiceId; },
                set: function (value) {
                    this.$store.commit('setOutboundCollectionServiceId', value);
                    this.$store.dispatch('updateSummaryWithOptions', { outboundRouteServiceId: this.outboundServiceId, outboundCollectionServiceId: value });
                }
            },
            collectionAddress: function() {
                return this.$store.state.addresses.origin;
            },
            collectionDateAlerts: function () {
                return this.estimateDateData.collectionDateAlerts;
            }
        },
        methods: {
            dateUpdated: function (value) {
                this.outboundCollectionDate = value;
                this.getEstimatedDate();
            },
            timeSlotUpdated: function (value) {
                this.outboundTimeSlot = value;
            },
            serviceUpdated: function (value) {
                this.outboundServiceId = value;
                this.getEstimatedDate();
            },
            collectionServiceUpdated: function (value) {
                this.outboundCollectionServiceId = value;
                this.getEstimatedDate();
            },
            loadAvailableServices: function() {
                var params = {
                    isOutbound: true
                };

                this.$store.dispatch('startedLoading');
                this.servicesLoading = true;

                let _this = this;
                var jqxhr = $.getJSON('/en-gb/api/basketdateapi/getavailableservices/', params, function (result) {
                    _this.availableServices = result.data.services;
                });

                jqxhr.fail(function (errors) {
                    _this.$store.dispatch('errorLog', { description: 'Action - loadAvailableServices failed with errors', outcome: 'Error' });
                    _this.$store.dispatch('showErrorToast');
                });

                jqxhr.always(() => {
                    _this.$store.dispatch('finishedLoading');
                    _this.servicesLoading = false;
                });
            },
            getEstimatedDate: function () {
                if (!this.outboundCollectionDate || !this.outboundServiceId) {
                    return;
                }

                this.estimatedDateLoading = true;
                var params = {
                    model: {
                        collectionDate: this.outboundCollectionDate,
                        routeServiceId: this.outboundServiceId,
                        collectionServiceId: this.outboundCollectionServiceId
                    },
                    //requestId: window.smbData.STEPS_OUTBOUNDDATE
                };

                let _this = this;
                var jqxhr = $.post('/en-gb/api/basketdateapi/getoutboundestimateddateasync/', params, function (result) {
                    _this.estimateDateData = result.data;

                    if (_this.estimateDateData.collectionDateAlerts && _this.estimateDateData.collectionDateAlerts.length) {
                        $('#outboundCollectionDateAlerts').modal({ backdrop: 'static', keyboard: false });
                    }
                });

                jqxhr.fail(function () {
                    _this.$store.dispatch('errorLog', { description: 'Action - loadAvailableServices failed with errors', outcome: 'Error' });

                    // EH: Crucial?
                    //_this.$store.dispatch('showErrorToast');
                });

                jqxhr.always(function () {
                    _this.estimatedDateLoading = false;
                });
            }
        },
        metaInfo() {
            return {
                title: window.smbData.CMS.Dates.Meta.Title
            };
        },
        created() {
            this.$successLog({ description: 'Component - Basket Step 5', outcome: 'Created' });
            this.$store.dispatch('updateSummary');
            this.loadAvailableServices();
            this.getEstimatedDate();
        },
        mounted() {
            $(window).scrollTop(0);
        }
    }
</script>
