<template>
    <div class="form-group row" v-show="availableServices.length > 1">
        <div class="col-12"><h2>Delivery Service</h2></div>
        <div class="col-12">
            <label class="form-check-label">You have selected:</label>

            <div
                class="form-check form-check-inline radiobutton col-sm-auto px-2 mt-2 mb-0"
                v-for="service in availableServices"
                v-bind:key="service.serviceId"
            >
                <label
                    class="form-check form-check-inline radiobutton"
                    :for="'dateselect-deliverymethod-' + service.serviceId"
                >
                    {{ service.serviceName }}
                    <input
                        class="form-check-input"
                        type="radio"
                        name="selectedServiceId"
                        :id="'dateselect-deliverymethod-' + service.serviceId"
                        v-model="serviceId"
                        :value="service.serviceId"
                    />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'shipment-service-select',
    props: {
        value: Number,
        availableServices: Array,
    },
    computed: {
        serviceId: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        //    selectedServiceId: function (newselectedServiceId, oldselectedServiceId) {
        //        if (newselectedServiceId !== '' && newselectedServiceId !== oldselectedServiceId) {
        //            this.$store.dispatch('saveServiceTypeToBasket')
        //            // Only dispatch the next action if the collection date has been set
        //            if (this.$store.state.dates.activeDateDetails.collectionDate !== '')
        //                this.$store.dispatch('loadEstimatedDeliveryDate', this.$store.state.dates.activeDateDetails.collectionDate)
        //        }
        //    }
    },
    created() {
        this.$successLog({
            description: 'Component - Basket Date Service Component',
            outcome: 'Created',
        })
    },
}
</script>
