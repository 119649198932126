export default {
    infoLog: ({ state }, logModel) => {
    //    console.log(
    //        `%c ${logModel.description} %c ${logModel.outcome} %c`,
    //        'padding: 4px; color: #2B2B2B',
    //        'background:#f8fcfe ; padding: 4px; border-radius: 3px;  color: #29ABE2; border: 1px solid #29ABE2',
    //        'background:transparent'
    //    )
    },
    successLog: ({ state }, logModel) => {
    //    console.log(
    //        `%c ${logModel.description} %c ${logModel.outcome} %c`,
    //        'padding: 4px; color: #2B2B2B',
    //        'background:#f4fcf7 ; padding: 4px; border-radius: 3px;  color: #2DBE60; border: 1px solid #2DBE60',
    //        'background:transparent'
    //    )
    },
    errorLog: ({ state }, logModel) => {
    //    console.log(
    //        `%c ${logModel.description} %c ${logModel.outcome} %c`,
    //        'padding: 4px; color: #2B2B2B',
    //        'background:#fce3e5 ; padding: 4px; border-radius: 3px;  color: #F07077; border: 1px solid #F07077',
    //        'background:transparent'
    //    )
    },
    loadBasket: function ({ commit, dispatch }) {
        dispatch('startedLoading');

        var jqxhr = $.post('/en-gb/api/basketapi/loadbasket/', function (result) {
            //console.log("Load Basket Complete:", result);
            if (result.success) {
                commit('setInitialState', {
                    data: result.data,
                    isUserSignedIn: result.isUserSignedIn
                });
                commit('setInitialStateItems', result.data);
                commit('setInitialStateCustoms', result.data);
                commit('setInitialStateAddresses', result.data);
                commit('setInitialStateDates', result.data);
                commit('setInitialStateEssentials', result.data);
                commit('setInitialStateConfirmation', result.data);

                commit('setInitialLoadComplete', true);
            } else {
                window.location.replace('/quote');
            }
        });

        jqxhr.fail(function () {
//            MUST THROW BUGSNAG ERROR
            window.location.replace('/quote');
        });

        jqxhr.always(function () {
            dispatch('finishedLoading');
        });
    },

    stepBack: ({ state, commit }) => {
        switch (state.step) {
            case window.smbData.STEPS_ADDITEMS:
                break;
            case window.smbData.STEPS_ORIGINADDRESS:
                commit('setStepForward', window.smbData.STEPS_ADDITEMS);
                break;
            case window.smbData.STEPS_DESTINATIONADDRESS:
                commit('setStepForward', window.smbData.STEPS_ORIGINADDRESS);
                break;
            case window.smbData.STEPS_ADDRESSCONFIRM:
                commit('setStepForward', window.smbData.STEPS_DESTINATIONADDRESS);
                break;
            case window.smbData.STEPS_OUTBOUNDDATE:
                commit('setStepForward', window.smbData.STEPS_ADDRESSCONFIRM);
                break;
            case window.smbData.STEPS_INBOUNDDATE:
                commit('setStepForward', window.smbData.STEPS_OUTBOUNDDATE);
                break;
            case window.smbData.STEPS_ESSENTIALS:
                if (state.addresses.isReturn) {
                    commit('setStepForward', window.smbData.STEPS_INBOUNDDATE);
                } else {
                    commit('setStepForward', window.smbData.STEPS_OUTBOUNDDATE);
                }
                break;
            case window.smbData.STEPS_CONFIRM:
                commit('setStepForward', window.smbData.STEPS_ESSENTIALS);
                break;

        }
    },

    startedLoading({ commit }) {
        commit('setIsLoading', true);
    },
    finishedLoading({ commit }) {
        commit('setIsLoading', false);
    },
}