<template>
    <div class="col-12 col-md-6 align-items-md-stretch d-md-flex">
        <div v-if="disabled"
             class="selectable-card-disabled mb-3 px-4 md-px-0 w-100">

            <div class="card-body row">
                <div class="w-100 mb-2 mt-3"><strong>{{ title }}</strong></div>
                <div class="w-100">
                    <ul class="fa-ul" style="margin-left:1.5em;">
                        <li v-for="point in bulletPoints" v-bind:key="point"><span class="fa-li"><i class="far fa-check-circle" style="color: #2DBE60;"></i></span>{{ point[0] }}</li>
                    </ul>
                </div>
                <div class="w-100 mb-1 text-center font-weight-bold">Unavailable on this date</div>
            </div>
        </div>
        <div v-else
             class="selectable-card mb-3 px-4 md-px-0 w-100"
             v-bind:id="elementId"
             v-bind:class="{ active: isActive }"
             v-on:click="$emit('input', collectionService)">
            <div class="ribbon" v-show="recommended === 1">Recommended</div>
            <div class="ribbon primary" v-show="recommended === 2">Premium</div>
            <div class="card-body row">
                <div class="w-100 mb-2 mt-3"><strong>{{ title }}</strong></div>
                <div class="w-100">
                    <ul class="fa-ul" style="margin-left:1.5em;">
                        <li v-for="point in bulletPoints" v-bind:key="point" data-toggle="tooltip" data-placement="bottom" :title="point.length >= 2 ? point[1] : ''"><span class="fa-li"><i class="far fa-check-circle" style="color: #2DBE60;"></i></span>{{ point[0] }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'basket-collection-service',
    props: {
        value: Number,
        collectionService: Number,
        title: String,
        bulletPoints: String,
        disabled: Boolean,
        recommended: Number
    },
    computed: {
        //----- Basic getters from store values
        isActive: function () {
            return this.value === this.collectionService
        },
        elementId: function () {
            return 'collectionService' + this.collectionService
        },
    },
    created() {
        this.$successLog({
            description: 'Component - Basket Collection Service',
            outcome: 'Created',
        })
    },
}
</script>
