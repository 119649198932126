const $ = window.$;
export default  {
    props: {
        name: '',
        options: Array,
        value: '',
        placeholder: String,
        defaultSelectedIso: '',
        defaultValueOnOptionsUpdate: {
            type: Boolean,
            default: true
        }
    },  
    watch: {
        options: function (options) {
            this.optionsWatcher(options);

            if (this.defaultValueOnOptionsUpdate)
                this.valueWatcher(this.defaultSelectedIso);
        },
        value: function (value) {
            this.valueWatcher(value);
        }
    },
    destroyed: function () {
        $(this.$el).off().select2('destroy');
    },
    methods: {
        optionsWatcher: function (options) {
            // update options 
            $(this.$el).empty().select2({
                data: options,
                placeholder: this.placeholder
            });

            $(this.$el)
                .val(this.value)
                .trigger('change');
        },
        valueWatcher: function (value) {
            // update value
            $(this.$el)
                .val(value)
                .trigger('change');
        } 
    }
};